import React, { Component } from 'react';

export default class SubscriptionEditor extends Component {
    render() {
        const { row, onRowChange, options } = this.props;

        return (
            <select
                className='rdg-text-editor' style={{ textAlignLast: 'center' }}
                value={row.subscriptionType || ''} autoFocus
                onKeyDown={(e) => this.handleKeyDown(e, row, onRowChange, options)}
                onChange={e => { this.handleDropDownChange(e.target.value, row) }}
            >
                {options.map(option => (<option key={option.label} value={option.value}>{option.label}</option>))}
            </select>
        );
    }

    handleDropDownChange = (targetValue, row) => this.props.updateOrganizationSubscriptionType(row.id, targetValue);

    handleKeyDown = (e, row, onRowChange, options) => {
        if (e.ctrlKey && e.key === 'v') {
            navigator.clipboard.readText().then(clipboardValue => {
                const value = options.find(option => option.label === clipboardValue)?.value;
                if (value || (this.props.emptyValue && value === ''))
                    this.handleDropDownChange(value, row);
            });
        } else if ([37, 38, 39, 40].includes(e.keyCode)) {
            e.preventDefault();
            this.props.updateSelectedRow(row);
            onRowChange(row, true);
        }
    }
}