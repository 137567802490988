import React, { Component } from 'react';

export default class CommentEditor extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    state = {
        startValue: null,
        endValue: null
    };

    render() {
        const { row, onRowChange } = this.props;

        return (
            <input
                className='rdg-text-editor' ref={this.autoFocusAndSelect} value={row.comment || ''}
                onChange={e => this.handleTextChange(e, row, onRowChange)} onBlur={this.handleBlur}
            />
        );
    }

    componentDidMount = () => this.setState({ startValue: this.props.row.comment });

    componentDidUpdate = () => { // Permet de mettre à jour la startValue lorsqu'on modifie le champs lié
        if (this.inputRef.current && document.activeElement !== this.inputRef.current && this.state.startValue !== this.props.row.comment)
            this.setState({ startValue: this.props.row.comment });
    }

    componentWillUnmount = () => { if (!this.blurred) this.handleBlur(); } // L'event blur n'est pas déclenché lorsqu'on appuie sur enter/tab

    handleBlur = () => {
        const { startValue, endValue } = this.state;
        const { row, onClose } = this.props;
        if (startValue !== endValue && (endValue || endValue === ''))
            this.props.updateProjectActionElementRecurrence(row.projectAction?.id || row.parentId, row, row.status);
        onClose(true);
        this.blurred = true;
        setTimeout(() => this.blurred = false, 100);
    }

    handleTextChange = (e, row, onRowChange) => {
        this.setState({ endValue: e.target.value });
        const newRow = { ...row, comment: e.target.value || null };
        onRowChange(newRow);
    }

    autoFocusAndSelect = (input) => {
        if (input) {
            input.focus();
            input.select();
            this.inputRef.current = input;
        }
    }
}