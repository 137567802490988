import React, { Component } from 'react';
// Composants
import { Form, Segment, Checkbox, Grid, Tab } from 'semantic-ui-react';
// Librairies
import { isMobileOnly } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import i18n from '../../../locales/i18n';
import { connect } from 'react-redux';
// Utils
import ProjectsUtil from '../../../utils/ProjectsUtil';
import RightsUtil from '../../../utils/RightsUtil';

class PublicFieldsForm extends Component {
    state = { publicFields: null }

    render() {
        return (
            <>
                {this.state.publicFields &&
                    <Tab panes={this.renderPanes()} />}
            </>
        );
    }

    componentDidMount = () => {
        let publicFields = null;
        if (this.props.publicFields) publicFields = JSON.parse(this.props.publicFields);
        else publicFields = ProjectsUtil.getPublicFields();
        this.setState({ publicFields: publicFields });
    }

    renderPanes = () => {
        const { rights } = this.props;
        let panes = [{ menuItem: i18n.t("Général"), render: () => <Tab.Pane>{this.renderMainFields()}</Tab.Pane> }];
        if (RightsUtil.canRead(rights?.trees)) panes.push({ menuItem: i18n.t("Arbres"), render: () => <Tab.Pane>{this.renderTreesFields()}</Tab.Pane> });
        if (RightsUtil.canRead(rights?.greenSpaces)) panes.push({ menuItem: i18n.t("Espaces verts"), render: () => <Tab.Pane>{this.renderGreenSpacesFields()}</Tab.Pane> });
        if (RightsUtil.canRead(rights?.furnitures)) panes.push({ menuItem: i18n.t("Mobilier urbain"), render: () => <Tab.Pane>{this.renderFurnituresFields()}</Tab.Pane> });
        return panes;
    }

    renderMainFields = () => {
        const main = this.state.publicFields?.main;
        const type = 'main';

        return (<Grid columns={isMobileOnly ? 1 : 4}>
            <Grid.Row stretched>
                <Grid.Column>
                    {this.renderCheckboxes([
                        { name: 'trees', label: i18n.t("Arbres"), checked: main.trees },
                        { name: 'greenSpaces', label: i18n.t("Espaces verts"), checked: main.greenSpaces },
                        { name: 'furnitures', label: i18n.t("Mobiliers"), checked: main.furnitures },
                        { name: 'markers', label: i18n.t("Repères"), checked: main.markers },
                        { name: 'stations', label: i18n.t("Stations"), checked: main.stations },
                        { name: 'images', label: i18n.t("Calques"), checked: main.images }
                    ], type)}
                </Grid.Column>
                <Grid.Column>
                    {this.renderCheckboxes([
                        { name: 'references', label: i18n.t("Références (projets & personnalisées)"), checked: main.references },
                        { name: 'actions', label: i18n.t("Actions"), checked: main.actions },
                        { name: 'thematicMaps', label: i18n.t("Cartes thématiques personnalisées"), checked: main.thematicMaps },
                        { name: 'wmsServices', label: i18n.t("Services WMS"), checked: main.wmsServices }
                    ], type)}
                </Grid.Column>
                <Grid.Column>
                    {this.renderCheckboxes([
                        { name: 'photos', label: i18n.t("Galerie photos"), checked: main.photos },
                        { name: 'files', label: i18n.t("Galerie fichiers"), checked: main.files },
                        { name: 'filters', label: i18n.t("Filtres"), checked: main.filters },
                    ], type)}
                </Grid.Column>
                <Grid.Column>
                    {this.renderCheckboxes([
                        { name: 'statistics', label: i18n.t("Statistiques"), checked: main.statistics },
                        { name: 'charts', label: i18n.t("Graphiques"), checked: main.charts },
                        { name: 'dataTable', label: i18n.t("Tableau de données"), checked: main.dataTable }
                    ], type)}
                </Grid.Column>
            </Grid.Row>
        </Grid>);
    }

    renderTreesFields = () => {
        const trees = this.state.publicFields?.trees;
        const subscription = this.props.subscription;
        const type = 'trees';

        return (<Grid>
            <Grid.Row stretched style={isMobileOnly ? { paddingBottom: 0 } : null}>
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    {this.renderCheckboxes([
                        { name: 'vernacularName', label: i18n.t("Nom vernaculaire"), checked: trees.vernacularName },
                        { name: 'gender', label: i18n.t("Genre"), checked: trees.gender },
                        { name: 'species', label: i18n.t("Espèce"), checked: trees.species },
                        { name: 'cultivar', label: i18n.t("Cultivar"), checked: trees.cultivar }
                    ], type)}
                </Grid.Column>
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    {this.renderCheckboxes([
                        { name: 'isEmpty', label: i18n.t("Vide"), checked: trees.isEmpty },
                        { name: 'isDead', label: i18n.t("Mort"), checked: trees.isDead },
                        { name: 'isStump', label: i18n.t("Souche"), checked: trees.isStump },
                        { name: 'isIndexed', label: i18n.t("Classé"), checked: trees.isIndexed },
                        { name: 'isRemarkable', label: i18n.t("Remarquable"), checked: trees.isRemarkable }
                    ], type)}
                </Grid.Column>
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    {this.renderCheckboxes([
                        { name: 'isFruit', label: 'Fruitier', checked: trees.isFruit },
                        { name: 'place', label: i18n.t("Lieu"), checked: trees.place },
                        { name: 'observation', label: i18n.t("Observation"), checked: trees.observation },
                        { name: 'tags', label: i18n.t("Tags"), checked: trees.tags }
                    ], type)}
                </Grid.Column>
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    {this.renderCheckboxes([
                        { name: 'treePort', label: i18n.t("Port de l'arbre"), checked: trees.treePort },
                        { name: 'plantationType', label: i18n.t("Type de plantation"), checked: trees.plantationType },
                        { name: 'coverType', label: i18n.t("Type de couverture au sol"), checked: trees.coverType },
                        { name: 'interactions', label: i18n.t("Interactions"), checked: trees.interactions },
                        { name: 'microHabitats', label: i18n.t("Dendro-microhabitats"), checked: trees.microHabitats }
                    ], type)}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched style={isMobileOnly ? { paddingTop: 0 } : null}>
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    {this.renderCheckboxes([
                        { name: 'numberOfTrunks', label: i18n.t("Nombre d'axes"), checked: trees.numberOfTrunks },
                        { name: 'trunkHeight', label: i18n.t("Hauteur du tronc"), checked: trees.trunkHeight },
                        { name: 'trunks', label: i18n.t("Axes"), checked: trees.trunks }
                    ], type)}
                </Grid.Column>
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    {this.renderCheckboxes([
                        { name: 'situationCoefficient', label: i18n.t("Coefficient de situation"), checked: trees.situationCoefficient },
                        { name: 'plantationCoefficient', label: i18n.t("Coefficient de plantation"), checked: trees.plantationCoefficient },
                        { name: 'patrimonialCoefficient', label: i18n.t("Coefficient patrimonial"), checked: trees.patrimonialCoefficient }
                    ], type)}
                </Grid.Column>
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    {this.renderCheckboxes([
                        { name: 'ontogenicStage', label: i18n.t("Stade ontogénique"), checked: trees.ontogenicStage },
                        { name: 'plantingDate', label: i18n.t("Date de plantation"), checked: trees.plantingDate },
                        { name: 'age', label: i18n.t("Âge"), checked: trees.age }
                    ], type)}
                </Grid.Column>
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    {this.renderCheckboxes([
                        { name: 'vigor', label: i18n.t("Vigueur"), checked: trees.vigor },
                        { name: 'risk', label: i18n.t("Risque"), checked: trees.risk },
                        { name: 'healthReview', label: i18n.t("Cote sanitaire"), checked: trees.healthReview }
                    ], type)}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched style={isMobileOnly ? { paddingTop: 0 } : null}>
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    {this.renderCheckboxes([
                        { name: 'stumpDiameter', label: i18n.t("Diamètre de la souche"), checked: trees.stumpDiameter },
                        { name: 'canopy', label: i18n.t("Indicateur de canopée"), checked: trees.canopy },
                        { name: 'toCutDown', label: i18n.t("Statut d'abattage"), checked: trees.toCutDown }
                    ], type)}
                </Grid.Column>
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    {this.renderCheckboxes([
                        { name: 'carbonStock', label: i18n.t("Stock carbone"), checked: trees.carbonStock && subscription.carbonStockFormula },
                        { name: 'amenityValue', label: i18n.t("Valeur d'agrément"), checked: trees.amenityValue && subscription.amenityFormula },
                        { name: 'coolingIndicator', label: i18n.t("Indicateur de rafraîchissement"), checked: trees.coolingIndicator && subscription.coolingFormula },
                        { name: 'biodiversityIndex', label: i18n.t("Potentiel de biodiversité"), checked: trees.biodiversityIndex }
                    ], type)}
                </Grid.Column>
            </Grid.Row>
        </Grid>);
    }

    renderGreenSpacesFields = () => {
        const greenSpaces = this.state.publicFields?.greenSpaces;
        const subscription = this.props.subscription;
        const type = 'greenSpaces';

        if (!subscription) return;
        return (<Grid>
            <Grid.Row stretched style={isMobileOnly ? { paddingBottom: 0 } : null}>
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    {this.renderCheckboxes([
                        { name: 'place', label: i18n.t("Lieu"), checked: greenSpaces.place },
                        { name: 'placeExtra', label: i18n.t("Libellé"), checked: greenSpaces.placeExtra },
                        { name: 'isTreeBase', label: i18n.t("Pied d'arbre"), checked: greenSpaces.isTreeBase }
                    ], type)}
                </Grid.Column>
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    {this.renderCheckboxes([
                        { name: 'surface', label: i18n.t("Surface"), checked: greenSpaces.surface },
                        { name: 'length', label: i18n.t("Longueur"), checked: greenSpaces.length },
                        { name: 'spaceFunction', label: i18n.t("Fonction de l'espace"), checked: greenSpaces.spaceFunction },
                        { name: 'spaceType', label: i18n.t("Type de surface"), checked: greenSpaces.spaceType },
                    ], type)}
                </Grid.Column>
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    {this.renderCheckboxes([
                        { name: 'dominantComposition', label: i18n.t("Composition dominante"), checked: greenSpaces.dominantComposition },
                        { name: 'runoffCoefficient', label: i18n.t("Coefficient de ruissellement"), checked: greenSpaces.runoffCoefficient },
                        { name: 'detailedComposition', label: i18n.t("Composition détaillée"), checked: greenSpaces.detailedComposition },
                        { name: 'managementClass', label: i18n.t("Classe de gestion"), checked: greenSpaces.managementClass }
                    ], type)}
                </Grid.Column>
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    {this.renderCheckboxes([
                        { name: 'annualMaintenanceFrequency', label: i18n.t("Fréquence annuelle d'entretien"), checked: greenSpaces.annualMaintenanceFrequency },
                        { name: 'observation', label: i18n.t("Observation"), checked: greenSpaces.observation },
                        { name: 'tags', label: i18n.t("Tags"), checked: greenSpaces.tags }
                    ], type)}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    {this.renderCheckboxes([
                        { name: 'density', label: i18n.t("Densité"), checked: greenSpaces.density },
                        { name: 'dominantEssence', label: i18n.t("Essence dominante"), checked: greenSpaces.dominantEssence },
                        { name: 'averageHealthReview', label: i18n.t("Cote sanitaire moyenne"), checked: greenSpaces.averageHealthReview }
                    ], type)}
                </Grid.Column>
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    {this.renderCheckboxes([
                        { name: 'averageHeight', label: i18n.t("Hauteur moyenne"), checked: greenSpaces.averageHeight },
                        { name: 'averageCircumference', label: i18n.t("Circonférence moyenne des troncs"), checked: greenSpaces.averageCircumference },
                        { name: 'averageCrownDiameter', label: i18n.t("Diamètre moyen des couronnes"), checked: greenSpaces.averageCrownDiameter }
                    ], type)}
                </Grid.Column>
                {(subscription.carbonStockFormula || subscription.coolingFormula) &&
                    <Grid.Column computer={4} tablet={4} mobile={16}>
                        {this.renderCheckboxes([
                            { name: 'carbonStock', label: i18n.t("Stock carbone"), checked: greenSpaces.carbonStock && subscription.carbonStockFormula },
                            { name: 'coolingIndicator', label: i18n.t("Indicateur de rafraîchissement"), checked: greenSpaces.coolingIndicator && subscription.coolingFormula }
                        ], type)}
                    </Grid.Column>}
            </Grid.Row>
        </Grid>);
    }

    renderFurnituresFields = () => {
        const furnitures = this.state.publicFields?.furnitures;
        const type = 'furnitures';

        return (<Grid>
            <Grid.Row stretched style={isMobileOnly ? { paddingBottom: 0 } : null}>
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    {this.renderCheckboxes([
                        { name: 'place', label: i18n.t("Lieu"), checked: furnitures.place },
                        { name: 'description', label: i18n.t("Description"), checked: furnitures.description },
                        { name: 'type', label: i18n.t("Type"), checked: furnitures.type }
                    ], type)}
                </Grid.Column>
                <Grid.Column computer={4} tablet={4} mobile={16}>
                    {this.renderCheckboxes([
                        { name: 'condition', label: i18n.t("État"), checked: furnitures.condition },
                        { name: 'tags', label: i18n.t("Tags"), checked: furnitures.tags }
                    ], type)}
                </Grid.Column>
            </Grid.Row>
        </Grid>);
    }

    renderCheckboxes = (fields, type) => {
        const { disabled } = this.props;
        return (
            <Segment style={isMobileOnly ? { marginTop: '2px', marginBottom: '2px' } : null}>
                {fields.map(field => {
                    return (
                        <Form.Field
                            key={uuidv4()} name={field.name} label={field.label} control={Checkbox} checked={field.checked !== undefined ? field.checked : true}
                            disabled={disabled || this.props.loginAsData?.readOnly} onChange={(_, { name, checked }) => this.handleCheckboxChange(name, checked, type)}
                        />
                    );
                })}
            </Segment>
        );
    }

    handleCheckboxChange = (name, checked, type) => {
        this.setState(prevState => ({
            publicFields: {
                ...prevState.publicFields,
                [type]: {
                    ...prevState.publicFields[type],
                    [name]: checked
                }
            }
        }), () => this.props.handleChange('publicFields', this.state.publicFields));
    }
}

const mapStateToProps = (state) => {
    return {
        loginAsData: state.loginAsData,
        rights: state.rights
    };
};

export default connect(mapStateToProps)(PublicFieldsForm);