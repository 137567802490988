import React, { Component } from 'react';
// Composants
import { Button, Segment, Loader, Dimmer, Grid } from 'semantic-ui-react';
import DatePickerWithPeriod from '../Utils/DatePickerWithPeriod';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheckCircle, faHistory, faShapes, faTimesCircle, faUsers } from '@fortawesome/pro-solid-svg-icons';
import UserProjectList from '../Lists/UserProjectList';
import ProjectLogTypeList from '../Lists/ProjectLogTypeList';
/*     Filters     */
import TextFilter from '../Tables/Filters/TextFilter';
import DropDownFilter from '../Tables/Filters/DropDownFilter';
import Woops from '../Utils/Woops';
// Librairies
import DataGrid, { Row as GridRow } from 'react-data-grid';
import i18n from '../../locales/i18n';
import { connect } from 'react-redux';
import { setProject } from '../../actionCreators/projectsActions';
import { setTableState } from '../../actionCreators/componentsActions';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { endOfDay, startOfDay } from 'date-fns';
import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
// Services
import ProjectsService from '../../services/ProjectsService';
// Styles
import '../../styles/react-contextmenu.css';
import '../../styles/rdg.css';
// Utils
import FormattersUtil from '../../utils/FormattersUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';
import DatesUtil from '../../utils/DatesUtil';
import { showToast } from '../../utils/ToastsUtil';
import StylesUtil from '../../utils/StylesUtil';
import RightsUtil from '../../utils/RightsUtil';

const initialFilters = {
    username: '',
    date: '',
    type: '',
    message: '',
    status: ''
};

class ProjectHistory extends Component {
    state = {
        period: 'month',
        startDate: null,
        endDate: null,
        data: {
            columns: [],
            rows: []
        },
        sortColumn: null,
        sortDirection: 'NONE',
        enableFilterRow: false,
        filters: initialFilters,
        isLoading: true,
        loadingFailed: false,
        showUserProjectList: false,
        selectedUserProjects: [],
        selectedElements: [],
        showTypeList: false,
        selectedTypes: []
    };

    render() {
        const { rights, activeOrganization, projectCollaborators, project, isOnline } = this.props;
        const {
            period, startDate, endDate, data, sortColumn, sortDirection, enableFilterRow, filters, isLoading, loadingFailed,
            showUserProjectList, selectedUserProjects, selectedElements, showTypeList, selectedTypes
        } = this.state;
        const rows = this.getFilteredRows();
        const areAllUserProjectsSelected = selectedUserProjects.length === projectCollaborators.length;
        const projectLogTypes = Object.keys(ProjectsUtil.getProjectLogTypes());
        const areAllTypesSelected = selectedTypes.length === projectLogTypes.length;
        const areFiltersApplied = selectedElements.length || !areAllUserProjectsSelected || !areAllTypesSelected;
        const projectSubscription = project.organization.subscription;
        const isExportable = RightsUtil.canExport(rights?.ProjectHistory) && projectSubscription.export && activeOrganization?.subscription.export && project.type === 'project';

        return (
            <div className='modal-content'>
                {loadingFailed
                    ? <Woops />
                    :
                    <>
                        <div className='modal-content-header' style={{ display: 'flex', alignItems: 'center', flexDirection: isMobile ? 'column' : 'row', marginBottom: '4px' }}>
                            <div style={{ display: 'flex', flexDirection: isMobileOnly && 'column' }}>
                                <div style={{ display: 'flex', gap: isMobileOnly && '5px' }}>
                                    <DatePickerWithPeriod hideLabel={true} period={period} startDate={startDate} endDate={endDate} forceTime={true} setDates={this.setDates} setPeriod={this.setPeriod} />
                                    <Button.Group>
                                        <Button
                                            title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                                            className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                                            onClick={this.toggleFilters}
                                        />
                                        <Button
                                            title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                                            onClick={this.clearFilters} disabled={!this.areFiltersApplied() && !areFiltersApplied}
                                        />
                                    </Button.Group>
                                </div>
                                <div style={{ display: 'flex', gap: isMobileOnly && '5px', marginTop: isMobileOnly && '5px' }}>
                                    <Button
                                        title={i18n.t("Filtrer les collaborateurs")} className={!areAllUserProjectsSelected && 'button--secondary'}
                                        color={areAllUserProjectsSelected && 'grey'} onClick={() => this.setState({ showUserProjectList: true })}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '5px' }}
                                    >
                                        <FontAwesomeIcon icon={faUsers} />
                                        <span style={{ marginLeft: '5px' }}>{`(${selectedUserProjects.length}/${this.props.projectCollaborators.length})`}</span>
                                    </Button>
                                    <Button
                                        title={i18n.t("Filtrer les éléments")} className={(selectedElements.length) && 'button--secondary'}
                                        color={!selectedElements.length && 'grey'} onClick={this.selectElements}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        <FontAwesomeIcon icon={faShapes} />
                                        <span style={{ marginLeft: '5px' }}>{`(${selectedElements.length})`}</span>
                                    </Button>
                                    <Button
                                        title={i18n.t("Filtrer les types")} className={!areAllTypesSelected && 'button--secondary'}
                                        color={areAllTypesSelected && 'grey'} onClick={() => this.setState({ showTypeList: true })}
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        <FontAwesomeIcon icon={faHistory} />
                                        <span style={{ marginLeft: '5px' }}>{`(${selectedTypes.length}/${projectLogTypes.length})`}</span>
                                    </Button>
                                    {isExportable &&
                                        <>
                                            <Button
                                                title={i18n.t("Exporter les données")} className='button--secondary' icon='download' content={i18n.t("Exporter")}
                                                disabled={!isOnline} onClick={this.exportTableAsXLSX}
                                            />
                                        </>}
                                    <div style={{ position: 'absolute', width: '150px', right: 0, top: '15px', paddingRight: '10px', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                        <span style={{ borderLeft: 'solid 1px var(--grey-100)', paddingLeft: '10px', fontWeight: 'bold' }}>{rows?.length}</span>
                                        {rows?.length !== data?.rows?.length && <span style={{ marginLeft: '4px' }}>{` / ${data?.rows?.length}`}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='modal-content-body'>
                            <Segment style={{ display: 'flex', flexFlow: 'column', padding: 0, width: '100%', height: '100%' }}>
                                {data?.columns &&
                                    <>
                                        <DataGrid
                                            ref={this.gridRef} className={this.props.isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                                            style={{ flex: '1 1 auto' }}
                                            columns={data.columns} rows={rows} rowRenderer={this.rowRenderer}
                                            defaultColumnOptions={{ sortable: true, resizable: true }}
                                            cellNavigationMode='LOOP_OVER_ROW'
                                            sortColumn={sortColumn} sortDirection={sortDirection}
                                            onSort={this.handleSort} enableFilterRow={enableFilterRow}
                                            filters={filters} onFiltersChange={filters => this.setState({ filters: filters })}
                                            emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}>
                                                <span>{isLoading ? i18n.t("Chargement en cours...") : i18n.t("Aucun résultat trouvé")}</span>
                                            </div>)}
                                            onSelectedCellChange={({ idx, rowIdx }) => this.setState({ selectedRow: rows[rowIdx], selectedColumn: data.columns[idx] })}
                                        />
                                        {this.props.project.type === 'project' &&
                                            <ContextMenu id='grid-context-menu'>
                                                <MenuItem onClick={this.showRowElement}>{i18n.t("Voir")}</MenuItem>
                                            </ContextMenu>}
                                    </>}
                            </Segment>
                        </div>
                    </>}
                {showUserProjectList && this.renderUserProjectList()}
                {showTypeList && this.renderTypeList(projectLogTypes)}
            </div>
        );
    }

    componentDidMount = () => {
        this.gridRef = React.createRef();

        if (this.props.tableState) {
            this.setState({ ...this.props.tableState });
            this.props.setTableState(null);
        } else {
            this.setState({
                selectedUserProjects: this.props.projectCollaborators.map(collaborator => collaborator.userId),
                selectedTypes: Object.keys(ProjectsUtil.getProjectLogTypes())
            }, () => {
                this.loadData().then(() => {
                    const projectLogs = [...this.props.project.projectLogs];
                    projectLogs.sort((a, b) => a.date < b.date ? -1 : a.data > b.date ? 1 : 0);
                    const endDate = endOfDay(new Date());
                    const startDate = startOfDay(new Date(projectLogs[0].date));
                    this.setState({ startDate, endDate });
                });
            });
        }

        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);

    loadData = () => {
        return new Promise(async (resolve) => {
            let data = {
                columns: [],
                rows: []
            };

            // Définition des colonnes
            data.columns = [
                {
                    name: i18n.t("Nom d'utilisateur"), key: 'username', width: 200, sortable: true,
                    formatter: (props) => props.row.username || '',
                    filterRenderer: p => <TextFilter p={p} />
                },
                {
                    name: i18n.t("Date"), key: 'date', width: 200, sortable: true,
                    formatter: (props) => props.row.date || '',
                    filterRenderer: (props) => <TextFilter p={props} />
                },
                {
                    name: i18n.t("Action"), key: 'message', width: 400, sortable: true,
                    formatter: (props) => (<>
                        {props.row.type && <FontAwesomeIcon icon={ProjectsUtil.getProjectLogTypes()[props.row.type].icon} title={ProjectsUtil.getProjectLogTypes()[props.row.type].label} style={{ marginRight: '10px' }} />}
                        {props.row.message || ''}
                    </>),
                    filterRenderer: p => <TextFilter p={p} />
                },
                {
                    name: i18n.t("Statut"), key: 'status', width: 110, sortable: true,
                    formatter: (props) => (
                        <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
                            {!props.row.status ? <Loader active inline size='small' title={i18n.t("En cours...")} style={{ alignSelf: 'center' }} />
                                : props.row.status === 1 ? <FontAwesomeIcon icon={faCheckCircle} color='green' title={i18n.t("Réussite")} style={{ height: '20px', alignSelf: 'center' }} />
                                    : props.row.status === 2 ? <FontAwesomeIcon icon={faTimesCircle} color='red' title={i18n.t("Échouée")} style={{ height: '20px', alignSelf: 'center' }} />
                                        : <FontAwesomeIcon icon={faBan} color='grey' title={i18n.t("Annulée")} style={{ height: '20px', alignSelf: 'center' }} />}
                        </div>
                    ),
                    filterRenderer: (props) => <DropDownFilter p={props} propertyOptions={[{ label: i18n.t("Actif"), value: '0' }, { label: i18n.t("Succès"), value: '1' }, { label: i18n.t("Échec"), value: '2' }]} />
                }
            ];

            const createRows = async (elements = []) => {
                const { layers, projectCollaborators } = this.props;

                // Création d'une Map des layers
                const targetMap = new Map();
                await Promise.all(layers.map(async (layer) => {
                    const elements = await layer.getLayers();
                    elements.forEach(element => {
                        if (element.feature && element.feature.id) {
                            targetMap.set(element.feature.id, element.feature.properties);
                        }
                    });
                }));

                // Génération des rows en les traduisant
                const rows = await Promise.all(elements.map(async (history) => {
                    const { id, projectId, userId, targetId, type, status, message: rawMessage, date } = history;
                    const userBaseProject = projectCollaborators?.find(collaborator => collaborator.userId === userId);

                    // Traduction du message
                    let message = rawMessage;
                    if (!history.parameters?.length) {
                        message = i18n.t(rawMessage);
                    } else {
                        const parameters = rawMessage.match(/\{\{.*?\}\}/g)?.map(x => x.replace('{{', '').replace('}}', ''));
                        if (parameters?.length) {
                            const options = {};
                            for (let i = 0; i < parameters.length; i++) {
                                options[parameters[i]] = history.parameters[i].value;
                            }
                            message = i18n.t(rawMessage, options);
                        }
                    }

                    // Recherche des références
                    let projectReference, customReference, category;
                    if (type.includes('element') && targetMap.has(targetId)) {
                        const properties = targetMap.get(targetId);
                        projectReference = properties.projectReference;
                        customReference = properties.customReference;
                        category = { 'Arbre': 'trees', 'Espace vert': 'greenSpaces', 'Mobilier': 'furnitures', 'Repère': 'markers' }[properties.category];
                    }

                    // Formattage des données
                    const formattedUsername = userBaseProject ? FormattersUtil.formatLastNameAndFirstName(userBaseProject.user.lastName, userBaseProject.user.firstName) : '';
                    const formattedDate = `${DatesUtil.getFormattedLocaleDateString(date)}, ${DatesUtil.getFormattedLocaleTimeString(date)}`;
                    const exportStatus = !status ? i18n.t("En cours...")
                        : status === 1 ? i18n.t("Réussite")
                            : status === 2 ? i18n.t("Échouée")
                                : i18n.t("Annulée");

                    const refMessage = projectReference
                        ? ` (${i18n.t("Ref : ")} ${projectReference}${customReference ? ` | ${customReference}` : ''})`
                        : '';

                    return {
                        id,
                        username: formattedUsername,
                        date: formattedDate,
                        orignalDate: date,
                        userId,
                        targetId,
                        rawMessage: `${message}${refMessage}`,
                        message: (
                            <>
                                {message}
                                {projectReference && (
                                    <> ({i18n.t("Ref : ")}
                                        <a href={`${window.location.origin}/projects/${projectId}/${category}/${targetId}`} target='_blank' rel='noreferrer'>
                                            {projectReference}{customReference && ` | ${customReference}`}
                                        </a>)
                                    </>
                                )}
                            </>
                        ),
                        exportMessage: message + refMessage,
                        type,
                        status,
                        exportStatus
                    };
                }));

                data.rows = rows.reverse();
                const initialOrder = elements.map(element => element.id);
                this.setState({ data, elements, initialOrder, isLoading: false });
            };

            const { project } = this.props;
            if (!project.projectLogs) {
                ProjectsService.getLogs(project.id).then(async (histories) => {
                    if (histories) {
                        await createRows(histories);
                        this.props.setProject({ ...this.props.project, projectLogs: histories || [] });
                    } else this.setState({ loadingFailed: true });
                    resolve();
                });
            } else {
                setTimeout(async () => {
                    await createRows(project.projectLogs);
                    resolve();
                }, 100);
            }
        });
    }

    rowRenderer = (props) => {
        const { targetId, type } = props.row;
        return (
            <>
                {targetId && ['element_addition', 'element_update'].includes(type) ?
                    <ContextMenuTrigger id='grid-context-menu' collect={() => ({ rowIdx: props.rowIdx })}>
                        <GridRow {...props} />
                    </ContextMenuTrigger>
                    : < GridRow {...props} />}
            </>
        );
    }

    renderUserProjectList = () => {
        let historiesPerUserProjects = [];
        this.props.projectCollaborators.forEach(collaborator => historiesPerUserProjects[collaborator.userId] = this.state.data.rows.filter(row => row.userId === collaborator.userId || row.targetId === collaborator.userId).length);

        return (
            <Dimmer
                active style={{ ...StylesUtil.getMapStyles().dimmerStyle, display: 'flex', position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', zIndex: 9999 }}
                onClick={({ target }) => { if (target.classList.contains('dimmer')) this.setState({ showUserProjectList: false }) }}
            >
                <Grid style={{ height: '100%', width: '100%', padding: 0, margin: 0, marginLeft: 'auto', marginRight: 'auto' }}>
                    <Grid.Row style={{ height: '100%', padding: 0, display: 'flex', justifyContent: 'center' }} verticalAlign='middle'>
                        <Grid.Column style={{ width: isMobileOnly ? '95%' : '650px', height: isMobileOnly && '98vh', padding: 0, margin: 0 }}>
                            <UserProjectList
                                selectedUserProjects={this.state.selectedUserProjects} userProjectDescriptions={historiesPerUserProjects} cancel={() => this.setState({ showUserProjectList: false })}
                                submit={(selectedUserProjects = []) => this.setState({ showUserProjectList: false, selectedUserProjects })}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Dimmer>
        );
    }

    renderTypeList = (types) => {
        let historiesPerTypes = [];
        types.forEach(type => historiesPerTypes[type] = this.state.data.rows.filter(row => row.type === type).length);

        return (
            <Dimmer
                active style={{ ...StylesUtil.getMapStyles().dimmerStyle, display: 'flex', position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', zIndex: 9999 }}
                onClick={({ target }) => { if (target.classList.contains('dimmer')) this.setState({ showTypeList: false }) }}
            >
                <Grid style={{ height: '100%', width: '100%', padding: 0, margin: 0, marginLeft: 'auto', marginRight: 'auto' }}>
                    <Grid.Row style={{ height: '100%', padding: 0, display: 'flex', justifyContent: 'center' }} verticalAlign='middle'>
                        <Grid.Column style={{ width: isMobileOnly ? '95%' : '650px', height: isMobileOnly && '98vh', padding: 0, margin: 0 }}>
                            <ProjectLogTypeList
                                selectedProjectTypes={this.state.selectedTypes} projectTypesDescriptions={historiesPerTypes} cancel={() => this.setState({ showTypeList: false })}
                                submit={(selectedTypes = []) => this.setState({ showTypeList: false, selectedTypes })}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Dimmer>
        );
    }

    exportTableAsXLSX = () => {
        ProjectsService.exportHistoryAsXLSX(this.props.project.label, this.getFilteredRows().map(row => ({ ...row, message: row.exportMessage, status: row.exportStatus })));
    }

    // Filtres
    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (const property in this.state.filters)
            if (this.state.filters[property]) filtersApplied = true;
        return filtersApplied;
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow }));
    clearFilters = () => {
        const selectedUserProjects = this.props.projectCollaborators.map(collaborator => collaborator.userId);
        const selectedTypes = Object.keys(ProjectsUtil.getProjectLogTypes());
        this.setState({ filters: initialFilters, selectedUserProjects, selectedElements: [], selectedTypes: selectedTypes });
    }

    getFilteredRows = () => {
        const filters = this.state.filters;
        let rows = [...this.state.data.rows];
        let { startDate, endDate } = this.state;
        startDate = startOfDay(startDate);
        endDate = endOfDay(endDate);

        const $ = (str) => FormattersUtil.getNormalizedString(str);
        return rows.filter(r => {
            return (!this.state.enableFilterRow || (
                (filters.username ? $(r.username)?.includes($(filters.username)) : true)
                && (filters.date ? $(r.date).includes($(filters.date)) : true)
                && (filters.type ? $(r.type).includes($(filters.type)) : true)
                && (filters.message ? $(r.rawMessage)?.includes($(filters.message)) : true)
                && (filters.status ? r.status === +filters.status : true)
            )) && (DatesUtil.convertUTCDateToDate(r.orignalDate) >= startDate && DatesUtil.convertUTCDateToDate(r.orignalDate) <= endDate)
                && this.state.selectedTypes.includes(r.type)
                && (this.state.selectedUserProjects.includes(r.userId) || this.state.selectedUserProjects.includes(r.targetId))
                && (!this.state.selectedElements.length || this.state.selectedElements.find(element => element.feature.id === r.targetId))
        });
    }

    // Tri
    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);
    sortRows = () => {
        const sortDirection = this.state.sortDirection;
        let rows = [...this.state.data.rows];
        if (sortDirection === 'NONE') {
            for (let i = 0; i < this.state.initialOrder.length; i++) {
                let temp = rows[i];
                const index = rows.findIndex(row => row.id === this.state.initialOrder[i]);
                rows[i] = rows[index];
                rows[index] = temp;
            }

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: rows
                }
            }));
        } else {
            const sortColumn = this.state.sortColumn;
            if (sortColumn === 'status') rows = rows.sort((a, b) => (a[sortColumn] || 0) - (b[sortColumn] || 0));
            else if (sortColumn === 'date')
                rows = rows.sort((a, b) => {
                    const aDate = DatesUtil.convertDateStringToDate(a[sortColumn]), bDate = DatesUtil.convertDateStringToDate(b[sortColumn]);
                    return !aDate ? -1 : !bDate ? 1 : aDate - bDate;
                });
            else rows = rows.sort((a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: sortDirection === 'DESC' ? rows.reverse() : rows
                }
            }));
        }
    }

    handleKeyDown = (e) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            const { selectedColumn, selectedRow } = this.state;
            if (selectedColumn && selectedRow) navigator.clipboard.writeText(selectedRow[selectedColumn.key] || '');
        }
    }

    setDates = (startDate, endDate) => this.setState({ startDate, endDate });
    setPeriod = (period) => this.setState({ period });
    showRowElement = (_, { rowIdx }) => {
        const filteredRows = this.getFilteredRows();
        const projectLog = this.props.project.projectLogs.find(pl => pl.id === filteredRows[rowIdx].id);
        if (!projectLog) showToast('element_not_found');
        else {
            let target;
            for (const layer of this.props.layers) {
                const elements = layer.getLayers();
                target = elements.find(element => element.feature && element.feature.id === projectLog.targetId);
                if (target) break;
            }

            if (!target) showToast('element_not_found');
            else {
                let category;
                switch (target.feature.properties.category) {
                    case 'Arbre': category = 'Arbres'; break;
                    case 'Espace vert': category = 'Espaces verts'; break;
                    case 'Mobilier': category = 'Mobiliers'; break;
                    default: break;
                }

                this.props.setTableState({ ...this.state }).then(() => this.props.showElement(category, target.feature, 'ProjectHistory'));
            }
        }
    }

    selectElements = () => {
        this.props.setTableState({ ...this.state, selectElements: true }).then(() => this.props.selectElements(this.state.selectedElements, true, { originModal: 'ProjectHistory' }));
    }
}

const mapStateToProps = (state) => {
    return {
        project: state.project,
        projectCollaborators: state.projectCollaborators,
        rights: state.rights,
        activeOrganization: state.activeOrganization,
        isDarkTheme: state.isDarkTheme,
        isOnline: state.isOnline,
        tableState: state.tableState
    };
};

const mapDispatchToProps = {
    setProject,
    setTableState
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectHistory);