import AppSettings from '../AppSettings';
// Services
import { showToast } from '../utils/ToastsUtil';
// Librairies
import Axios from 'axios';
import Cookies from 'universal-cookie';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class FilesService {
    static uploadImportFiles(importId, formData, axiosOptions) {
        axiosOptions['headers'] = {
            'Authorization': `Bearer ${new Cookies().get('token')}`,
            'content-type': 'multipart/form-data'
        };
        return Axios.post(BACKEND_ENDPOINT + 'files/uploadImportFiles/' + importId, formData, axiosOptions).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast(formData.getAll('files').length === 1 ? 'file_upload_failed' : 'files_upload_failed');
            return;
        });
    }

    static getImportMapping(importId, elementType, projectId, formData) {
        const axiosOptions = {
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`,
                'content-type': 'multipart/form-data'
            }
        };

        return Axios.post(BACKEND_ENDPOINT + 'files/getImportMapping/' + importId + '/' + elementType + '/' + projectId, formData, axiosOptions).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast(formData.getAll('files').length === 1 ? 'file_upload_failed' : 'files_upload_failed');
            return;
        });
    }

    static getImportSample(importId, elementType, formData) {
        const axiosOptions = {
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`,
                'content-type': 'multipart/form-data'
            }
        };

        return Axios.post(`${BACKEND_ENDPOINT}files/getImportSample/${importId}/${elementType}/`, formData, axiosOptions).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast(formData.getAll('files').length === 1 ? 'file_upload_failed' : 'files_upload_failed');
            return;
        });
    }

    static importElementsInProject(category, formData) {
        const method = category === 'trees' ? 'importTreesInProject' : category === 'greenSpaces' ? 'importGreenSpacesInProject'
            : category === 'furnitures' ? 'importFurnituresInProject' : category === 'stations' ? 'importStationsInProject' : 'importPhotosInProject';
        return Axios.post(`${BACKEND_ENDPOINT}files/${method}/`, formData, {
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`,
                'content-type': 'multipart/form-data'
            }
        }).then(response => {
            return response;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            return;
        });
    }

    static exportProjectPhotos(id, types = [], elementIds = []) {
        return Axios.post(BACKEND_ENDPOINT + 'files/exportProjectPhotos/' + id, { types, elementIds }, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response;
        }, () => {
            return;
        });
    }
}
