import React, { Component } from 'react';
// Composants
import { Form, TextArea, Grid, Select } from 'semantic-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { isMobileOnly } from 'react-device-detect';
import i18n from '../../../../locales/i18n';
import { connect } from 'react-redux';
// Ressources
import { faEye } from '@fortawesome/pro-solid-svg-icons';
import FieldsUtil from '../../../../utils/FieldsUtil';

class Step2 extends Component {
    state = {
        properties: {
            typeId: 0,
            description: null
        },
        error: {
            hidden: true,
            messages: [],
            description: false
        }
    }

    render() {
        const requiredFields = this.props.requiredFields.furnitures;
        const types = this.props.furnitureTypes.map(x => { return { text: x.label, value: x.id } });

        return (
            <Grid id='cat--description' style={{ margin: '14px 0 0 0', border: 'solid 2px var(--orange-100)', borderRadius: '6px', order: FieldsUtil.getCategoryOrder(this.props.orderConfig, 'Mobilier', 'Description') }}>
                <Grid.Row style={{ display: 'flex', padding: '14px 14px 0 14px', color: 'var(--orange-100)', fontWeight: 'bold', fontSize: '14pt' }}>
                    <FontAwesomeIcon icon={faEye} style={{ alignSelf: 'center', marginRight: '5px' }} />
                    <span className='no-themed'>{i18n.t("Description")}</span>
                </Grid.Row>
                {requiredFields.type &&
                    <Grid.Column stretched computer={8} tablet={16} mobile={16}>
                        <Form.Field
                            control={Select} label={<label>{i18n.t("Type")} :</label>} placeholder={i18n.t("Sélectionnez un type")}
                            name='typeId' options={types}
                            value={this.props.properties.typeId || ''}
                            selectOnBlur={false} clearable
                            onChange={this.props.handleChange}
                        />
                    </Grid.Column>}
                {requiredFields.description &&
                    <Grid.Column computer={8} tablet={8} mobile={16} style={{ marginTop: '4px', padding: '0 14px' }}>
                        <Form.Field style={{ marginBottom: '14px', minHeight: isMobileOnly ? '200px' : '100px' }}
                            control={TextArea} label={i18n.t("Description") + ' : '} placeholder={i18n.t("Max 5000 caractères")}
                            name='description' value={this.props.properties.description || ''}
                            error={this.props.error.description} onChange={this.props.handleChange}
                        />
                    </Grid.Column>}
                {this.props.renderFields(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Mobilier' && dfc.label === 'Description'))}
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        furnitureTypes: state.furnitureTypes,
        defaultFieldCategories: state.defaultFieldCategories
    };
};

export default connect(mapStateToProps)(Step2);