export default class WmsUtil {
    static getAvailableLayers = (capabilities) => {
        if (!capabilities) return [];

        const extractLayers = (layers, isWMTS) => {
            const extract = (layer) => {
                if (layer.Layer) extractLayers(layer.Layer);
                else if (!isWMTS && layer.Name) {
                    let boundingBox = layer['EX_GeographicBoundingBox'];
                    if (boundingBox?.northBoundLatitude && boundingBox?.eastBoundLongitude && boundingBox?.southBoundLatitude && boundingBox?.westBoundLongitude) {
                        boundingBox = [
                            [Number(boundingBox.westBoundLongitude), Number(boundingBox.southBoundLatitude)],
                            [Number(boundingBox.eastBoundLongitude), Number(boundingBox.northBoundLatitude)]
                        ];
                    } else boundingBox = null;
                    availableLayers.push({ text: layer.Name, value: layer.Name, boundingBox });
                } else if (isWMTS && layer.Identifier?.toString) {
                    let boundingBox = layer['WGS84BoundingBox'];
                    if (boundingBox?.LowerCorner && boundingBox?.UpperCorner) {
                        boundingBox = [
                            [Number(boundingBox.LowerCorner.toString?.()?.split?.(' ')[0]), Number(boundingBox.LowerCorner.toString?.()?.split?.(' ')[1])],
                            [Number(boundingBox.UpperCorner.toString?.()?.split?.(' ')[0]), Number(boundingBox.UpperCorner.toString?.()?.split?.(' ')[1])]
                        ];
                    } else boundingBox = null;
                    const tileMatrixSetLink = Array.isArray(layer.TileMatrixSetLink)
                        ? layer.TileMatrixSetLink.find(tmsl => tmsl?.TileMatrixSet === 'PM') || layer.TileMatrixSetLink.find(tmsl => tmsl?.TileMatrixSet === 'WGS84') || layer.TileMatrixSetLink[0]
                        : layer.TileMatrixSetLink;
                    let maxNativeZoom = tileMatrixSetLink?.TileMatrixSetLimits?.TileMatrixLimits && Math.max(...tileMatrixSetLink?.TileMatrixSetLimits?.TileMatrixLimits.map(tml => Number(tml.TileMatrix?.includes(':') ? tml.TileMatrix.split(':')[1] : tml.TileMatrix) || 0));
                    if (!maxNativeZoom) maxNativeZoom = null;

                    availableLayers.push({
                        text: layer.Identifier.toString(), value: layer.Identifier.toString(), boundingBox, format: layer.Format || 'image/jpeg', style: layer.Style?.Identifier?.toString() || 'default',
                        tileMatrixSet: tileMatrixSetLink?.TileMatrixSet || 'PM', maxNativeZoom, legendURL: layer.Style?.LegendURL?.['_xlink:href']
                    });
                }
            };

            if (Array.isArray(layers)) layers.forEach(layer => extract(layer));
            else extract(layers);
        };

        const isWMTS = (capabilities.Service?.Name === 'OGC:WMTS') || (capabilities.ServiceIdentification?.ServiceType?.toString() === 'OGC WMTS');
        const layers = capabilities.Contents?.Layer || capabilities.Capability?.Layer;
        if (!layers) return [];

        const availableLayers = [];
        extractLayers(layers, isWMTS);
        return availableLayers;
    }
}
