import React, { Component } from 'react';
// Composants
import { SortableHeaderCell } from 'react-data-grid';
import { Dimmer, Form, Button, Menu, Input, Segment, Message } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Woops from '../Utils/Woops';
/*     Filters     */
import TextFilter from '../Tables/Filters/TextFilter';
import BooleanFilter from '../Tables/Filters/BooleanFilter';
import NumberFilter from '../Tables/Filters/NumberFilter';
import DropDownFilter from '../Tables/Filters/DropDownFilter';
import DropDownNumberFilter from '../Tables/Filters/DropDownNumberFilter';
import EssenceFilter from '../Tables/Filters/EssenceFilter';
// Librairies
import { faCheck, faList, faRotateLeft, faTimesCircle, faUserHelmetSafety } from '@fortawesome/pro-solid-svg-icons';
import DataGrid from 'react-data-grid';
import i18n from '../../locales/i18n';
import { connect } from 'react-redux';
import { setElementHistory } from '../../actionCreators/elementsActions';
import { setEditedProperties, unlockEditedProperties } from '../../actionCreators/componentsActions';
// Services
import GreenSpacesService from '../../services/GreenSpacesService';
// Styles
import '../../styles/react-contextmenu.css';
import '../../styles/rdg.css';
// Utils
import { showToast } from '../../utils/ToastsUtil';
import FormattersUtil from '../../utils/FormattersUtil';
import StylesUtil from '../../utils/StylesUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';
import DatesUtil from '../../utils/DatesUtil';
import UpdatesUtil from '../../utils/UpdatesUtil';
import RightsUtil from '../../utils/RightsUtil';
import FieldsUtil from '../../utils/FieldsUtil';

const initialFilters = {
    date: '',
    action: '',
    username: '',
    customReference: '',
    place: '',
    placeExtra: '',
    tags: '',
    isTreeBase: '',
    spaceFunction: '',
    spaceType: '',
    dominantComposition: '',
    detailedComposition: '',
    managementClass: '',
    annualMaintenanceFrequency: '',
    observation: '',
    nbTrees: '',
    density: '',
    distanceBetweenTrunks: '',
    vernacularName: '',
    gender: '',
    species: '',
    cultivar: '',
    averageHealthReview: '',
    averageHeight: '',
    averageCircumference: '',
    averageCrownDiameter: ''
};

const initialState = {
    data: {
        columns: [],
        rows: []
    },
    rowIndex: 0,
    sortColumn: null,
    sortDirection: 'NONE',
    enableFilterRow: false,
    filters: initialFilters,
    isLoading: true,
    isRestoring: false,
    historyToRestore: 0,
    loadingFailed: false
};

class GreenSpaceHistory extends Component {
    state = { ...initialState, id: this.props.layer[0].feature.id };

    render() {
        const { data, sortColumn, sortDirection, enableFilterRow, filters, rowIndex, isLoading, isRestoring, loadingFailed, historyToRestore } = this.state;
        const rows = this.getFilteredRows();

        return (
            <>
                {loadingFailed
                    ? <Woops />
                    :
                    <Segment style={{ display: 'flex', flexFlow: 'column', padding: 0, width: '100%', height: '100%' }}>
                        <Dimmer active={historyToRestore} style={StylesUtil.getMapStyles().dimmerStyle}>
                            <Message compact className='tableConfirmation'>
                                <Message.Header>{i18n.t("Êtes vous certain de vouloir restaurer cet historique ?")}</Message.Header>
                                <Message.Content>
                                    <span>{i18n.t("Cela restaurera l'arbre à l'état sélectionné, toute modification ultérieure à celui-ci sera donc annulée")}</span>
                                </Message.Content>
                                <Message.Content style={{ marginTop: '10px' }}>
                                    <Button color='grey' disabled={isRestoring} onClick={() => this.setState({ historyToRestore: 0 })}>
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                    </Button>
                                    <Button color='green' disabled={isRestoring} loading={isRestoring} onClick={this.restoreHistory}>
                                        <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                                    </Button>
                                </Message.Content>
                            </Message>
                        </Dimmer>
                        {data?.columns &&
                            <>
                                <Menu attached='top' tabular style={{ margin: 0, flexWrap: 'wrap' }}>
                                    <Menu.Item>
                                        <Form.Field
                                            control={Input} type='number' step='1' placeholder={i18n.t("Numéro de ligne")}
                                            value={rowIndex || ''}
                                            onChange={(e, { value }) => this.setState({ rowIndex: value })}
                                        />
                                        <Button
                                            className='button--secondary' icon='arrow down' style={{ marginLeft: '10px' }}
                                            onClick={() => { if (this.gridRef.current) this.gridRef.current.scrollToRow(rowIndex - 1) }}
                                        />
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Button.Group>
                                            <Button
                                                title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                                                className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                                                onClick={this.toggleFilters}
                                            />
                                            <Button
                                                title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                                                onClick={this.clearFilters} disabled={!this.areFiltersApplied()}
                                            />
                                        </Button.Group>
                                    </Menu.Item>
                                    {ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators) && RightsUtil.canRead(this.props.rights?.actions) &&
                                        <Menu.Item>
                                            <Button.Group>
                                                <Button className='button--secondary' style={{ padding: '11px' }} disabled>
                                                    <FontAwesomeIcon icon={faList} style={{ height: '12px', marginRight: '7px' }} />{i18n.t("Propriétés")}
                                                </Button>
                                                <Button
                                                    title={i18n.t("Historique des actions")} className='button--secondary' style={{ padding: '11px' }}
                                                    onClick={() => this.props.changeModalContentType('ActionHistory', i18n.t("Historique"), true)}
                                                >
                                                    <FontAwesomeIcon icon={faUserHelmetSafety} style={{ height: '12px', marginRight: '7px' }} />{i18n.t("Actions")}
                                                </Button>
                                            </Button.Group>
                                        </Menu.Item>}
                                </Menu>
                                <DataGrid
                                    ref={this.gridRef} className={this.props.isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                                    style={{ flex: '1 1 auto' }}
                                    columns={data.columns.filter(column => column.visible || !column.hasOwnProperty('visible'))} rows={rows}
                                    defaultColumnOptions={{ sortable: true, resizable: true }}
                                    cellNavigationMode='LOOP_OVER_ROW'
                                    sortColumn={sortColumn} sortDirection={sortDirection}
                                    onSort={this.handleSort} enableFilterRow={enableFilterRow}
                                    filters={filters} onFiltersChange={filters => this.setState({ filters: filters })}
                                    emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}>
                                        <span>{isLoading ? i18n.t("Chargement en cours...") : i18n.t("Aucun résultat trouvé")}</span>
                                    </div>)}
                                    onSelectedCellChange={({ idx, rowIdx }) => this.setState({ selectedRow: rows[rowIdx], selectedColumn: data.columns[idx] })}
                                />
                            </>}
                    </Segment>}
            </>
        );
    }

    componentDidMount = () => {
        this.gridRef = React.createRef();
        this.loadData();

        this.setState({
            spaceFunctions: this.props.spaceFunctions.map(x => { return { label: x.label, id: x.id } }),
            spaceTypes: this.props.spaceTypes.map(x => { return { label: x.label, id: x.id } }),
            dominantCompositions: this.props.dominantCompositions.map(x => { return { label: x.label, id: x.id } }),
            managementClasses: this.props.managementClasses.map(x => { return { label: x.value, id: x.id } }),
            healthReviews: this.props.healthReviews.map(x => { return { label: x.value, id: x.id } }),
            vernacularNames: [...new Set(this.props.essences
                .map(x => x.vernacularName))]
                .filter(x => x)
                .sort((a, b) => { // On trie par ordre alphabétique
                    const textA = a.toUpperCase();
                    const textB = b.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }),
            genders: [...new Set(this.props.essences
                .map(x => x.gender))]
                .sort((a, b) => { // On trie par ordre alphabétique
                    const textA = a.toUpperCase();
                    const textB = b.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }),
            species: [...new Set(this.props.essences
                .filter(x => x.species)
                .map(x => x.species))]
                .sort((a, b) => { // On trie par ordre alphabétique
                    const textA = a.toUpperCase();
                    const textB = b.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }),
            cultivars: [...new Set(this.props.essences
                .filter(x => x.cultivar)
                .map(x => x.cultivar))]
                .sort((a, b) => { // On trie par ordre alphabétique
                    const textA = a.toUpperCase();
                    const textB = b.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                })
        });

        document.addEventListener('keydown', this.handleKeyDown);
    }
    componentDidUpdate = (prevProps) => { // Permet d'update les infos lorsqu'on passe à l'arbre suivant ou précédent dans un projet
        if (this.props.layer[0].feature.id !== this.state.id || JSON.stringify(this.props.elementHistory) !== JSON.stringify(prevProps.elementHistory))
            this.setState({ ...initialState, id: this.props.layer[0].feature.id }, this.loadData);
    }

    componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);

    getHeaderRenderer = ({ column, onSort, sortColumn, sortDirection }, color) => (
        <div className={color ? 'headerCellOverride' : null} ref={(node) => { if (color) node?.style?.setProperty('background-color', color, 'important'); }}>
            <SortableHeaderCell
                column={column}
                onSort={onSort}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
            >
                {column.name}
            </SortableHeaderCell>
        </div>
    );

    loadData = () => {
        let data = {
            columns: [],
            rows: []
        };

        const requiredFields = ProjectsUtil.getProjectRequiredFields(this.props.project).greenSpaces;
        const publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators);
        const mainPF = publicFields.main;
        const greenSpacesPF = publicFields.greenSpaces;
        const projectTags = this.props.project?.tags || [];

        // Définition des colonnes
        this.customFieldFilters = [];
        data.columns = [
            {
                name: '', key: 'restore', width: 148, order: -1,
                sortable: true, resizable: false,
                formatter: (props) => (
                    <div style={{ overflow: 'hidden' }}>
                        {props.row.isSameState ?
                            <Button color='yellow' disabled style={{ padding: '6px 20px', width: '132px' }} content={<><FontAwesomeIcon style={{ marginRight: '10px' }} />{i18n.t("État actuel")}</>} />
                            :
                            <Button
                                color='green' style={{ padding: '6px 20px' }} content={<><FontAwesomeIcon icon={faRotateLeft} style={{ marginRight: '10px' }} />{i18n.t("Restaurer")}</>}
                                onClick={() => this.setState({ historyToRestore: props.row.id })}
                            />}
                    </div>
                )
            },
            {
                name: i18n.t("Date"), key: 'date', width: 250, sortable: true, order: -1,
                formatter: (props) => props.row.date || '',
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Action"), key: 'action', width: 150, sortable: true, order: -1,
                formatter: (props) => props.row.action || '',
                filterRenderer: p => <TextFilter p={p} />
            },
            {
                name: i18n.t("Nom d'utilisateur"), key: 'username', width: 150, sortable: true, order: -1,
                formatter: (props) => props.row.username || '',
                filterRenderer: p => <TextFilter p={p} />
            },
            {
                name: i18n.t("Référence personnalisée"), key: 'customReference', width: 190, order: -1,
                sortable: true, visible: requiredFields.customReference && mainPF.references,
                formatter: (props) => this.didChange('customReference', props.row.id)
                    ? <div className='modified'>{props.row.customReference}</div>
                    : props.row.customReference || '',
                filterRenderer: p => <TextFilter p={p} />
            },
            {
                name: i18n.t("Lieu"), key: 'place', width: 250, category: 'Emplacement',
                sortable: true, visible: requiredFields.place && greenSpacesPF.place,
                formatter: (props) => this.didChange('place', props.row.id)
                    ? <div className='modified'>{props.row.place}</div>
                    : props.row.place || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Emplacement')?.color),
                filterRenderer: p => <TextFilter p={p} />
            },
            {
                name: i18n.t("Libellé"), key: 'placeExtra', width: 180, category: 'Emplacement',
                sortable: true, visible: requiredFields.placeExtra && greenSpacesPF.placeExtra,
                formatter: (props) => this.didChange('placeExtra', props.row.id)
                    ? <div className='modified'>{props.row.placeExtra}</div>
                    : props.row.placeExtra || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Emplacement')?.color),
                filterRenderer: p => <TextFilter p={p} />
            },
            {
                name: i18n.t("Tags"), key: 'tags', width: 200, category: 'Emplacement',
                sortable: false, visible: requiredFields.tags && greenSpacesPF.tags,
                formatter: (props) => this.didChange('tags', props.row.id)
                    ? <div className='modified'>{props.row.tags}</div>
                    : props.row.tags || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Emplacement')?.color),
                filterRenderer: p => <TextFilter p={p} />
            },
            {
                name: i18n.t("Pied d'arbre"), key: 'isTreeBase', width: 110, category: 'Emplacement',
                sortable: true, visible: requiredFields.isTreeBase && greenSpacesPF.isTreeBase,
                formatter: (props) => this.didChange('isTreeBase', props.row.id)
                    ? <div className='modified'>{props.row.isTreeBase}</div>
                    : props.row.isTreeBase || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Emplacement')?.color),
                filterRenderer: p => <BooleanFilter p={p} />
            },
            {
                name: i18n.t("Fonction de l'espace"), key: 'spaceFunction', width: 150, category: 'Emplacement',
                sortable: true, visible: requiredFields.spaceFunction && greenSpacesPF.spaceFunction,
                formatter: (props) => this.didChange('spaceFunction', props.row.id)
                    ? <div className='modified'>{props.row.spaceFunction}</div>
                    : props.row.spaceFunction || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Emplacement')?.color),
                filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.spaceFunctions} isNullable />
            },
            {
                name: i18n.t("Type de surface"), key: 'spaceType', width: 350, category: 'Emplacement',
                sortable: true, visible: requiredFields.spaceType && greenSpacesPF.spaceType,
                formatter: (props) => this.didChange('spaceType', props.row.id)
                    ? <div className='modified'>{props.row.spaceType}</div>
                    : props.row.spaceType || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Emplacement')?.color),
                filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.spaceTypes} isNullable />
            },
            ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Emplacement')),
            {
                name: i18n.t("Composition dominante"), key: 'dominantComposition', width: 180, category: 'Composition',
                sortable: true, visible: requiredFields.dominantComposition && greenSpacesPF.dominantComposition,
                formatter: (props) => this.didChange('dominantComposition', props.row.id)
                    ? <div className='modified'>{props.row.dominantComposition}</div>
                    : props.row.dominantComposition || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Composition')?.color),
                filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.dominantCompositions} isNullable />
            },
            {
                name: i18n.t("Composition détaillée"), key: 'detailedComposition', width: 180, category: 'Composition',
                sortable: true, visible: requiredFields.detailedComposition && greenSpacesPF.detailedComposition,
                formatter: (props) => this.didChange('detailedComposition', props.row.id)
                    ? <div className='modified'>{props.row.detailedComposition}</div>
                    : props.row.detailedComposition || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Composition')?.color),
                filterRenderer: p => <TextFilter p={p} />
            },
            ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Composition')),
            {
                name: i18n.t("Classe de gestion"), key: 'managementClass', width: 130, category: 'Entretien',
                sortable: true, visible: requiredFields.managementClass && greenSpacesPF.managementClass,
                formatter: (props) => this.didChange('managementClass', props.row.id)
                    ? <div className='modified'>{props.row.managementClass}</div>
                    : props.row.managementClass || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Entretien')?.color),
                filterRenderer: p => <DropDownNumberFilter p={p} propertyOptions={this.state.managementClasses} />,
            },
            {
                name: i18n.t("Fréquence annuelle d'entretien"), key: 'annualMaintenanceFrequency', width: 220, category: 'Entretien',
                sortable: true, visible: requiredFields.annualMaintenanceFrequency && greenSpacesPF.annualMaintenanceFrequency,
                formatter: (props) => this.didChange('annualMaintenanceFrequency', props.row.id)
                    ? <div className='modified'>{props.row.annualMaintenanceFrequency}</div>
                    : props.row.annualMaintenanceFrequency || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Entretien')?.color),
                filterRenderer: p => <NumberFilter p={p} step='0.1' />
            },
            ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Entretien')),
            {
                name: i18n.t("Observation"), key: 'observation', width: 500, category: 'Observation',
                sortable: true, visible: requiredFields.observation && greenSpacesPF.observation,
                formatter: (props) => this.didChange('observation', props.row.id)
                    ? <div className='modified'>{props.row.observation}</div>
                    : props.row.observation || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Observation')?.color),
                filterRenderer: p => <TextFilter p={p} />
            },
            ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Observation')),
            {
                name: i18n.t("Nombre d'arbres"), key: 'nbTrees', width: 150, category: 'Massif arboré',
                sortable: true, visible: requiredFields.density && greenSpacesPF.density,
                formatter: (props) => this.didChange('nbTrees', props.row.id)
                    ? <div className='modified'>{props.row.nbTrees}</div>
                    : props.row.nbTrees || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                filterRenderer: p => <NumberFilter p={p} step='1' />
            },
            {
                name: i18n.t("Densité"), key: 'density', width: 110, category: 'Massif arboré',
                sortable: true, visible: requiredFields.density && greenSpacesPF.density,
                formatter: (props) => this.didChange('density', props.row.id)
                    ? <div className='modified'>{props.row.density}</div>
                    : props.row.density || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                filterRenderer: p => <NumberFilter p={p} step='0.01' />
            },
            {
                name: i18n.t("Distance moyenne entre les troncs"), key: 'distanceBetweenTrunks', width: 270, category: 'Massif arboré',
                sortable: true, visible: requiredFields.density && greenSpacesPF.density,
                formatter: (props) => this.didChange('distanceBetweenTrunks', props.row.id)
                    ? <div className='modified'>{props.row.distanceBetweenTrunks}</div>
                    : props.row.distanceBetweenTrunks || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                filterRenderer: p => <NumberFilter p={p} step='0.01' />
            },
            {
                name: i18n.t("Nom vernaculaire dominant"), key: 'vernacularName', width: 150, category: 'Massif arboré',
                sortable: true, visible: requiredFields.dominantEssence && greenSpacesPF.dominantEssence,
                formatter: (props) => this.didChange('vernacularName', props.row.id)
                    ? <div className='modified'>{props.row.vernacularName}</div>
                    : props.row.vernacularName || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                filterRenderer: p => <EssenceFilter p={p} propertyOptions={this.state.vernacularNames} />
            },
            {
                name: i18n.t("Genre dominant"), key: 'gender', width: 150, category: 'Massif arboré',
                sortable: true, visible: requiredFields.dominantEssence && greenSpacesPF.dominantEssence,
                formatter: (props) => this.didChange('gender', props.row.id)
                    ? <div className='modified'>{props.row.gender}</div>
                    : props.row.gender || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                filterRenderer: p => <EssenceFilter p={p} propertyOptions={this.state.genders} />
            },
            {
                name: i18n.t("Espèce dominante"), key: 'species', width: 150, category: 'Massif arboré',
                sortable: true, visible: requiredFields.dominantEssence && greenSpacesPF.dominantEssence,
                formatter: (props) => this.didChange('species', props.row.id)
                    ? <div className='modified'>{props.row.species}</div>
                    : props.row.species || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                filterRenderer: p => <EssenceFilter p={p} propertyOptions={this.state.species} />
            },
            {
                name: i18n.t("Cultivar dominant"), key: 'cultivar', width: 150, category: 'Massif arboré',
                sortable: true, visible: requiredFields.dominantEssence && greenSpacesPF.dominantEssence,
                formatter: (props) => this.didChange('cultivar', props.row.id)
                    ? <div className='modified'>{props.row.cultivar}</div>
                    : props.row.cultivar || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                filterRenderer: p => <EssenceFilter p={p} propertyOptions={this.state.cultivars} />
            },
            {
                name: i18n.t("Cote sanitaire moyenne"), key: 'averageHealthReview', width: 175, category: 'Massif arboré',
                sortable: true, visible: requiredFields.averageHealthReview && greenSpacesPF.averageHealthReview,
                formatter: (props) => this.didChange('averageHealthReview', props.row.id)
                    ? <div className='modified'>{props.row.averageHealthReview}</div>
                    : props.row.averageHealthReview || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                filterRenderer: p => <DropDownNumberFilter p={p} propertyOptions={this.state.healthReviews} />
            },
            {
                name: i18n.t("Hauteur moyenne") + ' (m)', key: 'averageHeight', width: 160, category: 'Massif arboré',
                sortable: true, visible: requiredFields.averageHeight && greenSpacesPF.averageHeight,
                formatter: (props) => this.didChange('averageHeight', props.row.id)
                    ? <div className='modified'>{props.row.averageHeight}</div>
                    : props.row.averageHeight || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                filterRenderer: p => <NumberFilter p={p} step='0.1' />
            },
            {
                name: i18n.t("Diamètre moyen des couronnes") + ' (m)', key: 'averageCrownDiameter', width: 250, category: 'Massif arboré',
                sortable: true, visible: requiredFields.averageCrownDiameter && greenSpacesPF.averageCrownDiameter,
                formatter: (props) => this.didChange('averageCrownDiameter', props.row.id)
                    ? <div className='modified'>{props.row.averageCrownDiameter}</div>
                    : props.row.averageCrownDiameter || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                filterRenderer: p => <NumberFilter p={p} step='0.1' />
            },
            {
                name: i18n.t("Circonférence moyenne des troncs") + ' (cm)', key: 'averageCircumference', width: 280, category: 'Massif arboré',
                sortable: true, visible: requiredFields.averageCircumference && greenSpacesPF.averageCircumference,
                formatter: (props) => this.didChange('averageCircumference', props.row.id)
                    ? <div className='modified'>{props.row.averageCircumference}</div>
                    : props.row.averageCircumference || '',
                headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                filterRenderer: p => <NumberFilter p={p} step='0.1' />
            },
            ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')),
            ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Indicateurs')),
            ...this.getCustomCategoriesColumns(),
            ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Autres')),
        ];

        if (this.props.project.orderConfig)
            data.columns.sort((a, b) => {
                const aOrder = a.order || FieldsUtil.getCategoryOrder(this.props.project.orderConfig, 'Espace vert', a.category)
                const bOrder = b.order || FieldsUtil.getCategoryOrder(this.props.project.orderConfig, 'Espace vert', b.category)
                return aOrder - bOrder;
            });

        this.setState(({ filters }) => {
            this.customFieldFilters.forEach(customFieldId => filters[customFieldId] = '');
            return { filters };
        });

        const createRows = (elements) => {
            data.rows = elements.map((history) => {
                const properties = history.greenSpace.properties;
                // Récupération des valeurs de l'espace vert
                const dominantComposition = this.props.dominantCompositions.find(x => x.id === properties.dominantCompositionId)?.label;
                const managementClass = this.props.managementClasses.find(x => x.id === properties.managementClassId)?.value;
                const spaceFunction = this.props.spaceFunctions.find(x => x.id === properties.spaceFunctionId)?.label;
                const spaceType = this.props.spaceTypes.find(x => x.id === properties.spaceTypeId);
                const spaceTypeCategoryLabel = spaceType ? this.props.spaceTypeCategories.find(x => x.id === spaceType.spaceTypeCategoryId)?.label : null;
                const spaceTypeLabel = spaceType?.label;
                let tags = '';
                if (properties.tagId)
                    properties.tagId.forEach(tagId => {
                        const tag = projectTags.find(x => x.id === tagId)?.label;
                        tags += tags === '' ? tag : ', ' + tag;
                    });
                const dominantEssence = this.props.essences.find(x => x.id === properties.dominantEssenceId);
                const averageHealthReview = this.props.healthReviews.find(x => x.id === properties.averageHealthReviewId)?.value;
                const averageHeight = Math.round((properties.averageHeight / 100) * 100) / 100;
                const averageCircumference = properties.averageCircumference;
                const averageCrownDiameter = Math.round((properties.averageCrownDiameter / 100) * 100) / 100;

                const row = {
                    id: history.id,
                    date: `${DatesUtil.getFormattedLocaleDateString(history.date)}, ${DatesUtil.getFormattedLocaleTimeString(history.date)}`,
                    action: history.action,
                    username: history.username,
                    customReference: properties.customReference,
                    place: properties.place,
                    placeExtra: properties.placeExtra,
                    isTreeBase: properties.isTreeBase ? i18n.t("Oui") : i18n.t("Non"),
                    spaceFunction: spaceFunction,
                    spaceTypeCategoryId: spaceTypeCategoryLabel,
                    spaceType: spaceTypeLabel,
                    surface: properties.surface,
                    dominantComposition: dominantComposition,
                    detailedComposition: properties.detailedComposition,
                    managementClass: managementClass,
                    annualMaintenanceFrequency: properties.annualMaintenanceFrequency,
                    observation: properties.observation,
                    nbTrees: properties.nbTrees || '',
                    density: properties.density || '',
                    distanceBetweenTrunks: properties.distanceBetweenTrunks || '',
                    dominantVernacularName: dominantEssence?.vernacularName || '',
                    dominantGender: dominantEssence?.gender || '',
                    dominantSpecies: dominantEssence?.species || '',
                    dominantCultivar: dominantEssence?.cultivar || '',
                    averageHealthReview: averageHealthReview,
                    averageHeight: averageHeight || '',
                    averageCircumference: averageCircumference || '',
                    averageCrownDiameter: averageCrownDiameter || '',
                    tags,
                    isSameState: this.isSameState(properties)
                };

                if (properties.customFields) {
                    Object.keys(properties.customFields).forEach(key => {
                        const customField = this.props.customFields.find(customField => customField.id === Number(key));
                        if (customField) {
                            row[key] = customField.type === 'boolean' ? properties.customFields[key] === 'true' ? i18n.t("Oui") : i18n.t("Non")
                                : customField.type === 'date'
                                    ? DatesUtil.getFormattedLocaleDateString(properties.customFields[key])
                                    : customField.type === 'list'
                                        ? customField.isMultiple
                                            ? properties.customFields[key].split(',').map(id => customField.dropdownCustomFieldValues.find(dcfv => dcfv.id === Number(id))?.label).join(', ')
                                            : customField.dropdownCustomFieldValues.find(dcfv => dcfv.id === Number(properties.customFields[key]))?.label
                                        : properties.customFields[key];
                        }
                    });
                }

                return row;
            }).reverse();

            const initialOrder = data.rows.map(row => row.id);
            this.setState({ data, elements, initialOrder, isLoading: false });
        }

        // Ajout des données
        this.props.unlockEditedProperties();
        const { elementHistory, layer } = this.props;
        if (!elementHistory || elementHistory[0]?.greenSpaceId !== this.state.id) {
            GreenSpacesService.getGreenSpaceHistory(layer[0].feature.id).then(histories => {
                if (histories) {
                    this.props.setElementHistory(histories);
                    createRows(histories);
                } else this.setState({ loadingFailed: true });
            });
        } else {
            const histories = [...elementHistory];
            this.props.setElementHistory(histories);
            createRows(histories);
        }
    }

    getCustomColumns = (category, isDefaultCategory = true) => {
        const { activeOrganization, customFields, project } = this.props;
        const areCustomFieldsAvailable = (project.organization || activeOrganization).subscription.customFields;
        if (!areCustomFieldsAvailable) return [];

        const projectCustomFields = [...(project.projectCustomFields || [])].sort((a, b) => a.order - b.order);
        const pcfColumns = projectCustomFields.filter(pcf => pcf.fieldCategoryId === category.id).map((projectCustomField) => {
            const customField = customFields.find(cf => cf.id === projectCustomField.customFieldId);
            if (customField) this.customFieldFilters.push(String(customField.id));

            return customField?.category === 'Espace vert' ? {
                name: customField.label + (['number', 'formula'].includes(customField.type) && customField.unit?.trim() ? ` (${customField.unit})` : ''), key: customField.id, width: 180, category: isDefaultCategory ? category.label : `${category.id}`,
                sortable: true, visible: true, customField,
                formatter: (props) => (
                    <div className={this.didChange(customField.id, props.row.id) ? 'modified' : null}>
                        {(props.row.isEmpty === i18n.t("Oui") && !customField.forEmpty) ||
                            (props.row.isDead === i18n.t("Oui") && !customField.forDead) ||
                            (props.row.isStump === i18n.t("Oui") && !customField.forStump)
                            ? <div className='disabled'></div>
                            : props.row[String(customField.id)] ? (
                                customField.type === 'url'
                                    ? <a href={props.row[String(customField.id)]?.includes('http') ? props.row[String(customField.id)] : '//' + props.row[String(customField.id)]} target='_blank' rel='noreferrer'>{props.row[String(customField.id)]}</a>
                                    : customField.type === 'formula'
                                        ? FormattersUtil.formatFormulaCustomField(customField, props.row[String(customField.id)])
                                        : props.row[String(customField.id)] + (customField.type === 'number' && customField.unit?.trim() ? customField.unit : '')
                            ) : ''}
                    </div>
                ),
                headerRenderer: (props) => this.getHeaderRenderer(props, category.color),
                filterRenderer: p => (
                    customField.type === 'boolean' ? <BooleanFilter p={p} />
                        : ['text', 'url', 'date'].includes(customField.type) || (customField.type === 'list' && customField.isMultiple) ? <TextFilter p={p} />
                            : customField.type === 'number' ? <NumberFilter p={p} step={customField.step} />
                                : customField.type === 'list' ? <DropDownFilter p={p} propertyOptions={customField.dropdownCustomFieldValues.map(dcfv => ({ label: dcfv.label, id: dcfv.id }))} isNullable />
                                    : null
                )
            } : null;
        }).filter(pcf => pcf);

        return pcfColumns;
    }

    getCustomCategoriesColumns = () => {
        const { project } = this.props;
        const fieldCategories = project?.fieldCategories || [];
        const fieldCategoriesToRender = fieldCategories.filter(fieldCategory => fieldCategory.category === 'Espace vert' && (project?.projectCustomFields || []).find(pcf => pcf.fieldCategoryId === fieldCategory.id));
        return fieldCategoriesToRender.flatMap(fieldCategory => this.getCustomColumns(fieldCategory, false));
    }

    // Filtres
    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (const property in this.state.filters)
            if (this.state.filters[property]) filtersApplied = true;
        return filtersApplied;
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow }));
    clearFilters = () => this.setState({ filters: initialFilters });

    getFilteredRows = () => {
        const filters = this.state.filters;
        let rows = [...this.state.data.rows];

        const $ = (str) => FormattersUtil.getNormalizedString(str);
        return rows.filter(r => {
            return !this.state.enableFilterRow || (
                (filters.action ? $(r.action)?.includes($(filters.action)) : true)
                && (filters.username ? $(r.username)?.includes($(filters.username)) : true)
                && (filters.date ? $(r.date).includes($(filters.date)) : true)
                && (filters.customReference ? $(r.customReference)?.includes($(filters.customReference)) : true)
                && (filters.place ? $(r.place)?.includes($(filters.place)) : true)
                && (filters.placeExtra ? $(r.placeExtra)?.includes($(filters.placeExtra)) : true)
                && (filters.tags ? $(r.tags)?.includes($(filters.tags)) : true)
                && (filters.isTreeBase ? (r.isTreeBase === filters.isTreeBase || (filters.isTreeBase === 'empty' && !r.isTreeBase)) : true)
                && (filters.spaceFunction ? (r.spaceFunction === filters.spaceFunction || (filters.spaceFunction === 'empty' && !r.spaceFunction)) : true)
                && (filters.spaceType ? (r.spaceType === filters.spaceType || (filters.spaceType === 'empty' && !r.spaceType)) : true)
                && (filters.dominantComposition ? (r.dominantComposition === filters.dominantComposition || (filters.dominantComposition === 'empty' && !r.dominantComposition)) : true)
                && (filters.managementClass ? (r.managementClass === filters.managementClass || (filters.managementClass === 'empty' && !r.managementClass)) : true)
                && (filters.detailedComposition ? $(r.detailedComposition)?.includes($(filters.detailedComposition)) : true)
                && (filters.annualMaintenanceFrequency ? Number(r.annualMaintenanceFrequency) === Number(filters.annualMaintenanceFrequency) : true)
                && (filters.observation ? $(r.observation)?.includes($(filters.observation)) : true)
                && (filters.nbTrees ? Number(r.nbTrees) === Number(filters.nbTrees) : true)
                && (filters.density ? Number(r.density) === Number(filters.density) : true)
                && (filters.distanceBetweenTrunks ? Number(r.distanceBetweenTrunks) === Number(filters.distanceBetweenTrunks) : true)
                && (filters.vernacularName ? r.vernacularName === filters.vernacularName : true)
                && (filters.gender ? r.gender === filters.gender : true)
                && (filters.species ? r.species === filters.species : true)
                && (filters.cultivar ? r.cultivar === filters.cultivar : true)
                && (filters.averageHealthReview || filters.averageHealthReview === 0 ? r.averageHealthReview === filters.averageHealthReview : true)
                && (filters.averageHeight ? Number(r.averageHeight) === Number(filters.averageHeight) : true)
                && (filters.averageCircumference ? Number(r.averageCircumference) === Number(filters.averageCircumference) : true)
                && (filters.averageCrownDiameter ? Number(r.averageCrownDiameter) === Number(filters.averageCrownDiameter) : true)
            );
        });
    }

    // Tri
    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);
    sortRows = () => {
        const sortDirection = this.state.sortDirection;
        let rows = [...this.state.data.rows];
        if (sortDirection === 'NONE') {
            for (let i = 0; i < this.state.initialOrder.length; i++) {
                let temp = rows[i];
                const index = rows.findIndex(row => row.id === this.state.initialOrder[i]);
                rows[i] = rows[index];
                rows[index] = temp;
            }

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: rows
                }
            }));
        } else {
            const sortColumn = this.state.sortColumn;
            if (['managementClass', 'annualMaintenanceFrequency', 'surface', 'carbonStock',
                'nbTrees', 'density', 'distanceBetweenTrunks', 'healthReview', 'averageHeight', 'averageCircumference', 'averageCrownDiameter'].includes(sortColumn))
                rows = rows.sort((a, b) => (a[sortColumn] || 0) - (b[sortColumn] || 0));
            else if (sortColumn === 'date')
                rows = rows.sort((a, b) => {
                    const aDate = DatesUtil.convertDateStringToDate(a[sortColumn]), bDate = DatesUtil.convertDateStringToDate(b[sortColumn]);
                    return !aDate ? -1 : !bDate ? 1 : aDate - bDate;
                });
            else rows = rows.sort((a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: sortDirection === 'DESC' ? rows.reverse() : rows
                }
            }));
        }
    }

    handleKeyDown = (e) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            const { selectedColumn, selectedRow } = this.state;
            if (selectedColumn && selectedRow) navigator.clipboard.writeText(selectedRow[selectedColumn.key] || '');
        }
    }

    didChange = (key, historyId) => {
        if (this.state.elements.length > 0) {
            const index = this.state.initialOrder.findIndex(id => id === historyId);
            if (index !== -1 && index < this.state.initialOrder.length - 1) {
                const history = this.state.data.rows.find(row => row.id === historyId);
                const prevHistoryId = this.state.initialOrder[index + 1];
                const prevHistory = this.state.data.rows.find(row => row.id === prevHistoryId);
                if (JSON.stringify(history[key]) !== JSON.stringify(prevHistory[key]))
                    return true;
            }
        }

        return false;
    }

    isSameState = (properties) => {
        const { elementHistory } = this.props;
        if (elementHistory) {
            const latestHistory = JSON.parse(JSON.stringify(elementHistory[elementHistory.length - 1].greenSpace.properties));
            const targetHistory = JSON.parse(JSON.stringify(properties));
            delete latestHistory.id; delete latestHistory.greenSpaceSnapshotId; delete latestHistory.baseLine; delete latestHistory.surface;
            delete targetHistory.id; delete targetHistory.greenSpaceSnapshotId; delete targetHistory.baseLine; delete targetHistory.surface;

            [...(latestHistory.trunks || []), ...(targetHistory.trunks || [])].forEach(trunk => {
                delete trunk.id;
                delete trunk.propertiesId;
            });

            return JSON.stringify(targetHistory) === JSON.stringify(latestHistory);
        }
    }

    restoreHistory = () => {
        const { historyToRestore } = this.state;

        const history = this.state.elements.find(history => history.id === historyToRestore);
        if (history) {
            const properties = history.greenSpace.properties;
            const { layer, elementHistory, project } = this.props;

            this.setState({ isRestoring: true });
            UpdatesUtil.updateGreenSpace(layer[0]?.feature.id, properties, layer, true, this.props.fieldList, this.props.greenSpacesLayer.activeChild, 'restoring', project.id, { webSocketHubs: this.props.webSocketHubs, thematicMaps: this.props.project.thematicMaps })
                .then(response => {
                    if (response?.data?.history) {
                        this.setState(prevState => ({ elements: [...prevState.elements, response.data.history] }));
                        if (elementHistory) this.props.setElementHistory([...elementHistory, response.data.history]).then(this.loadData);
                    }
                })
                .finally(() => {
                    this.setState({ isRestoring: false, historyToRestore: 0 });
                    this.props.setEditedProperties(null);
                });
        }
    };
}

const mapStateToProps = (state) => {
    return {

        spaceFunctions: state.spaceFunctions,
        spaceTypes: state.spaceTypes,
        spaceTypeCategories: state.spaceTypeCategories,
        dominantCompositions: state.dominantCompositions,
        managementClasses: state.managementClasses,
        essences: state.essences,
        healthReviews: state.healthReviews,
        project: state.project,
        layer: state.layer,
        elementHistory: state.elementHistory,
        webSocketHubs: state.webSocketHubs,
        projectCollaborators: state.projectCollaborators,
        isDarkTheme: state.isDarkTheme,
        rights: state.rights,
        defaultFieldCategories: state.defaultFieldCategories,
        customFields: state.project
            ? [...state.customFields, ...state.organizationCustomFields || [], ...(state.projectsCustomFields[state.project?.id] || [])]
            : state.customFields
    };
};

const mapDispatchToProps = {
    setElementHistory,
    setEditedProperties,
    unlockEditedProperties
};

export default connect(mapStateToProps, mapDispatchToProps)(GreenSpaceHistory);