import React, { Component } from 'react';
// Composants
import { Button, Checkbox, Form, Grid, Input, Message, Select } from 'semantic-ui-react';
import DatePicker from '../../Utils/DatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import i18n from '../../../locales/i18n';
import { connect } from 'react-redux';
import { addDays, startOfDay } from 'date-fns';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { setProjectActions } from '../../../actionCreators/projectsActions';
// Ressources
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';

const initialError = {
    hidden: true,
    messages: [],
    startDate: false,
    endDate: false
}

class PAModificationForm extends Component {
    state = {
        action: null,
        projectAction: null,
        recurrence: false,
        error: initialError,
        isLoading: false
    }

    render() {
        const { projectAction, action, recurrence, error, isLoading } = this.state;
        const recurrenceValue = recurrence ? projectAction.recurrence.split(' - ')[0] : null;
        const recurrenceUnit = recurrence ? projectAction.recurrence.split(' - ')[1] : null;
        // Date de début
        let startDate, endDate, minDateDP;
        if (projectAction?.startDate && projectAction?.startDate) {
            startDate = new Date(projectAction.startDate.valueOf());
            minDateDP = new Date(projectAction.startDate.valueOf());
            if (recurrence) minDateDP = addDays(minDateDP, 1);
        }
        if (projectAction?.startDate && projectAction?.startDate) endDate = new Date(projectAction.endDate.valueOf());

        const recurrenceUnits = [
            { text: i18n.t("Jours"), value: 'Jours' },
            { text: i18n.t("Semaines"), value: 'Semaines' },
            { text: i18n.t("Mois"), value: 'Mois' },
            { text: i18n.t("Ans"), value: 'Années' }
        ];
        let recurrenceBtnDisabled = false;
        if (projectAction && projectAction.actionId && projectAction.actionId !== '') {
            const action = this.props.actions.find(x => x.id === projectAction.actionId);
            if (action) recurrenceBtnDisabled = !action.recurrencesAllowed;
            if (recurrenceBtnDisabled && recurrence) {
                this.setState(prevState => ({
                    recurrence: false,
                    projectAction: {
                        ...prevState.projectAction,
                        endDate: null
                    }
                }));
            }
        }

        const oldRecurrence = this.props.projectAction?.startDate !== this.props.projectAction?.endDate;
        const paChanged = JSON.stringify(projectAction) !== JSON.stringify(this.props.projectAction);
        const recurrenceChanged = oldRecurrence !== recurrence;
        const isValid = (!paChanged && !recurrenceChanged) || (!recurrence && !projectAction?.startDate) ||
            (
                recurrence && (!projectAction?.startDate || !projectAction?.endDate || projectAction?.startDate === projectAction?.endDate || parseInt(projectAction?.recurrenceValue) <= 0 ||
                    error.startDate || error.endDate || error.recurrence)
            ) ? false : true;

        return (
            <Form onSubmit={this.handleSubmit} loading={isLoading} error>
                <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                    <Grid.Row columns={isMobileOnly ? 1 : isTablet ? 2 : 3} style={{ padding: 0 }}>
                        <Grid.Column>
                            <Grid.Row>
                                <Grid.Column computer={8} tablet={8} mobile={16}>
                                    <Form.Field
                                        control={Select} options={action ? [action] : []} search={FormattersUtil.searchList} clearable disabled
                                        name='actionId' value={projectAction?.actionId || ''}
                                        label={`${i18n.t("Action")} : `} placeholder={i18n.t("Sélectionnez une action")}
                                        selectOnBlur={false} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row style={{ height: '59.59px', display: 'flex', alignItems: 'center' }}>
                                <Grid.Column computer={8} tablet={8} mobile={16}>
                                    <Checkbox label={i18n.t("Action récurrente")} checked={recurrence} disabled={true} />
                                    <Checkbox label={i18n.t("Action urgente")} name='isUrgent' checked={projectAction?.isUrgent} style={{ marginLeft: '10px' }} onChange={this.handleCheckboxChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Column>
                            <Grid.Row>
                                <Grid.Column>
                                    <DatePicker label={`${!recurrence ? i18n.t("Date de réalisation") : i18n.t("Date de début")} :`} name='startDate' value={startDate} disabled={recurrence} onChange={!recurrence && this.handleDateChange} />
                                </Grid.Column>
                            </Grid.Row>
                            {recurrence &&
                                <Grid.Row>
                                    <Grid.Column>
                                        <DatePicker
                                            label={`${i18n.t("Date de fin")} :`} name='endDate' value={endDate} style={{ marginTop: '5px' }}
                                            minDate={minDateDP || ''} error={error.endDate} onChange={this.handleDateChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>}
                        </Grid.Column>
                        {recurrence &&
                            <Grid.Column>
                                <Grid.Row>
                                    <Grid.Column computer={16} tablet={16} mobile={16}>
                                        <span className='field' style={{ flexGrow: 0, fontSize: '.92857143em', fontWeight: 700 }}>
                                            {i18n.t("Récurrence")} :
                                        </span>
                                        <Form.Group widths='equal'>
                                            <Form.Field
                                                control={Input} fluid disabled
                                                type='text' value={projectAction?.recurrenceUnit !== 'Semaines' ? i18n.t("Tous les") : i18n.t("Toutes les")}
                                            />
                                            <Form.Field
                                                control={Input} fluid type='number' step='1' error={error.recurrence}
                                                name='recurrenceValue' value={recurrenceValue || ''} disabled
                                            />
                                            <Form.Field
                                                control={Select} options={recurrenceUnits} fluid selectOnBlur={false}
                                                name='recurrenceUnit' value={recurrenceUnit || ''} disabled
                                            />
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid.Column>}
                    </Grid.Row>
                    {!error.hidden &&
                        <Grid.Row>
                            <Grid.Column>
                                <Message
                                    error hidden={error.hidden} style={{ textAlign: 'left' }}
                                    header='Erreur' list={error.messages}
                                />
                            </Grid.Column>
                        </Grid.Row>}
                    <Grid.Row style={{ padding: '0px' }}>
                        <Grid.Column>
                            <Button className='form-button' type='button' color='red' onClick={this.props.cancel}>
                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                            </Button>
                            <Button className='form-button' type='submit' color='green' id='rigRXCCA' disabled={!isValid || isLoading || !this.props.isOnline}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        );
    }

    componentDidMount = () => {
        const { projectAction } = this.props;
        const action = this.props.actions.find(action => action.id === projectAction.actionId);
        const recurrence = projectAction?.startDate !== projectAction?.endDate;
        this.setState({ projectAction: JSON.parse(JSON.stringify(projectAction)), recurrence, action: { text: action.label, value: action.id } });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.projectAction && this.props.projectAction && prevProps.projectAction.id !== this.props.projectAction.id) {
            const { projectAction } = this.props;
            const action = this.props.actions.find(action => action.id === projectAction.actionId);
            const recurrence = projectAction?.startDate !== projectAction?.endDate;

            this.setState({ projectAction: JSON.parse(JSON.stringify(projectAction)), recurrence, action: { text: action.label, value: action.id } });
        }
    }

    handleCheckboxChange = (_, { name, checked }) => this.setState(prevState => ({ projectAction: { ...prevState.projectAction, [name]: checked } }));
    handleRecurrenceChange = (_, { checked }) => this.setState({ recurrence: checked });
    handleDateChange = (_, { name, value }) => {
        const { projectAction, recurrence } = this.state;
        let { startDate, endDate } = projectAction;
        let error = false; let hidden = true;
        let messages = [];
        const addError = (message) => {
            value = null; error = true; hidden = false;
            messages.push(message);
        }

        if (!recurrence) {
            startDate = value;
            endDate = value;
        } else if (name === 'startDate') startDate = value;
        else endDate = value;

        if (value && value.getFullYear().toString().length !== 4)
            addError(i18n.t("Veuillez saisir une date valide"));
        else if (recurrence && name === 'startDate' && this.state.projectAction.endDate && value > this.state.projectAction.endDate)
            addError(i18n.t("Veuillez saisir une date de début valide"));
        else if (recurrence && name === 'endDate' && this.state.projectAction.startDate && this.state.projectAction.startDate > value)
            addError(i18n.t("Veuillez saisir une date de fin valide"));

        this.setState({
            projectAction: { ...this.state.projectAction, startDate, endDate },
            error: { ...this.state.error, [name]: error, messages: messages, hidden: hidden }
        });
    }

    handleSubmit = () => {
        const isReccurent = this.state.projectAction.startDate === this.state.projectAction.endDate ? false : true;
        const projectAction = { ...JSON.parse(JSON.stringify(this.state.projectAction)), endDate: !this.state.recurrence ? this.state.projectAction.startDate : this.state.projectAction.endDate }
        const paerListToDelete = isReccurent && projectAction.projectActionElements.length
            ? projectAction.projectActionElements[0].projectActionElementRecurrences.filter(paer => startOfDay(new Date(paer.date)) < startOfDay(new Date(projectAction.startDate)) || startOfDay(new Date(paer.date)) > startOfDay(new Date(projectAction.endDate)))
            : [];
        this.props.submit(projectAction, paerListToDelete.length * projectAction.projectActionElements.length);
    }
}

const mapStateToProps = (state) => {
    return {
        actions: state.actions,
        projectActions: state.projectActions,
        project: state.project,
        webSocketHubs: state.webSocketHubs,
        isOnline: state.isOnline
    };
};

const mapDispatchToProps = {
    setProjectActions
}


export default connect(mapStateToProps, mapDispatchToProps)(PAModificationForm);