import React, { Component } from 'react';
// Composants
import { Button, Dimmer, Header, Loader, Progress, TextArea } from 'semantic-ui-react';
import UseAnimations from 'react-useanimations';
// Librairies
import i18n from '../../locales/i18n';
import { connect } from 'react-redux';
import alert from 'react-useanimations/lib/alertCircle';
import settings from 'react-useanimations/lib/settings';
import Bowser from 'bowser';
import { isMobileOnly } from 'react-device-detect';
// Services
import ErrorsService from '../../services/ErrorsService';
// Utils
import StylesUtil from '../../utils/StylesUtil';

class ErrorBoundary extends Component {
    state = {
        hasError: false,
        isUnderMaintenance: false,
        secondsUntilRefresh: -1,
        errorMessage: null
    };

    render() {
        const { updateApp } = this.props;
        const { hasError, isUnderMaintenance, secondsUntilRefresh, errorMessage } = this.state;

        if (hasError) {
            return (
                <div id='error-handler'>
                    <Header as='h1' icon textAlign='center'>
                        {updateApp &&
                            <Dimmer active style={StylesUtil.getMapStyles().dimmerStyle}>
                                <Loader content={
                                    <Progress value={2} total={4} size='tiny' style={isMobileOnly ? { width: '200px' } : { width: '400px' }}>
                                        <div style={{ color: 'white' }}>{i18n.t("Mise à jour de l'application...")}</div>
                                    </Progress>
                                } />
                            </Dimmer>}
                        {isUnderMaintenance &&
                            <>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <UseAnimations animation={settings} strokeColor='white' autoplay loop size={130} />
                                </div>
                                <Header.Content style={{ color: 'white' }}>{i18n.t("L'application est en cours de maintenance...")}</Header.Content>
                                <Header.Subheader as='h3' style={{ color: 'white' }}>{i18n.t("Une nouvelle version sera bientôt disponible.")}</Header.Subheader>
                            </>}
                        {!updateApp && !isUnderMaintenance &&
                            <>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <UseAnimations animation={alert} strokeColor='white' autoplay loop size={130} />
                                </div>
                                <Header.Content style={{ color: 'white' }}>{i18n.t("Oops ! Une erreur est survenue...")}</Header.Content>
                                <TextArea className='text-area--error'>
                                    {errorMessage}
                                </TextArea>
                                <Header.Subheader as='h3' style={{ color: 'white' }}>{i18n.t("L'équipe technique travaille actuellement sur le problème.")}</Header.Subheader>
                                <Header.Content style={{ marginTop: '5px' }} >
                                    <Button content={`${i18n.t("Rafraîchir la page")} (${secondsUntilRefresh})`} onClick={() => window.location.reload()} />
                                </Header.Content>
                            </>}
                    </Header>
                </div>
            );
        }

        return this.props.children;
    }

    componentDidCatch = (error, errorInfo) => {
        const changeCountdown = () => {
            this.setState(prevState => ({ secondsUntilRefresh: prevState.secondsUntilRefresh - 1 }), () => {
                if (this.state.secondsUntilRefresh <= 0) window.location.reload();
                else setTimeout(changeCountdown, 1000);
            });
        };

        if (!this.triggeredCountdown) {
            this.triggeredCountdown = true;
            this.setState({ hasError: true, isUnderMaintenance: error.message === 'isUnderMaintenance' });

            if (error.message !== 'isUnderMaintenance') {
                if (this.props.isOnline || this.props.updateApp)
                    this.stopServiceWorker();

                if (!this.props.updateApp) {
                    const bowser = Bowser.parse(window.navigator.userAgent);
                    ErrorsService.addError({
                        projectId: this.props.project?.id || null,
                        date: new Date().toUTCString(),
                        message: error.message + errorInfo.componentStack,
                        device: `${bowser?.platform?.vendor || ''} ${bowser?.platform?.type || ''}`.trim(),
                        os: `${bowser?.os?.name || ''} ${bowser?.os?.versionName || ''} ${bowser?.os?.version || ''}`.trim(),
                        browser: `${bowser?.browser?.name || ''} ${bowser?.browser?.version || ''}`.trim(),
                        language: navigator.language || navigator.userLanguage,
                        url: window.location.href,
                        connectionSpeed: navigator.connection.effectiveType
                    });

                    this.setState({ secondsUntilRefresh: 60, errorMessage: error.message }, () => {
                        setTimeout(changeCountdown, 1000);
                    });
                }
            }
        }
    };

    stopServiceWorker = () => {
        const promises = [];
        navigator.serviceWorker.getRegistrations().then(registrations => {
            if (registrations)
                for (const registration of registrations)
                    promises.push(registration.unregister());
        });
        Promise.all(promises).then(() => this.emptyCache());
    }

    emptyCache = () => {
        const itemsToKeep = [
            'projectListSort', 'projectListDisplay', 'notifications', 'isFullScreen', 'projectsView', 'isDarkTheme', 'actionListShowPreferences',
            'legendPosition', 'favoriteTools', 'chromeModalClosed', 'loginAsData'
        ];

        for (const item in localStorage)
            if (!itemsToKeep.includes(item))
                localStorage.removeItem(item);

        const promises = []
        caches.keys().then(keys => keys.forEach(key => {
            promises.push(caches.delete(key));
        }));
        if (this.props.updateApp) Promise.all(promises).then(() => window.location.reload());
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        updateApp: state.updateApp,
        project: state.project
    };
};

export default connect(mapStateToProps)(ErrorBoundary);