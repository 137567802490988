import React, { Component } from 'react';
// Composants
import { Button, Dimmer, Grid, Message } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
// Utils
import StylesUtil from '../../utils/StylesUtil';

class PopupMessage extends Component {
    render() {
        return (
            <Dimmer
                active style={{ ...StylesUtil.getMapStyles().dimmerStyle, position: 'fixed', top: 0, left: 0, width: '100%', height: '100vh', zIndex: 9999 }}
                onClick={({ target }) => { if (target.classList.contains('dimmer')) this.props.submit(); }}
            >
                <Grid style={{ height: '100%' }}>
                    <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                        <Grid.Column textAlign='left'>
                            <Message className='input-popup-form' style={{ maxWidth: '400px' }}>
                                {this.props.title && <Message.Header style={{ textAlign: 'center' }}>{this.props.title}</Message.Header>}
                                <Message.Content style={{ marginTop: '10px' }}>
                                    {this.props.content && <div style={{ marginBottom: '10px' }}>{this.props.content}</div>}
                                    <Button color={this.props.buttonColor || 'blue'} onClick={this.props.submit}>
                                        <FontAwesomeIcon icon={this.props.submitButtonIcon || null} style={{ marginRight: '10px' }} />
                                        {this.props.submitButtonLabel || i18n.t("Ok")}
                                    </Button>
                                </Message.Content>
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Dimmer>
        );
    }

    componentDidMount = () => document.addEventListener('keydown', this.handleKeyDown);
    componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);

    handleKeyDown = (e) => {
        if (e.key?.toLowerCase() === 'enter') {
            e.preventDefault();
            this.props.submit();
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isOnline: state.isOnline,
        isDarkTheme: state.isDarkTheme
    };
};

export default connect(mapStateToProps)(PopupMessage);