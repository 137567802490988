import React, { Component } from 'react';
// Composants
import { Button, Form, Grid, Input, Segment, Divider, Checkbox, Message } from 'semantic-ui-react';
import ImprovedSearch from '../../../Utils/ImprovedSearch';
import InfoIcon from '../../../Utils/InfoIcon';
// Librairies
import i18n from '../../../../locales/i18n';
import { connect } from 'react-redux';
import { setThemes, setPriceLists } from '../../../../actionCreators/usersActions';
// Utils
import ProjectsService from '../../../../services/ProjectsService';
import GeometriesUtil from '../../../../utils/GeometriesUtil';

const initialError = {
    messages: [],
    label: false,
    municipality: false,
    shapefile: false
};

class ProjectMainSettingsForm extends Component {
    state = {
        isLoading: false,
        fileUploadPercentage: 0,
        error: initialError,
        municipality: 0,
        loadedMunicipalities: []
    };

    render() {
        const { projectToEdit, municipalities, loginAsData, isDuplication, isRecommandation, duplicateElements } = this.props;
        const { isLoading, error, municipality } = this.state;
        const municipalityOption = (municipalities || []).find(m => m.value === municipality);

        return (
            <>
                {isDuplication &&
                    <>
                        <h3>{i18n.t("Options de duplication")}</h3>
                        <Grid>
                            <Grid.Row style={{ paddingBottom: 0 }}>
                                <Grid.Column computer={16} tablet={16} mobile={16}>
                                    <div style={{ display: 'flex' }}>
                                        <Checkbox name='isRecommandation' label={i18n.t("Recommandation")} checked={isRecommandation} onChange={this.props.handleCheckboxChange} style={{ marginBottom: '10px', alignSelf: 'flex-start' }} />
                                        <InfoIcon content={i18n.t("Associer le projet copié à l'original afin de comparer les deux versions dans le tableau de bord.")} iconStyle={{ position: 'relative', top: 0, right: 0, marginLeft: '5px' }} />
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <Checkbox name='duplicateElements' label={i18n.t("Dupliquer les données")} checked={duplicateElements} onChange={this.props.handleCheckboxChange} style={{ marginBottom: '10px', alignSelf: 'flex-start' }} />
                                        <InfoIcon content={i18n.t("Dupliquer les arbres, espaces verts, mobiliers, calques, actions, photos, fichiers, historiques et bookmarks de ce projet dans la copie.")} iconStyle={{ position: 'relative', top: 0, right: 0, marginLeft: '5px' }} />
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </>}
                <h3>Paramètres généraux</h3>
                <Grid>
                    <Grid.Row style={{ paddingBottom: 0 }}>
                        <Grid.Column computer={6} tablet={8} mobile={16}>
                            <Form.Field
                                control={Input} label={`${i18n.t("Nom du projet")} :`} placeholder={`Ex: ${i18n.t("Aménagement des parcs")}`} name='label' value={projectToEdit.label || ''}
                                autoComplete='off' error={error.label} style={{ marginBottom: '10px' }} disabled={loginAsData?.readOnly} onChange={(_, { value }) => this.props.handleChange('label', value)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ paddingTop: 0 }}>
                        <Grid.Column computer={6} tablet={8} mobile={16}>
                            <label style={{ fontSize: '13px', fontWeight: 'bolder' }}>{i18n.t("Contour du projet : Choisissez la méthode de tracé")}</label>
                            <Segment loading={isLoading || !municipalities} style={{ marginTop: 0 }}>
                                <label>{i18n.t("Tracé manuel sur la carte :")}</label>
                                {municipality ?
                                    <div style={{ display: 'flex' }}>
                                        <Button
                                            type='button' color='yellow' id='YdIdQLe8' style={{ flex: 1 }}
                                            title={i18n.t("Modifier la zone géographique")} content={i18n.t("Modifier")}
                                            icon='edit' disabled={isLoading || loginAsData?.readOnly} onClick={this.props.handleDrawClick}
                                        />
                                        <Button
                                            type='button' color='red' id='bZIdQTu7' style={{ flex: 1 }}
                                            title={i18n.t("Supprimer la zone géographique")} content={i18n.t("Supprimer")}
                                            icon='trash' disabled={isLoading || loginAsData?.readOnly} onClick={this.clearSurroundings}
                                        />
                                    </div>
                                    : <Button
                                        type='button' color='blue' id='DxZCDdlL' style={{ width: '100%' }}
                                        content={i18n.t("Tracer une zone personnalisée")} icon='pencil alternate'
                                        disabled={isLoading || loginAsData?.readOnly} onClick={this.props.handleDrawClick}
                                    />}
                                <Divider horizontal>{i18n.t("Ou")}</Divider>
                                <ImprovedSearch
                                    label={i18n.t("Le projet correspond à une ville entière")} placeholder={i18n.t("Rechercher une commune")}
                                    selectedValue={municipalityOption} value={municipality} values={municipalities}
                                    handleChange={this.handleMunicipalityChange} error={error.municipality}
                                />
                                <Divider horizontal>{i18n.t("Ou")}</Divider>
                                <label>
                                    {i18n.t("Importer depuis un fichier Shapefile")}
                                    <InfoIcon content={i18n.t("La projection des coordonnées du contour doit être au format WGS84.")} iconStyle={{ position: 'relative', top: 0, right: 0, marginLeft: '5px' }} /> :
                                </label>
                                <Button
                                    type='button' color='green' as='label' htmlFor='file-input' style={{ width: '100%' }}
                                    title={i18n.t("Charger un Shapefile (.shp, .shx et .dbf)")} content={i18n.t("Charger un Shapefile (.shp, .shx et .dbf)")}
                                    icon='upload' disabled={isLoading || loginAsData?.readOnly}
                                />
                                <input type='file' id='file-input' multiple hidden accept={['.shp', '.shx', '.dbf']} onChange={({ target }) => {
                                    this.uploadFiles(Array.from(target.files));
                                    target.value = '';
                                }} />
                                <Message
                                    error hidden={!error.shapefile}
                                    header={i18n.t("Erreur")}
                                    content={i18n.t("Le shapefile n'est pas valide.")}
                                />
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </>
        );
    }

    componentDidMount = () => {
        const surroundings = this.props.projectToEdit.surroundings ? JSON.parse(this.props.projectToEdit.surroundings) : null;
        this.setState({ municipality: surroundings ? surroundings.id || -1 : 0 });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.projectToEdit && this.props.projectToEdit?.surroundings && prevProps.projectToEdit.surroundings !== this.props.projectToEdit.surroundings) {
            const surroundings = this.props.projectToEdit.surroundings ? JSON.parse(this.props.projectToEdit.surroundings) : null;
            this.setState({ municipality: surroundings ? surroundings.id || -1 : 0 });
        }
    }

    handleMunicipalityChange = (_, { value }) => {
        const loadedMunicipality = this.state.loadedMunicipalities.find(municipality => municipality.id === value);
        if (loadedMunicipality) {
            this.props.handleChange('surroundings', JSON.stringify(loadedMunicipality));
            this.setState(prevState => ({
                error: { ...prevState.error, municipality: false },
                municipality: value
            }));
        } else {
            this.setState({ isLoading: true });
            ProjectsService.getMunicipality(value).then(municipality => {
                this.props.handleChange('surroundings', JSON.stringify(municipality));
                this.setState(prevState => ({
                    isLoading: false,
                    error: { ...prevState.error, municipality: false },
                    municipality: value,
                    loadedMunicipalities: [...prevState.loadedMunicipalities, municipality]
                }));
            });
        }
    }

    clearSurroundings = () => {
        this.setState({ municipality: 0 });
        this.props.handleChange('surroundings', null);
    }

    uploadFiles = (files) => {
        const shapefilesExtensions = ['.shp', '.shx', '.dbf'];
        const missingFiles = shapefilesExtensions.filter(extension => !files.find(file => file.name.endsWith(extension)));

        if (missingFiles.length) this.getMissingShapeFilesMessage(files, missingFiles);
        else {
            const axiosOptions = {
                onUploadProgress: (processEvent) => {
                    const { loaded, total } = processEvent;
                    let percent = Math.floor((loaded * 100) / total);
                    // TODO
                    // steps[index].loadingMessage = i18n.t("Envoi du fichier ({{percentage}}%)...", { percentage: percent });
                    this.setState({ fileUploadPercentage: percent });
                }
            }

            const formData = new FormData();
            files.forEach(file => formData.append('files', file));
            ProjectsService.importSurroundings(formData, axiosOptions).then(response => {
                if (response && GeometriesUtil.invertPolygon(response)) {
                    delete response.id;
                    // Charger la Country
                    this.props.handleChange('surroundings', JSON.stringify(response));
                    this.setState(prevState => ({
                        error: { ...prevState.error, shapefile: false },
                    }));
                } else {
                    this.setState(prevState => ({
                        error: { ...prevState.error, shapefile: true },
                    }));
                }
            });
        }
    }

    getMissingShapeFilesMessage = (files, missingFiles) => {
        return files.length > 1
            ? i18n.t("Vous devez fournir le fichier {{missingFile}} en plus des fichiers {{providedFiles}}", { missingFile: missingFiles[0], providedFiles: files.map(file => '.' + file.name.split('.')[1]).join(', ') })
            : i18n.t("Vous devez fournir les fichiers {{missingFiles}} en plus du fichier {{providedFile}}", { missingFiles: missingFiles.join(', '), providedFile: '.' + files[0].name.split('.')[1] });
    }
}

const mapStateToProps = (state) => {
    return {
        userProjects: state.userProjects,
        loginAsData: state.loginAsData
    };
};

const mapDispatchToProps = {
    setThemes,
    setPriceLists
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMainSettingsForm);