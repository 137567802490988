import React, { Component } from 'react';
// Composants
import { Checkbox } from 'semantic-ui-react';
import { Pie } from 'react-chartjs-2';
// Librairies
import 'chartjs-plugin-labels/src/chartjs-plugin-labels';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
// Utils
import StylesUtil from '../../utils/StylesUtil';

class HealthReviewsChart extends Component {
    state = {
        data: null,
        options: null,
        includeCuttedTrees: true
    }

    render() {
        const { includeCuttedTrees, data, options } = this.state;

        return (
            <div className='modal-content'>
                <div className='modal-content-body'>
                    {data && options ? <>
                        <Checkbox
                            toggle label={i18n.t("Arbres abattus")} checked={includeCuttedTrees} style={isMobileOnly ? null : { position: 'absolute', top: 10, right: 20 }}
                            onChange={(_, { checked }) => this.setState({ includeCuttedTrees: checked }, () => this.loadData())}
                        />
                        <Pie data={data} options={options} />
                    </> : i18n.t("Aucune donnée trouvée")}
                </div>
            </div>
        );
    }

    componentDidMount = () => {
        this.loadData();
        if (this.props.webSocketHubs.elementsHub) {
            this.props.webSocketHubs.elementsHub.on('SendElements', this.loadData);
            this.props.webSocketHubs.elementsHub.on('UpdateElements', this.loadData);
            this.props.webSocketHubs.elementsHub.on('RemoveElements', this.loadData);
        }
    }

    loadData = () => {
        const { includeCuttedTrees } = this.state;

        let labels = this.props.healthReviews.map(x => x.description);
        let values = this.props.healthReviews.map(() => 0);;
        let count = 0;

        // On compte le nombre d'arbres par genre
        const layers = this.props.layer.getLayers();
        for (const layerName in layers) {
            const layer = layers[layerName];
            if (includeCuttedTrees || layer.feature.properties.toCutDown !== 4) {
                const healthReview = this.props.healthReviews.find(x => x.id === layer.feature.properties.healthReviewId)?.description;
                if (healthReview) {
                    const index = labels.indexOf(healthReview);
                    if (index >= 0) {
                        count++;
                        values[index]++;
                    }
                }
            }
        }
        labels = labels.map((label, index) => label + ` (${values[index]})`);

        if (count > 0) {
            const themeColor = this.props.isDarkTheme ? 'white' : 'black';
            this.setState({
                data: {
                    labels: labels,
                    datasets: [{
                        data: values,
                        backgroundColor: labels.map(label => {
                            return StylesUtil.getHealthReviewColor(this.props.healthReviews.find(x => x.description === label.split(' (')[0]).value);
                        }),
                        borderColor: 'rgba(0, 0, 0, 1)',
                        borderWidth: 1
                    }]
                },
                options: {
                    maintainAspectRatio: false,
                    plugins: {
                        labels: {
                            render: 'percentage',
                            showZero: false,
                            position: 'outside',
                            textMargin: 8,
                            fontColor: themeColor
                        }
                    },
                    legend: {
                        position: isMobileOnly ? 'bottom' : 'right',
                        labels: {
                            fontColor: themeColor
                        }
                    }
                }
            });
        }
    }
}

const mapStateToProps = (state) => {
    return {
        healthReviews: state.healthReviews,
        isDarkTheme: state.isDarkTheme,
        webSocketHubs: state.webSocketHubs
    };
};

export default connect(mapStateToProps)(HealthReviewsChart);