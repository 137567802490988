import AppSettings from '../AppSettings';
// Librairies
import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'universal-cookie';
// Utils
import OfflineUtil from '../utils/OfflineUtil';
import { showLoadingToast, showToast } from '../utils/ToastsUtil';
import WebSocketUtil from '../utils/WebSocketUtil';
import i18n from '../locales/i18n';

const BACKEND_ENDPOINT = AppSettings.getBackendUrl();

export default class FurnituresService {
    static addFurniture(furniture, action, webSocketHubs) {
        furniture.id = uuidv4();
        furniture.properties.furnitureId = furniture.id;
        const promise = Axios.post(BACKEND_ENDPOINT + 'furnitures/' + webSocketHubs?.elementsHub?.connectionId, furniture, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            furniture.properties = response.data.properties;
            return response;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            else {
                if (furniture.projectId) {
                    OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'furnitures/isMaxReached/' + furniture.projectId, (body) => {
                        if (body.state) showToast('furnitures_limit_reached');
                        else if (body.maxElement > -1 && (body.maxElements - (body.nbElements + 1)) <= 10) {
                            body.nbElements++;
                            showToast('furnitures_limit_almost_reached', '(' + body.nbElements + '/' + body.maxElements + ')');
                            if (body.maxElements === body.nbElements) body.state = true;
                        } else body.nbElements++;
                        return body;
                    });
                }
                else if (furniture.projectId) showToast('furnitures_limit_verification_failed');
            }
            return Promise.reject();
        });

        const toastId = 'furniture_' + (action === 'copyPasting' ? 'copy_pasting' : action);
        const successId = 'furniture_' + (action === 'copyPasting' ? 'copy_pasted' : action.replace('ing', 'ed'));
        const errorId = 'furniture_' + (action === 'adding' ? 'addition' : 'copy_paste') + '_failed';
        if (navigator.onLine) showLoadingToast(toastId, successId, errorId, promise);
        else showToast('will_sync');
        return promise;
    }

    static addFurnitures(furnitures, action, webSocketHubs) {
        furnitures.forEach(f => {
            f.id = uuidv4();
            f.properties.furnitureId = f.id;
        });
        const promise = Axios.post(BACKEND_ENDPOINT + 'furnitures/bulk/' + webSocketHubs?.elementsHub?.connectionId, furnitures, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            response.data.forEach(f => {
                let furniture = furnitures.find(x => x.id === f.id);
                furniture.properties = f.properties;
            });
            return response;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            else {
                if (furnitures[0].projectId) {
                    OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'furnitures/isMaxReached/' + furnitures[0].projectId, (body) => {
                        if (body.state) showToast('furnitures_limit_reached');
                        else if (body.maxElement > -1 && (body.maxElements - (body.nbElements + furnitures.length)) <= 10) {
                            body.nbElements += furnitures.length;
                            showToast('furnitures_limit_almost_reached', '(' + body.nbElements + '/' + body.maxElements + ')');
                            if (body.maxElements === body.nbElements) body.state = true;
                        } else body.nbElements += furnitures.length;
                        return body;
                    });
                }
                else if (furnitures[0].projectId) showToast('furnitures_limit_verification_failed');
            }
            return Promise.reject();
        });

        const prefix = furnitures.length > 1 ? 'furnitures_' : 'furniture_';
        const toastId = prefix + (action === 'copyPasting' ? 'copy_pasting' : action);
        const successId = prefix + (action === 'copyPasting' ? 'copy_pasted' : action.replace('ing', 'ed'));
        const errorId = prefix + (action === 'adding' ? 'addition' : action === 'copyPasting' ? 'copy_paste' : 'duplication') + '_failed';
        if (navigator.onLine) showLoadingToast(toastId, successId, errorId, promise, furnitures.length);
        else showToast('will_sync');
        return promise;
    }

    static getFurnitures(projectId, data) {
        return Axios.post((!BACKEND_ENDPOINT.includes('localhost') && (data?.page || data?.page === 0)
            ? BACKEND_ENDPOINT.replace('://', '://' + (Math.floor(Math.random() * 10) + 1) + '.')
            : BACKEND_ENDPOINT) + 'furnitures/projects/' + projectId, data, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine && !data?.cacheKey && !data?.cursor) showToast('furnitures_loading_failed');
            return;
        });
    }

    static removeFurnitures(furnitures, webSocketHubs) {
        const promise = Axios.delete(BACKEND_ENDPOINT + 'furnitures/' + webSocketHubs?.elementsHub?.connectionId, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` },
            data: furnitures
        }).then(response => {
            return response;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            else {
                if (furnitures[0].projectId !== 0) {
                    OfflineUtil.modifyDynamicCache(BACKEND_ENDPOINT + 'furnitures/isMaxReached/' + furnitures[0].projectId, (body) => {
                        body.nbElements -= furnitures.length;
                        if (body.maxElement > -1 && (body.maxElements - body.nbElements) <= 10) body.state = false;
                        return body;
                    });
                }
            }
            return Promise.reject();
        });

        const prefixe = furnitures.length > 1 ? 'furnitures' : 'furniture';
        if (navigator.onLine) showLoadingToast(prefixe + '_deleting', prefixe + '_deleted', prefixe + '_deletion_failed', promise);
        else showToast('will_sync');
        return promise;
    }

    static updateFurniture(id, furniture, history, action, webSocketHubs) {
        const promise = Axios.put(BACKEND_ENDPOINT + 'furnitures/?connectionId=' + webSocketHubs?.elementsHub?.connectionId + '&id=' + id + '&history=' + history, furniture, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            furniture.properties = { ...furniture.properties, ...response.data.furniture.properties };
            return response;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            return Promise.reject();
        });

        const toastId = 'furniture_' + action;
        const successId = 'furniture_' + action.replace('ing', 'ed');
        const errorId = 'furniture_' + (action === 'updating' ? 'update'
            : action === 'restoring' ? 'restoration'
                : action) + '_failed';
        if (navigator.onLine) showLoadingToast(toastId, successId, errorId, promise);
        else showToast('will_sync');
        return promise;
    }

    static bulkUpdateFurnitures(furnitures, action, webSocketHubs) {
        const promise = Axios.put(BACKEND_ENDPOINT + 'furnitures/bulk/' + webSocketHubs?.elementsHub?.connectionId, furnitures, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            if (Array.isArray(response?.data)) {
                response.data.forEach(feature => {
                    let furniture = furnitures.find(furniture => furniture.id === feature.id);
                    if (furniture) furniture.properties = { ...furniture.properties, ...feature.properties };
                });
            }
            return response;
        }, (error) => {
            if (navigator.onLine && error?.response?.data === 'Project is importing data') showToast('import_in_progress');
            return Promise.reject();
        });

        const toastId = 'furnitures_' + action;
        const successId = 'furnitures_' + action.replace('ing', 'ed');
        const errorId = 'furnitures_' + (action === 'updating' ? 'update'
            : action === 'restoring' ? 'restoration'
                : action) + '_failed';
        if (navigator.onLine) showLoadingToast(toastId, successId, errorId, promise);
        else showToast('will_sync');
        return promise;
    }

    static getFurnitureHistory(id) {
        return Axios.get(BACKEND_ENDPOINT + 'furnitures/history/?id=' + id, {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) if (navigator.onLine) showToast('element_history_loading_failed');
            return;
        });
    }

    static getConditions() {
        return Axios.get(BACKEND_ENDPOINT + 'furnitures/conditions', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('conditions_loading_failed');
            return;
        });
    }

    static getFurnitureTypes() {
        return Axios.get(BACKEND_ENDPOINT + 'furnitures/types', {
            headers: { 'Authorization': new Cookies().get('token') ? `Bearer ${new Cookies().get('token')}` : '' }
        }).then(response => {
            return response.data;
        }, () => {
            if (navigator.onLine) showToast('furniture_types_loading_failed');
            return;
        });
    }

    static exportFurnituresFromProjectAsExcel(name, id, elementIds = [], projection) {
        return Axios.post(BACKEND_ENDPOINT + 'furnitures/exportXLSX/' + id, { elementIds, projection }, {
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.ms-excel' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${id} - ${name} (${i18n.t("Mobilier urbain").toLowerCase()}).xlsx`);
            document.getElementById('modal-content').appendChild(link);
            link.click();
            document.getElementById('modal-content').removeChild(link);
        }, () => {
            if (navigator.onLine) showToast('elements_xlsx_export_failed');
        });
    }

    static exportFurnitureAsPDF(furniture, image, photosId, colorless, toastCustomTexts) {
        return Axios.post(BACKEND_ENDPOINT + 'furnitures/exportPDF/' + furniture.id, { image, photosId, colorless }, {
            responseType: 'arraybuffer',
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`,
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            let filename = furniture.properties.projectReference ? `${i18n.t("Fiche mobilier")}_${furniture.properties.projectReference}` : i18n.t("Fiche mobilier");
            if (furniture.properties.customReference) filename += '_' + furniture.properties.customReference;
            filename += '.pdf';
            link.setAttribute('download', filename);
            document.getElementById('tasksStatus').appendChild(link);
            link.click();
            document.getElementById('tasksStatus').removeChild(link);
            showToast('pdf_export_completed', ...toastCustomTexts);
        }, () => {
            if (navigator.onLine) showToast('elements_pdf_export_failed');
        });
    }

    static exportFurnituresAsPDF(projectId, elementIds, { projection, colorless = false, addPhotos = true } = {}) {
        return Axios.post(BACKEND_ENDPOINT + 'furnitures/exportAllPDF/' + projectId, { elementIds, projection, colorless, addPhotos }, {
            headers: { 'Authorization': `Bearer ${new Cookies().get('token')}` }
        }).then(response => {
            return response;
        }, () => {
            if (navigator.onLine) showToast('elements_pdf_export_failed');
            return;
        });
    }

    static exportFurnituresAsSHP(projectLabel, projectId, elementIds, projection) {
        return Axios.post(BACKEND_ENDPOINT + 'furnitures/exportSHP/' + projectId, { elementIds, projection }, {
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${new Cookies().get('token')}`
            }
        }).then(response => {
            if (response.data?.size > 0) {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/zip' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${projectId} - ${projectLabel} (${i18n.t("Mobilier urbain").toLowerCase()}).zip`);
                document.getElementById('modal-content').appendChild(link);
                link.click();
                document.getElementById('modal-content').removeChild(link);
            }
        }, () => {
            if (navigator.onLine) showToast('elements_shp_export_failed');
        });
    }
}
