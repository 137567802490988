import React, { Component } from 'react';
// Semantic UI
import { Form, Input, Icon, Button, Segment, Divider, Message } from 'semantic-ui-react';
import i18n from '../../../locales/i18n';
// Services
import UsersService from '../../../services/UsersService';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';

const initialUser = {
    email: '',
    lastName: '',
    firstName: ''
};

const initialError = { email: false, lastName: false, firstName: false, messages: [] };

export default class UserForm extends Component {
    state = {
        newUser: initialUser,
        error: { ...initialError },
        isLoading: false
    };

    render() {
        const { error, newUser, isLoading } = this.state;

        return (
            <Segment style={{ padding: '20px', width: '500px' }}>
                <h3 style={{ textAlign: 'center' }}>{i18n.t("Ajout d'un nouvel utilisateur")}</h3>
                <Divider />
                <Form onSubmit={this.handleSubmit} error style={{ textAlign: 'left' }}>
                    <Form.Field
                        control={Input} label={`${i18n.t("Email")}* :`} placeholder={i18n.t("Obligatoire")}
                        name='email' value={newUser.email || ''}
                        style={{ marginBottom: '10px' }}
                        onChange={this.handleChange} error={error.email}
                    />
                    <Form.Field
                        control={Input} label={`${i18n.t("Nom")}* :`} placeholder={i18n.t("Obligatoire")}
                        name='lastName' value={newUser.lastName || ''}
                        style={{ marginBottom: '10px' }}
                        onChange={this.handleChange} error={error.lastName}
                    />
                    <Form.Field
                        control={Input} label={`${i18n.t("Prénom")}* :`} placeholder={i18n.t("Obligatoire")}
                        name='firstName' value={newUser.firstName || ''}
                        style={{ marginBottom: '10px' }}
                        onChange={this.handleChange} error={error.firstName}
                    />
                    <Message
                        error hidden={!error.messages?.length} onDismiss={() => this.setState({ error: { ...initialError } })}
                        header={i18n.t("Erreur")} list={error.messages}
                        style={{ textAlign: 'left', marginTop: 0 }}
                    />
                    <Button type='submit' color='green' className='form-button' disabled={isLoading}>
                        {i18n.t("Créer")} <Icon name='add' style={{ marginLeft: '5px', marginRight: 0 }} />
                    </Button>
                </Form>
            </Segment>
        );
    }

    handleChange = (e, { name, value }) => {
        if (value.trim() === '') value = null;
        else value = value.trim();
        this.setState({
            newUser: { ...this.state.newUser, [name]: value },
            error: { ...this.state.error, [name]: false }
        });
    }

    handleSubmit = async () => {
        const { firstName, lastName, email } = this.state.newUser;
        let isValid = true;

        const errors = { ...initialError };

        const addError = (property, message) => {
            errors[property] = true;
            errors.messages = [...(errors.messages || []), message];
            isValid = false;
        };

        if (!(firstName?.trim()) || !FormattersUtil.checkName(firstName))
            addError('firstName', i18n.t("Le prénom ne peut être vide ou contenir des caractères spéciaux"));

        if (!(lastName?.trim()) || !FormattersUtil.checkName(lastName))
            addError('lastName', i18n.t("Le nom ne peut être vide ou contenir des caractères spéciaux"));

        if (!email?.length || !FormattersUtil.checkEmail(email))
            addError('email', i18n.t("L'adresse email ne peut être vide"));
        else {
            const { isUsed } = await UsersService.isEmailInUse(email);
            if (isUsed) addError('email', i18n.t("L'adresse email est déjà utilisée"));
        }

        if (isValid) {
            this.setState({ isLoading: true });
            UsersService.addUser(this.state.newUser).then(() => {
                this.setState({ newUser: initialUser, isLoading: false });
            });
        } else this.setState({ error: { ...errors } });
    }
}