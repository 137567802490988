// Libraries
import L from 'leaflet';
import {
    booleanClockwise, booleanWithin, circle, difference, distance, featureCollection, getCoords, length, lineIntersect, lineOverlap, booleanContains, booleanIntersects,
    lineString, lineToPolygon, point, polygon, transformRotate, lineSliceAlong, length as lineLength, bearing, transformTranslate, transformScale, nearestPointOnLine, union, bboxPolygon
} from '@turf/turf';
import proj4 from 'proj4';
import { showToast } from './ToastsUtil';
// Services
import FormattersUtil from './FormattersUtil';

export default class GeometriesUtil {
    static splitPolygon(poly, line, scaleLine) {
        if (scaleLine) {
            const newLineCoords = GeometriesUtil.getElongatedLine(line.coordinates, lineLength(line, { units: 'meters' }) / 1000);
            line.coordinates = newLineCoords; // Permet de pouvoir relier un sommet à un autre
        }
        const thickLineUnits = 'kilometers';
        const thickLineWidth = 0.001;
        let i, j, intersectPoints, lineCoords, forCut, forSelect;
        let thickLineString, thickLinePolygon, clipped, polyg, intersect;
        let polyCoords = [], cutPolyGeoms = [], cutFeatures = [], offsetLine = [];
        let retVal = null;

        if (((poly.type !== 'Polygon') && (poly.type !== 'MultiPolygon')) || (line.type !== 'LineString'))
            return retVal;

        intersectPoints = lineIntersect(polygon(poly.coordinates), lineString(line.coordinates));
        if (intersectPoints.features.length === 0) return retVal;

        lineCoords = JSON.parse(JSON.stringify(getCoords(line)));
        while (booleanWithin(point(lineCoords[0]), poly) && lineCoords.length > 0)
            lineCoords.splice(0, 1);
        while (booleanWithin(point(lineCoords[lineCoords.length - 1]), poly) && lineCoords.length > 0)
            lineCoords.splice(-1);
        if (lineCoords.length < 2) return retVal;
        else {
            line = JSON.parse(JSON.stringify(line)); // Pour modifier la ligne que par rapport au polygone actuel
            line.coordinates = lineCoords;
        }

        offsetLine[0] = GeometriesUtil.getLineOffset(lineString(line.coordinates), thickLineWidth, { units: thickLineUnits });
        offsetLine[1] = GeometriesUtil.getLineOffset(lineString(line.coordinates), -thickLineWidth, { units: thickLineUnits });

        for (i = 0; i <= 1; i++) {
            forCut = i;
            forSelect = (i + 1) % 2;
            polyCoords = [];
            for (j = 0; j < line.coordinates.length; j++)
                polyCoords.push(line.coordinates[j]);
            for (j = (offsetLine[forCut].geometry.coordinates.length - 1); j >= 0; j--)
                polyCoords.push(offsetLine[forCut].geometry.coordinates[j]);
            polyCoords.push(line.coordinates[0]);

            thickLineString = lineString(polyCoords);
            thickLinePolygon = lineToPolygon(thickLineString);
            clipped = difference(featureCollection([polygon(poly.coordinates), thickLinePolygon]));

            cutPolyGeoms = [];
            for (j = 0; j < clipped.geometry.coordinates.length; j++) {
                if (clipped.geometry.coordinates[j][0][0][0]) { // On vérifie qu'il s'agit bien d'un polygone
                    polyg = polygon(clipped.geometry.coordinates[j]);
                    intersect = lineIntersect(polyg, offsetLine[forSelect]);
                    if (intersect.features.length > 0)
                        cutPolyGeoms.push(polyg.geometry.coordinates);
                }
            }

            cutPolyGeoms.forEach((geometry) => {
                for (let i = 0; i < geometry.length; i++)
                    if (JSON.stringify(geometry[i][0] !== geometry[i][geometry[i].length - 1]))
                        geometry[i][geometry[i].length - 1] = geometry[i][0];
                cutFeatures.push(polygon(geometry));
            });
        }

        if (cutFeatures.length > 0) {
            // Suppression des polygones "dupliqués"
            for (let i = 0; i < cutFeatures.length; i++) {
                const cf1 = cutFeatures[i];
                let index = -1;
                for (let j = 0; j < cutFeatures.length && index === -1; j++) {
                    const cf2 = cutFeatures[j];
                    if (cf1 !== cf2) {
                        const coords1 = cf1.geometry.coordinates.map(coords1 => {
                            return coords1.map(coords2 => {
                                return coords2.map(coord => (coord.toFixed(5)));
                            });
                        });
                        const coords2 = cf2.geometry.coordinates.map(coords1 => {
                            return coords1.map(coords2 => {
                                return coords2.map(coord => (coord.toFixed(5)));
                            });
                        });

                        if (JSON.stringify(coords1) === JSON.stringify(coords2)) index = j;
                    }
                }
                if (index !== -1) cutFeatures.splice(index, 1);
            }

            retVal = featureCollection(cutFeatures);
        }

        return retVal;
    }

    static mergePolygons(features) {
        let newBaseLine;
        if (!features[0].properties?.baseLine) { // En cas de fusion d'EV linéaires, ils doivent être parfaitement jointifs
            /* L'algorithme suivant permet de créer des points intermédiaires dans les polygones qui possède un côté adjacent au sommet d'un autre polygone */
            let overlapToCreate = [];
            features.forEach((firstFeature, index) => { // Pour chacun des polygones
                const firstCoordinates = firstFeature.geometry.coordinates[0];
                for (let i = 0; i < firstCoordinates.length - 1; i++)
                    features.forEach(secondFeature => { // On cherche dans chacun des autres polygones
                        if (secondFeature !== firstFeature) {
                            const secondCoordinates = secondFeature.geometry.coordinates[0];
                            for (let j = 0; j < secondCoordinates.length - 1; j++) {
                                let firstLineCoords = [
                                    [firstCoordinates[i][0], firstCoordinates[i][1]],
                                    [firstCoordinates[i + 1][0], firstCoordinates[i + 1][1]]
                                ];
                                const firstLine = lineString(JSON.parse(JSON.stringify(firstLineCoords)));
                                let secondLineCoords = [
                                    [secondCoordinates[j][0], secondCoordinates[j][1]],
                                    [secondCoordinates[j + 1][0], secondCoordinates[j + 1][1]]
                                ];
                                const secondLine = lineString(JSON.parse(JSON.stringify(secondLineCoords)));
                                const overlapFC = lineOverlap(firstLine, secondLine, { tolerance: 0.0001 });
                                let overlapCoords = overlapFC.features[0] ? JSON.parse(JSON.stringify(overlapFC.features[0].geometry.coordinates)) : null;
                                for (let i = 0; i < firstLineCoords.length; i++)  // On arrondit à 10 pour la détection des coordonnées similaires
                                    for (let j = 0; j < firstLineCoords[i].length; j++) {
                                        firstLineCoords[i][j] = Number(firstLineCoords[i][j].toFixed(10));
                                        secondLineCoords[i][j] = Number(secondLineCoords[i][j].toFixed(10));
                                        if (overlapCoords) overlapCoords[i][j] = Number(overlapCoords[i][j].toFixed(10));
                                    }
                                if (overlapFC.features.length > 0 // Si l'un d'entre eux a un côté adjacent à l'un des côté du polygone
                                    && JSON.stringify(firstLineCoords) !== JSON.stringify(secondLineCoords)  // Avec des coordonnées différentes
                                    && JSON.stringify(firstLineCoords) !== JSON.stringify(secondLineCoords.reverse())
                                    && JSON.stringify(firstLineCoords) !== JSON.stringify(overlapCoords) // Et un overlapping qui ne correspond pas au côté entier
                                    && JSON.stringify(firstLineCoords) !== JSON.stringify(overlapCoords.reverse())) {
                                    overlapToCreate.push({ featureIndex: index, coordinatesIndex: i, feature: overlapFC.features[0] });
                                }
                            }
                        }
                    });
            });

            // On regroupe les overlappings détectés en fonction du polygone concerné et de l'index de la coordonnée en question
            let overlapToCreateSorted = [];
            overlapToCreate.forEach(overlap => {
                const overlapIndex = overlapToCreateSorted.findIndex(o => o.featureIndex === overlap.featureIndex);
                if (overlapIndex === -1) {
                    overlapToCreateSorted.push({
                        featureIndex: overlap.featureIndex,
                        coordinatesToReplace: [{
                            coordinatesIndex: overlap.coordinatesIndex,
                            coordinates: [...overlap.feature.geometry.coordinates.map(coordinates => [
                                coordinates[0],
                                coordinates[1]
                            ])]
                        }]
                    });
                } else {
                    const coordinateIndex = overlapToCreateSorted[overlapIndex].coordinatesToReplace.findIndex(c => c.coordinatesIndex === overlap.coordinatesIndex);
                    if (coordinateIndex === -1)
                        overlapToCreateSorted[overlapIndex].coordinatesToReplace.push({
                            coordinatesIndex: overlap.coordinatesIndex,
                            coordinates: [...overlap.feature.geometry.coordinates.map(coordinates => [
                                coordinates[0],
                                coordinates[1]
                            ])]
                        });
                    else overlapToCreateSorted[overlapIndex].coordinatesToReplace[coordinateIndex].coordinates.push(
                        ...overlap.feature.geometry.coordinates.map(coordinates => [
                            coordinates[0],
                            coordinates[1]
                        ])
                    );
                }
            });

            // On supprime les doublons et les ordonne
            overlapToCreateSorted.forEach(overlap => {
                overlap.coordinatesToReplace = overlap.coordinatesToReplace.sort((a, b) => a.coordinateIndex < b.coordinateIndex ? 1 : -1);
                overlap.coordinatesToReplace.forEach(coordinateToReplace => {
                    // On push les points du côté sur lequel survient les overlaps
                    coordinateToReplace.coordinates.push(
                        features[overlap.featureIndex].geometry.coordinates[0][coordinateToReplace.coordinatesIndex],
                        features[overlap.featureIndex].geometry.coordinates[0][coordinateToReplace.coordinatesIndex + 1]
                    );
                    // Suppression des coordonnées doublons
                    let coordinates = [];
                    coordinateToReplace.coordinates.forEach(coordinate => {
                        const index = coordinates.findIndex(c => c[0].toFixed(10) === coordinate[0].toFixed(10) && c[1].toFixed(10) === coordinate[1].toFixed(10));
                        if (index === -1) coordinates.push(coordinate);
                        else if (FormattersUtil.countDecimals(coordinates[index][0]) < FormattersUtil.countDecimals(coordinate[0])
                            || FormattersUtil.countDecimals(coordinates[index][1]) < FormattersUtil.countDecimals(coordinate[1]))
                            coordinates[index] = coordinate;
                    });
                    coordinateToReplace.coordinates = coordinates;
                    // On met les points à créer dans l'ordre en fonction de la distance par rapport au point de référence
                    coordinateToReplace.coordinates = coordinateToReplace.coordinates.sort((a, b) => {
                        return length(lineString([a, features[overlap.featureIndex].geometry.coordinates[0][coordinateToReplace.coordinatesIndex]]))
                            > length(lineString([b, features[overlap.featureIndex].geometry.coordinates[0][coordinateToReplace.coordinatesIndex]])) ? 1 : -1
                    });

                    // On remplace le côté par des sous-segment
                    features[overlap.featureIndex].geometry.coordinates[0].splice(coordinateToReplace.coordinatesIndex, 2, ...coordinateToReplace.coordinates);
                });

                // On assure que le polygone se referme correctement
                for (let i = 0; i < features[overlap.featureIndex].geometry.coordinates.length; i++)
                    if (JSON.stringify(features[overlap.featureIndex].geometry.coordinates[i][0] !== features[overlap.featureIndex].geometry.coordinates[i][features[overlap.featureIndex].geometry.coordinates[i].length - 1]))
                        features[overlap.featureIndex].geometry.coordinates[i][features[overlap.featureIndex].geometry.coordinates[i].length - 1] = features[overlap.featureIndex].geometry.coordinates[i][0];
            });
        } else {
            newBaseLine = features[0].properties.baseLine;
            const newBaseLineCoords = newBaseLine.coordinates;
            let firstBLPoint = circle(point(newBaseLineCoords[0]), 0.0001);
            let lastBLPoint = circle(point(newBaseLineCoords.at(-1)), 0.0001);
            const usedFeatures = [features[0]];
            let appendBaseLine = false;
            do {
                appendBaseLine = false;
                const featuresToCheck = features.filter(feature => !usedFeatures.includes(feature));
                for (let i = 0; i < featuresToCheck.length; i++) {
                    const baseLineCoords = featuresToCheck[i].properties.baseLine.coordinates;
                    const firstPoint = circle(point(baseLineCoords[0]), 0.0001);
                    const lastPoint = circle(point(baseLineCoords.at(-1)), 0.0001);

                    let found = false;
                    if (booleanIntersects(firstBLPoint, firstPoint)) {
                        newBaseLineCoords.splice(0, 1, ...baseLineCoords.reverse().slice(0, baseLineCoords.length - 1));
                        firstBLPoint = lastPoint; found = true;
                    } else if (booleanIntersects(firstBLPoint, lastPoint)) {
                        newBaseLineCoords.splice(0, 1, ...baseLineCoords.slice(0, baseLineCoords.length - 1));
                        firstBLPoint = firstPoint; found = true;
                    } else if (booleanIntersects(lastBLPoint, firstPoint)) {
                        newBaseLineCoords.splice(newBaseLineCoords.length - 1, 1, ...baseLineCoords.slice(1));
                        lastBLPoint = lastPoint; found = true;
                    } else if (booleanIntersects(lastBLPoint, lastPoint)) {
                        newBaseLineCoords.splice(newBaseLineCoords.length - 1, 1, ...baseLineCoords.reverse().slice(1));
                        lastBLPoint = firstPoint; found = true;
                    }

                    if (found) {
                        usedFeatures.push(featuresToCheck[i]);
                        appendBaseLine = true;
                    }
                }
            } while (appendBaseLine);

            // Si toutes les lignes n'ont pas servies à la reconstitution de la baseLine, c'est que les features ne sont pas parfaitements jointives
            if (usedFeatures.length !== features.length) return null;
        }

        let mergedPolygon = features[0];
        for (let i = 1; i < features.length; i++)
            mergedPolygon = union(featureCollection([mergedPolygon, features[i]]));
        if (newBaseLine) mergedPolygon.properties = { baseLine: newBaseLine };

        return mergedPolygon?.geometry.type === 'Polygon' ? mergedPolygon : null;
    }

    static mergePolygonsSimilarPoints(polygons) {
        polygons = JSON.parse(JSON.stringify(polygons));
        polygons.forEach(polygon => {
            for (let i = 0; i < polygon[0].length - 1; i++) {
                if (distance(polygon[0][i], polygon[0][i + 1], { units: 'meters' }) <= 0.15) {
                    if (i !== 0) polygon[0].splice(i, 1);
                    else polygon[0].splice(i + 1, 1);
                    i--;
                }
            }
        });

        polygons.forEach(firstPolygon => {
            firstPolygon.forEach(firstCoordinates => { // Vérification des coordonnées similaires
                firstCoordinates.forEach(firstCoordinate => {
                    polygons.filter(polygon => polygon !== firstPolygon).forEach(secondPolygon => {
                        secondPolygon.forEach(secondCoordinates => {
                            secondCoordinates.forEach(secondCoordinate => {
                                if (distance(firstCoordinate, secondCoordinate, { units: 'meters' }) <= 0.15) {
                                    if (FormattersUtil.countDecimals(Number(firstCoordinate[0].toFixed(10))) > FormattersUtil.countDecimals(Number(secondCoordinate[0].toFixed(10))))
                                        secondCoordinate[0] = JSON.parse(JSON.stringify(firstCoordinate[0]));
                                    else firstCoordinate[0] = JSON.parse(JSON.stringify(secondCoordinate[0]));
                                    if (FormattersUtil.countDecimals(Number(firstCoordinate[1].toFixed(10))) > FormattersUtil.countDecimals(Number(secondCoordinate[1].toFixed(10))))
                                        secondCoordinate[1] = JSON.parse(JSON.stringify(firstCoordinate[1]));
                                    else firstCoordinate[1] = JSON.parse(JSON.stringify(secondCoordinate[1]));
                                }
                            });
                        });
                    });
                });
            });
        });

        return polygons;
    }

    static convertLineLatLngsToCoordinates(latLngs) {
        if (latLngs.length > 0) return latLngs.map(lineLatLngs => [lineLatLngs.lng, lineLatLngs.lat]);
        else return [];
    }

    static convertLineCoordinatesToLatLngs(coords) {
        if (coords.length > 0) return coords.map(lineCoords => ({ lat: lineCoords[1], lng: lineCoords[0] }));
        else return [];
    }

    static convertPolygonLatLngsToCoordinates(latLngs) {
        return latLngs.map((polygon, index) => {
            if (polygon.length > 0) {
                let result = polygon.map(polygonLatLngs => {
                    return [polygonLatLngs.lng, polygonLatLngs.lat]
                });
                if (JSON.stringify(result[0]) !== JSON.stringify(result[result.length - 1]))
                    result.push([polygon[0].lng, polygon[0].lat]);
                return index === 0 && booleanClockwise(lineString(result)) ? result.reverse() : result;
            } else return null;
        }).filter(polygon => polygon)
    }

    static convertPolygonCoordinatesToLatLngs(coordinates, isMultiPolygon = false) {
        const convertCoordinates = (coords) => {
            return coords.map(polygon => {
                if (JSON.stringify(polygon[0]) === JSON.stringify(polygon[polygon.length - 1])) polygon.pop();
                if (polygon.length > 0)
                    return polygon.map(polygonCoordinates => {
                        return { lat: polygonCoordinates[1], lng: polygonCoordinates[0] };
                    });
                else return null;
            }).filter(polygon => polygon)
        }
        let coords = JSON.parse(JSON.stringify(coordinates));
        return isMultiPolygon
            ? coords.map(polygon => convertCoordinates(polygon))
            : convertCoordinates(coords);
    }

    static convertPolylineLatLngsToCoordinates(latLngs) {
        return latLngs.map((polyline) => [polyline.lng, polyline.lat]);
    }

    static convertCircleToPolygon(layer, radius, steps) {
        const latLng = layer.getLatLng();
        const center = [latLng.lng, latLng.lat];
        const r = (!radius ? layer.getRadius() : radius) / 1000;
        const options = { steps: steps, units: 'kilometers' };
        const polygonFeature = circle(center, r, options);
        return L.geoJSON(polygonFeature).getLayers()[0];
    }

    static setRectangleDimensions(layer, length, width, angle) {
        const latLngs = this.convertPolygonLatLngsToCoordinates(layer.getLatLngs());
        if (latLngs[0].length >= 4 && latLngs[0].length <= 5) {
            // Largeurs
            let leftSideLine = [latLngs[0][0], latLngs[0][1]];
            let rightSideLine = [latLngs[0][2], latLngs[0][3]];
            const w = distance(point(leftSideLine[0]), point(leftSideLine[1]), { units: 'kilometers' }) * 1000;
            let diff0 = leftSideLine[1][0] - leftSideLine[0][0];
            let res0 = (diff0 / w) * width;
            leftSideLine[1][0] = leftSideLine[0][0] + res0;
            rightSideLine[0][0] = rightSideLine[1][0] + res0;
            let diff1 = leftSideLine[1][1] - leftSideLine[0][1];
            let res1 = (diff1 / w) * width;
            leftSideLine[1][1] = leftSideLine[0][1] + res1;
            rightSideLine[0][1] = rightSideLine[1][1] + res1;

            // Longueurs
            let topSideLine = [latLngs[0][1], latLngs[0][2]];
            let bottomSideLine = [latLngs[0][3], latLngs[0][0]];
            const l = distance(point(topSideLine[0]), point(topSideLine[1]), { units: 'kilometers' }) * 1000;
            diff0 = topSideLine[1][0] - topSideLine[0][0];
            res0 = (diff0 / l) * length;
            topSideLine[1][0] = topSideLine[0][0] + res0;
            bottomSideLine[0][0] = bottomSideLine[1][0] + res0;
            diff1 = topSideLine[1][1] - topSideLine[0][1];
            res1 = (diff1 / l) * length;
            topSideLine[1][1] = topSideLine[0][1] + res1;
            bottomSideLine[0][1] = bottomSideLine[1][1] + res1;

            layer.setLatLngs(this.convertPolygonCoordinatesToLatLngs(latLngs));

            // Angle
            const center = layer.getCenter();
            const pivot = [center.lng, center.lat];
            const rotatedPolygon = transformRotate(polygon(this.convertPolygonLatLngsToCoordinates(layer.getLatLngs())), Number(angle), { pivot: pivot });
            layer.setLatLngs(this.convertPolygonCoordinatesToLatLngs(rotatedPolygon.geometry.coordinates));
        }
    }

    static checkIfPolygonHasSelfIntersection(polygon) {
        let hasSelfIntersection = false;
        const latLngs = polygon.getLatLngs()[0];

        for (let i = 0; i < latLngs.length - 1 && !hasSelfIntersection; i++) {
            const line1 = lineString([[latLngs[i].lat, latLngs[i].lng], [latLngs[i + 1].lat, latLngs[i + 1].lng]]);
            for (let j = 0; j < latLngs.length - 1 && !hasSelfIntersection; j++) {
                const line2 = lineString([[latLngs[j].lat, latLngs[j].lng], [latLngs[j + 1].lat, latLngs[j + 1].lng]]);
                const intersection = lineIntersect(line1, line2);
                if (intersection.features.length > 1) hasSelfIntersection = true;
                else if (intersection.features.length === 1) {
                    const isPointOfLine1 = line1.geometry.coordinates.map(x => JSON.stringify(x)).includes(JSON.stringify(intersection.features[0].geometry.coordinates));
                    const isPointOfLine2 = line2.geometry.coordinates.map(x => JSON.stringify(x)).includes(JSON.stringify(intersection.features[0].geometry.coordinates));
                    hasSelfIntersection = (isPointOfLine1 && isPointOfLine2) ? false : true;
                }
            }
        }

        return hasSelfIntersection;
    }

    static checkIfIsInsidePolygon(latLngs, surroundings) {
        let isContained = true;

        const check = (latLng) => {
            let isInside = true; let samePoint = false;
            let x = latLng.lat, y = latLng.lng;
            for (let i = 0, j = surroundings.length - 1; i < surroundings.length; j = i++) {
                let xi = surroundings[i].lat, yi = surroundings[i].lng;
                let xj = surroundings[j].lat, yj = surroundings[j].lng;
                if (x === xj && y === yj) samePoint = true;
                let intersect = ((yi > y) !== (yj > y))
                    && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
                if (intersect) isInside = !isInside;
            }
            return samePoint || !isInside;
        }

        if (Array.isArray(latLngs[0]))
            latLngs[0].forEach(latLng => {
                if (!check(latLng)) isContained = false;
            });
        else if (!check(latLngs)) isContained = false;

        return isContained;
    }

    static convertBackgroundImageLatLngsToCoordinates(latLngs) {
        return latLngs.map(polygonLatLngs => {
            return [polygonLatLngs.lng, polygonLatLngs.lat]
        }).filter(polygon => polygon);
    }

    static convertBufferedLineToCoordinates(line, width) {
        const baseLineFeature = lineString(line);
        const positiveOffset = GeometriesUtil.getLineOffset(baseLineFeature, (width / 2));
        const negativeOffset = GeometriesUtil.getLineOffset(baseLineFeature, -(width / 2));
        const resultLine = lineString([...positiveOffset.geometry.coordinates, ...negativeOffset.geometry.coordinates.reverse()])
        resultLine.geometry.coordinates.push(JSON.parse(JSON.stringify(resultLine.geometry.coordinates[0])));
        const bufferedFeature = lineToPolygon(resultLine);
        return bufferedFeature.geometry.coordinates;
    }

    static getBufferedPolygon(polygonCoords, buffer, { units = 'meters' } = {}) {
        const offsetLines = [];
        for (let i = 0; i < polygonCoords[0].length - 1; i++) {
            const line = lineString(polygonCoords[0].slice(i, i + 2));
            const offsetLine = GeometriesUtil.getLineOffset(line, buffer);
            offsetLines.push(offsetLine);
        }

        const offsetCoords = [];
        for (let i = 0; i < offsetLines.length; i++) {
            let firstLine = offsetLines[i];
            let secondLine = offsetLines[i + 1] || offsetLines[0];
            let intersectionPoints = lineIntersect(firstLine, secondLine).features;
            for (let i = 0; intersectionPoints.length < 1 && i < 50; i++) {
                firstLine = transformScale(firstLine, 2);
                secondLine = transformScale(secondLine, 2);
                intersectionPoints = lineIntersect(firstLine, secondLine).features;
            }
            offsetCoords.push(intersectionPoints[0].geometry.coordinates);
            if (!offsetLines[i + 1]) offsetCoords.unshift(JSON.parse(JSON.stringify(intersectionPoints[0].geometry.coordinates)));
        }

        polygonCoords[0] = offsetCoords;

        let isInside = true;
        polygonCoords.slice(1).forEach(coords => {
            if (!booleanContains(polygon([polygonCoords[0]]), polygon([coords])))
                isInside = false;
        });

        return isInside && polygonCoords;
    }

    static getLineOffset(line, buffer, { units = 'meters' } = {}) {
        const lineCoords = line.geometry.coordinates;
        const transformAngle = buffer < 0 ? -90 : 90;
        if (buffer < 0) buffer = -buffer;

        const offsetLines = [];
        for (let i = 0; i < lineCoords.length - 1; i++) {
            const angle = bearing(lineCoords[i], lineCoords[i + 1]) + transformAngle;
            const firstPoint = transformTranslate(point(lineCoords[i]), buffer, angle, { units })?.geometry.coordinates;
            const secondPoint = transformTranslate(point(lineCoords[i + 1]), buffer, angle, { units })?.geometry.coordinates;
            offsetLines.push([firstPoint, secondPoint]);
        }

        const offsetCoords = [offsetLines[0][0]];
        for (let i = 0; i < offsetLines.length; i++) {
            if (offsetLines[i + 1]) {
                let firstLine = lineString(offsetLines[i]);
                let secondLine = lineString(offsetLines[i + 1]);
                let intersectionPoints = lineIntersect(firstLine, secondLine).features;
                for (let i = 0; intersectionPoints.length < 1 && i < 50; i++) {
                    firstLine = transformScale(firstLine, 2);
                    secondLine = transformScale(secondLine, 2);
                    intersectionPoints = lineIntersect(firstLine, secondLine).features;
                }
                offsetCoords.push(intersectionPoints[0].geometry.coordinates);
            } else offsetCoords.push(offsetLines[i][1]);
        }

        return lineString(offsetCoords);
    }

    static getPointsOnLine(latLngs, { startDistance = 0, maxDistance = 0, gap = 0, maxPoints = 0, firstPointOfLine = false } = {}) {
        const line = lineString(this.convertPolylineLatLngsToCoordinates(latLngs));
        const lineDistance = Math.floor(lineLength(line, { units: 'meters' }));
        let points = [], coords = [], distance = 0, shouldStop = false, i = 0;
        let nbMaxPoints = gap > 0 && gap <= lineDistance ? Math.floor(lineDistance / gap) : 0;
        nbMaxPoints = maxPoints > 0 ? maxPoints : nbMaxPoints;

        if (firstPointOfLine && nbMaxPoints > 0) {
            coords = line.geometry.coordinates[0];
            points.push([coords[1], coords[0]]);
            i++;
        }

        for (i; i < nbMaxPoints && !shouldStop; i++) {
            distance += gap;
            if (maxDistance > 0 && distance >= maxDistance) shouldStop = true;
            else {
                const newLine = lineSliceAlong(line, startDistance, distance, { units: 'meters' });
                if (newLine?.geometry.coordinates.length) {
                    coords = newLine.geometry.coordinates[newLine.geometry.coordinates.length - 1];
                    points.push([coords[1], coords[0]]);
                }
            }
        }

        return points;
    }

    static getElongatedLine = (lineCoords, distance, { units = 'meters' } = {}) => {
        const scaledLineCoords = JSON.parse(JSON.stringify(lineCoords));
        const translatedFirstPoint = transformTranslate(point(scaledLineCoords[0]), distance, bearing(scaledLineCoords[1], scaledLineCoords[0]), { units });
        const translatedSecondPoint = transformTranslate(point(scaledLineCoords.at(-1)), distance, bearing(scaledLineCoords.at(-2), scaledLineCoords.at(-1)), { units });
        scaledLineCoords.splice(0, 1, translatedFirstPoint?.geometry.coordinates);
        scaledLineCoords.splice(-1, 1, translatedSecondPoint?.geometry.coordinates);
        return scaledLineCoords;
    }

    static getAngleDelimitations = (lineCoords, buffer) => {
        const lineFeature = lineString(lineCoords); // lineString de 3 points formant un angle
        const firstAngle = bearing(lineCoords[0], lineCoords[1]);
        const secondAngle = bearing(lineCoords[1], lineCoords[2]);
        if (firstAngle.toFixed(1) === secondAngle.toFixed(1))
            return [
                transformTranslate(point(lineCoords[1]), 1, firstAngle + 180, { units: 'meters' })?.geometry.coordinates,
                transformTranslate(point(lineCoords[1]), 1, firstAngle, { units: 'meters' })?.geometry.coordinates
            ];
        // On calcule les deux offsets (lignes espacées de la valeur du buffer) & on garde le plus petit
        const positiveOffset = GeometriesUtil.getLineOffset(lineFeature, buffer);
        const negativeOffset = GeometriesUtil.getLineOffset(lineFeature, -buffer);
        const offset = lineLength(positiveOffset) > lineLength(negativeOffset) ? negativeOffset : positiveOffset;
        // On calcule le point le plus proche de l'angle de l'offset sur chaque trait de la ligne
        const anglePoint = offset.geometry.coordinates[1];
        const firstPointOnLine = nearestPointOnLine(lineString(lineCoords.slice(0, 2)), anglePoint)?.geometry.coordinates;
        const secondPointOnLine = nearestPointOnLine(lineString(lineCoords.slice(1, 3)), anglePoint)?.geometry.coordinates;
        return [firstPointOnLine, secondPointOnLine];
    }

    static invertPolygon(polygonFeature) {
        try {
            return difference(featureCollection([
                polygon([[[-200, -90], [200, -90], [200, 90], [-200, 90], [-200, -90]]]),
                polygonFeature
            ]));
        }
        catch {
            return null;
        }
    }

    static projectPoint(latLng, projectionSource) {
        const source = new proj4.Proj(projectionSource.proj4);
        const dest = new proj4.Proj('+proj=longlat +datum=WGS84 +no_defs');
        return proj4(source, dest, latLng);
    }

    static projectPolygon(coordinates, projectionSource) {
        return coordinates.map(subCoordinates => subCoordinates.map(latLng => this.projectPoint(latLng, projectionSource)));
    }

    static getPolygonSurface(latLngs) {
        let surface = L.GeometryUtil.geodesicArea(latLngs[0]);
        for (let i = 1; i < latLngs.length; i++) { // On décompte les zones de vide
            let emptySurface = L.GeometryUtil.geodesicArea(latLngs[i]);
            surface -= emptySurface;
        }
        return Math.round(surface * 100) / 100;;
    }

    static isLatLngWGS84(latLng) {
        return latLng[0] >= -180 && latLng[0] <= 180 && latLng[1] >= -90 && latLng[1] <= 90 ? true : false;
    }

    static areCoordinatesWGS84(coordinates) {
        for (const subCoordinates of coordinates)
            for (const latLng of subCoordinates)
                if (!this.isLatLngWGS84(latLng))
                    return false;
        return true;
    }

    static removeVertexValidation = (e) => {
        if (e.layer.linkedPolygon && e.layer.getLatLngs().length < 3) return false; // Pour la drawLine

        const removedLatLng = e.marker.getLatLng();
        const pointCircle = circle(point([removedLatLng.lng, removedLatLng.lat]), 0.0001); // Laisse une marge d'erreur en créant un polygone autour du nouveau point

        const checkLatLngs = (layer) => {
            const latLngs = JSON.parse(JSON.stringify(layer.getLatLngs()));
            for (let i = 0; i < latLngs.length; i++)
                for (let j = 0; j < latLngs[i].length; j++)
                    if (booleanIntersects(point([latLngs[i][j].lng, latLngs[i][j].lat]), pointCircle)) // Permet de rechercher les sommets similaires au sommet à supprimer
                        if (latLngs[i].length < 4) { // On vérifie qu'il reste au moins 4 sommets aux layers dont on va supprimer un sommet
                            e.event.originalEvent.stopPropagation();
                            showToast('vertex_deletion_not_allowed_too_few_vertexes');
                            return false;
                        }
            return true;
        };

        if (!checkLatLngs(e.layer)) return false;

        return true;
    }

    static getScaledBbox = (surroundingsBbox, mapBounds) => {
        const bbox = JSON.parse(JSON.stringify(surroundingsBbox));
        if (mapBounds) {
            const xDiff = (mapBounds.getSouthEast().lng - mapBounds.getNorthWest().lng) * 0.95;
            const yDiff = (mapBounds.getNorthWest().lat - mapBounds.getSouthEast().lat) * 0.95;
            bbox[0] -= xDiff;
            bbox[1] -= yDiff;
            bbox[2] += xDiff;
            bbox[3] += yDiff;
        }

        return bboxPolygon(bbox).geometry.coordinates;
    }
}