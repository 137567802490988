import React, { Component } from 'react';
// Composants
import { Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Librairies
import { connect } from 'react-redux';
import i18n from '../../locales/i18n';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
// Utils
import FormattersUtil from '../../utils/FormattersUtil';

class LoginAsBand extends Component {
    render() {
        const { userInfos, loginAsData } = this.props;
        const userName = FormattersUtil.formatLastNameAndFirstName(userInfos?.lastName, userInfos?.firstName);

        return (
            <>
                {loginAsData && userInfos &&
                    <div style={{ position: 'relative', width: '100%', display: 'flex', backgroundColor: 'var(--red-80)', alignItems: 'center', padding: '5px 0' }}>
                        <Button color='red' size='tiny' onClick={this.loginAsPreviousAccount} style={{ padding: '6px 8px', marginLeft: '5px' }}>
                            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />
                            {i18n.t("Revenir à votre compte")}
                        </Button>
                        <h4 style={{ position: 'absolute', left: '50%', margin: 0, transform: 'translate(-50%, 0)' }}>
                            {loginAsData.readOnly ?
                                i18n.t("Connecté en tant que {{userName}} (lecture seule)", { userName })
                                : i18n.t("Connecté en tant que {{userName}}", { userName })}
                        </h4>
                    </div>}
            </>
        );
    }

    loginAsPreviousAccount = () => {
        const loginAsData = JSON.parse(localStorage.getItem('loginAsData'));
        if (loginAsData) {
            this.props.logUser();
            this.props.logUser(loginAsData.token);
        }
    }
}

const mapStateToProps = (state) => {
    return {
        userInfos: state.userInfos,
        loginAsData: state.loginAsData
    };
};

export default connect(mapStateToProps)(LoginAsBand);