import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faFilterList, faFloppyDisk, faFlowerTulip, faLasso, faMagnifyingGlass, faTablePicnic, faTimes, faXmarkLarge } from '@fortawesome/pro-solid-svg-icons';
import MapPreview from '../Utils/MapPreview';
import FormMemberList from '../Lists/FormMemberList';
/*     Editors     */
import TextEditor from './Editors/TextEditor';
import SmartTextEditor from './Editors/SmartTextEditor';
import NumberEditor from './Editors/NumberEditor';
import BooleanEditor from './Editors/BooleanEditor';
import DropDownEditor from './Editors/DropDownEditor';
import EssenceEditor from './Editors/EssenceEditor';
/*     Filters     */
import TextFilter from './Filters/TextFilter';
import NumberFilter from './Filters/NumberFilter';
import BooleanFilter from './Filters/BooleanFilter';
import DropDownFilter from './Filters/DropDownFilter';
import EssenceFilter from './Filters/EssenceFilter';
import DropDownNumberFilter from './Filters/DropDownNumberFilter';
// Librairies
import DataGrid, { Row as GridRow, SortableHeaderCell } from 'react-data-grid';
import { v4 as uuidv4 } from 'uuid';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import i18n from '../../locales/i18n';
// Redux
import { connect } from 'react-redux';
import { setTableState } from '../../actionCreators/componentsActions';
import { setProject, setProjects } from '../../actionCreators/projectsActions';
// Semantic UI
import { Form, Button, Menu, Input, Label, Grid, Message, Dimmer, Transition, Loader } from 'semantic-ui-react';
// Services
import GreenSpacesService from '../../services/GreenSpacesService';
import FilesService from '../../services/FilesService';
import ProjectsService from '../../services/ProjectsService';
import StationsService from '../../services/StationsService';
// Styles
import '../../styles/react-contextmenu.css';
import '../../styles/rdg.css';
// Utils
import { showToast } from '../../utils/ToastsUtil';
import UpdatesUtil from '../../utils/UpdatesUtil';
import FormattersUtil from '../../utils/FormattersUtil';
import ProjectsUtil from '../../utils/ProjectsUtil';
import StylesUtil from '../../utils/StylesUtil';
import WebSocketUtil from '../../utils/WebSocketUtil';
import FormulasUtil from '../../utils/FormulasUtil';
import DatesUtil from '../../utils/DatesUtil';
import RightsUtil from '../../utils/RightsUtil';
import FieldsUtil from '../../utils/FieldsUtil';

const initialFilters = {
    global: '',
    projectReference: '',
    customReference: '',
    place: '',
    placeExtra: '',
    tags: '',
    isTreeBase: '',
    spaceFunction: '',
    spaceType: '',
    dominantComposition: '',
    detailedComposition: '',
    managementClass: '',
    annualMaintenanceFrequency: '',
    observation: '',
    nbTrees: '',
    density: '',
    distanceBetweenTrunks: '',
    vernacularName: '',
    gender: '',
    species: '',
    cultivar: '',
    averageHealthReview: '',
    averageHeight: '',
    averageCircumference: '',
    averageCrownDiameter: '',
    surface: '',
    length: '',
    carbonStock: '',
    totalCarbonStock: '',
    coolingEnergyIndicator: '',
    coolingEconomicValue: '',
    oxygenProduction: '',
    ecopotentialIndex: '',
    creationDate: '',
    creatorName: '',
    modificationDate: '',
    editorName: '',
    projectLabel: ''
};

class GreenSpaceTable extends Component {
    state = {
        data: {
            columns: [],
            rows: []
        },
        greenSpacesToModify: [],
        greenSpacesToDelete: [],
        modificationsHistory: [],
        modificationsHistoryIndex: 0,
        rowIndex: 0,
        sortColumn: null,
        sortDirection: 'NONE',
        enableFilterRow: false,
        filters: JSON.parse(JSON.stringify(initialFilters)),
        tableToShow: null,
        spaceFunctions: [],
        spaceTypes: [],
        dominantCompositions: [],
        managementClasses: [],
        tags: [],
        greenSpacesStations: null,
        showExports: false,
        featureToShow: null
    };

    render() {
        const { rights, selectedElements, mainFilters } = this.props;
        const {
            data, greenSpacesToModify, greenSpacesToDelete, modificationsHistory, modificationsHistoryIndex, featureToShow,
            sortColumn, sortDirection, enableFilterRow, filters, rowIndex, selectedRow, selectedColumn, tableToShow, showExports
        } = this.state;
        const mainPF = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators).main;
        const rows = this.getFilteredRows();
        const id = `green-spaces-table-${this.props.project.id}`;

        return (
            <div className={'scrollable-container'}>
                {(this.props.closingTable || tableToShow) &&
                    <Dimmer active style={StylesUtil.getMapStyles().dimmerStyle}>
                        <Grid style={{ height: '100%' }}>
                            <Grid.Row style={{ height: '100%' }} verticalAlign='middle'>
                                <Grid.Column textAlign='center'>
                                    <Message compact className='tableConfirmation'>
                                        <Message.Header>{i18n.t("Des modifications n'ont pas été sauvegardées, que faire ?")}</Message.Header>
                                        <Message.Content style={{ marginTop: '10px' }}>
                                            <Button color='green' onClick={() => {
                                                if (!tableToShow) this.props.cancelTableClose();
                                                this.handleSubmit(true);
                                            }}>
                                                <FontAwesomeIcon icon={faFloppyDisk} style={{ marginRight: '10px' }} />{i18n.t("Sauvegarder")}
                                            </Button>
                                            <Button color='red' onClick={() => {
                                                tableToShow ? this.props.changeModalContentType(tableToShow, i18n.t("Tableau de données"), true) : this.props.hideForm(true);
                                            }}>
                                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Ne pas sauvegarder")}
                                            </Button>
                                            <Button color='grey' onClick={() => {
                                                tableToShow ? this.setState({ tableToShow: null }) : this.props.cancelTableClose();
                                            }}>
                                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                            </Button>
                                        </Message.Content>
                                    </Message>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Dimmer>}
                {data && data.columns &&
                    <>
                        <Menu attached='top' tabular style={{ marginTop: 0, flexWrap: 'wrap' }}>
                            <Menu.Item>
                                <Form.Field
                                    control={Input} type='number' step='1' placeholder={i18n.t("Numéro de ligne")}
                                    value={rowIndex || ''}
                                    onChange={(e, { value }) => this.setState({ rowIndex: value })}
                                />
                                <Button
                                    className='button--secondary' icon='arrow down' style={{ marginLeft: '10px' }}
                                    onClick={() => { if (this.gridRef.current) this.gridRef.current.scrollToRow(rowIndex - 1) }}
                                />
                            </Menu.Item>
                            <Menu.Item>
                                <Button.Group>
                                    {mainPF.trees &&
                                        <Button
                                            title={i18n.t("Voir les arbres")} className='button--secondary' icon='tree'
                                            onClick={() => {
                                                if (this.props.elementsHaveBeenModified) this.setState({ tableToShow: 'TreeTable' });
                                                else this.props.changeModalContentType('TreeTable', i18n.t("Tableau de données"), true);
                                            }}
                                        />}
                                    <Button className='button--secondary' disabled style={{ padding: '11px' }}>
                                        <FontAwesomeIcon icon={faFlowerTulip} style={{ height: '12px' }} />
                                    </Button>
                                    {mainPF.furnitures &&
                                        <Button
                                            title={i18n.t("Voir le mobilier urbain")} className='button--secondary' style={{ padding: '11px' }}
                                            onClick={() => {
                                                if (this.props.elementsHaveBeenModified) this.setState({ tableToShow: 'FurnitureTable' });
                                                else this.props.changeModalContentType('FurnitureTable', i18n.t("Tableau de données"), true);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTablePicnic} style={{ height: '12px' }} />
                                        </Button>}
                                </Button.Group>
                            </Menu.Item>
                            <Menu.Item>
                                <Button.Group>
                                    <Button
                                        title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                                        className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                                        onClick={this.toggleFilters}
                                    />
                                    <Button
                                        title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                                        onClick={this.clearFilters} disabled={!this.areFiltersApplied()}
                                    />
                                    <Button
                                        title={i18n.t("Visualiser les données")} className='button--secondary' icon='eye'
                                        onClick={this.showFilteredElements}
                                    />
                                    {RightsUtil.canExport(rights?.greenSpaces) && <>
                                        <Button
                                            title={i18n.t("Exporter les données")} className='button--secondary' icon='download' style={{ position: 'relative' }}
                                            disabled={!this.props.isOnline} onClick={() => this.setState(prevState => ({ showExports: !prevState.showExports }))}
                                        />
                                        {showExports &&
                                            <Button.Group style={{ position: 'absolute', top: '85%', left: '175px', zIndex: 1000 }}>
                                                <Button title='Excel' className='button--secondary' icon='file excel' disabled={!this.props.isOnline} onClick={this.exportXLSX} />
                                                <Button title='Shapefiles' className='button--secondary' icon='file' disabled={!this.props.isOnline} onClick={this.exportSHP} />
                                                <Button title='Fiches PDF' className='button--secondary' icon='file pdf' disabled={!this.props.isOnline} onClick={this.exportPDF} />
                                                <Button title='Photos' className='button--secondary' icon='picture' disabled={!this.props.isOnline} onClick={this.exportPhotos} />
                                            </Button.Group>}
                                    </>}
                                    {RightsUtil.canWrite(rights?.greenSpaces) &&
                                        <>
                                            <Button
                                                title={i18n.t("Annuler la dernière modification")} className='button--secondary' icon='undo'
                                                onClick={this.restorePreviousModification} disabled={modificationsHistoryIndex < 1}
                                            />
                                            <Button
                                                title={i18n.t("Rétablir la modification suivante")} className='button--secondary' icon='redo'
                                                disabled={modificationsHistoryIndex === modificationsHistory.length}
                                                onClick={this.restoreNextModification}
                                            />
                                            <Button
                                                title={i18n.t("Valider les modifications")} className='button--secondary' icon='check'
                                                onClick={() => this.handleSubmit(false)} disabled={greenSpacesToModify.length < 1 && greenSpacesToDelete.length < 1}
                                            />
                                        </>}
                                </Button.Group>
                            </Menu.Item>
                            <Menu.Item position='right' style={{ paddingBottom: 0, paddingTop: 26 }}>
                                <div style={{ position: 'absolute', right: '20px', top: '3px' }}>
                                    <FormMemberList
                                        id={id} stateToSend={{ rows: this.state.data.rows, greenSpacesToModify: this.state.greenSpacesToModify }}
                                        setIsLoading={(isLoading) => this.setState({ isLoading })} updateForm={this.updateForm}
                                    />
                                </div>
                                {greenSpacesToDelete.length > 0 &&
                                    <>
                                        <Label color='red' content={`${i18n.t("Éléments supprimés")} : ` + greenSpacesToDelete.length} />
                                        <span style={{ marginLeft: '10px' }}>|</span>
                                    </>}
                                <Label color='grey' content={i18n.t("Les cases foncées ne sont pas éditables (référence automatique, surface, ...)")} />
                            </Menu.Item>
                            <Menu.Item style={{ width: '100%', padding: '1px 0', border: 'none', height: '32px' }} className='full-width-input-item element-input'>
                                {(selectedColumn?.editable === true || (typeof selectedColumn?.editable === 'function' && selectedColumn.editable(selectedRow))) ?
                                    selectedColumn.editor({
                                        row: selectedRow, column: selectedColumn,
                                        onRowChange: this.handleRowChange,
                                        onClose: (commitChanges) => {
                                            if (commitChanges) {
                                                this.handleRowChange(selectedRow);
                                                WebSocketUtil.updateForm(this.props.webSocketHubs, 'GreenSpaceTable' + this.props.project.id, { rows: [selectedRow], greenSpacesToModify: this.state.greenSpacesToModify });
                                            }
                                        }
                                    })
                                    : <Input disabled placeholder={i18n.t("Sélectionnez une cellule éditable")} />}
                                <Input placeholder={i18n.t("Rechercher...")} onChange={(_, { value }) => this.setState(prevState => ({ filters: { ...prevState.filters, global: value } }))} />
                                <div style={{ borderLeft: 'solid 1px var(--grey-100)', paddingLeft: '10px', position: 'absolute', right: 0, paddingRight: '10px', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <div style={{ borderRight: 'solid 1px var(--grey-100)', paddingRight: '6px', marginRight: '5px' }}>
                                        {mainFilters &&
                                            <FontAwesomeIcon
                                                icon={faFilterList} title={i18n.t("Outil filtres")}
                                                style={{ borderRadius: '5px', padding: '3px', height: '13px', width: '13px', backgroundColor: 'var(--grey-100)', marginRight: '3px' }}
                                            />}
                                        {selectedElements?.length > 0 &&
                                            <FontAwesomeIcon
                                                icon={faLasso} title={i18n.t("Sélection d'éléments")}
                                                style={{ borderRadius: '5px', padding: '3px', height: '13px', width: '13px', backgroundColor: 'var(--grey-100)', marginRight: '3px' }}
                                            />}
                                        {filters.global?.trim().length > 0 &&
                                            <FontAwesomeIcon
                                                icon={faMagnifyingGlass} title={i18n.t("Recherche globable")}
                                                style={{ borderRadius: '5px', padding: '3px', height: '13px', width: '13px', backgroundColor: 'var(--grey-100)', marginRight: '3px' }}
                                            />}
                                        {enableFilterRow &&
                                            <FontAwesomeIcon
                                                icon={faFilter} title={i18n.t("Filtres du tableau")}
                                                style={{ borderRadius: '5px', padding: '3px', height: '13px', width: '13px', backgroundColor: 'var(--grey-100)', marginRight: '3px' }}
                                            />}
                                    </div>
                                    <span style={{ fontWeight: 'bold' }}>{rows?.length}</span>
                                    {rows?.length !== data?.rows?.length && <span style={{ marginLeft: '4px' }}>{` / ${data?.rows?.length}`}</span>}
                                </div>
                            </Menu.Item>
                        </Menu>
                        <div className='scrollable reset-height'>
                            <DataGrid
                                ref={this.gridRef} className={this.props.isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                                columns={data.columns.filter(column => column.visible)}
                                rows={rows} rowRenderer={this.rowRenderer}
                                defaultColumnOptions={{ sortable: true, resizable: true }}
                                cellNavigationMode='LOOP_OVER_ROW'
                                sortColumn={sortColumn} sortDirection={sortDirection}
                                onSort={this.handleSort} onFill={this.handleFill} enableFilterRow={enableFilterRow}
                                filters={filters} onFiltersChange={filters => this.setState({ filters: filters })}
                                emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}><span>{i18n.t("Aucun résultat trouvé")}</span></div>)}
                                onSelectedCellChange={({ idx, rowIdx }) => this.setState({ selectedRow: rows[rowIdx], selectedColumn: data.columns[idx] })}
                                onRowsChange={(newRows, _) => {
                                    let rowsToSend = [];
                                    this.state.data.rows.forEach(row => {
                                        const index = newRows.findIndex(newRow => newRow.elementId === row.elementId);
                                        if (index !== -1 && JSON.stringify(row) !== JSON.stringify(newRows[index]))
                                            rowsToSend.push(newRows[index]);
                                    });

                                    this.handleRowsChange(newRows);
                                    if (rowsToSend.length) WebSocketUtil.updateForm(this.props.webSocketHubs, 'GreenSpaceTable' + this.props.project.id, { rows: rowsToSend, greenSpacesToModify: this.state.greenSpacesToModify });
                                }}
                            />
                        </div>
                        <ContextMenu id='grid-context-menu'>
                            <MenuItem onClick={this.showRowElement}>{i18n.t("Voir (carte)")}</MenuItem>
                            <MenuItem onClick={this.showRowElementOnMinimap}>{i18n.t("Voir (aperçu)")}</MenuItem>
                            {RightsUtil.canWrite(rights?.greenSpaces) && <MenuItem onClick={this.resetRow}>{i18n.t("Réinitialiser")}</MenuItem>}
                            {RightsUtil.canWrite(rights?.greenSpaces) && <MenuItem onClick={this.deleteRow}>{i18n.t("Supprimer")}</MenuItem>}
                        </ContextMenu>
                        <Transition.Group animation='scale' duration='1000'>
                            {featureToShow &&
                                <div style={{ height: '200px', width: '300px', position: 'absolute', bottom: '15px', right: '15px' }}>
                                    <MapPreview
                                        id={featureToShow.id} style={{ borderRadius: '10px', height: '100%', width: '100%' }} dragging={true} zooming={true}
                                        features={[featureToShow]} elementStyle={{ greenSpace: StylesUtil.getGreenSpaceStyle() }}
                                    />
                                    <FontAwesomeIcon icon={faXmarkLarge} size='1x' style={{ position: 'absolute', top: '7px', right: '7px', zIndex: 2, cursor: 'pointer' }} onClick={() => this.setState({ featureToShow: null })} />
                                </div>}
                        </Transition.Group>
                    </>}
            </div>
        );
    }

    componentDidMount = () => {
        this.gridRef = React.createRef();
        if (this.props.tableState) {
            const { greenSpaces, data: oldData, timestamp, ...state } = this.props.tableState; // On copie le state sauvegardé mais sans la variable 'greenSpaces'
            this.greenSpaces = greenSpaces;
            const data = this.loadData(state.tags, oldData.rows);

            const modifiedGreenSpaces = this.greenSpaces.filter(layer => layer.feature.properties.modificationDate && layer.feature.properties.modificationDate > timestamp);
            modifiedGreenSpaces.forEach(layer => {
                const index = data.rows.findIndex(row => row.elementId === layer.feature.id);
                if (index !== -1) data.rows[index] = this.getRowValue(layer.feature, data.rows[index].id, state.tags);
            });
            if (modifiedGreenSpaces.length > 0) {
                state.greenSpacesToModify = state.greenSpacesToModify.filter(feature => !modifiedGreenSpaces.find(layer => layer.feature.id === feature.id));
                data.rows = [...data.rows];
            }

            this.setState({ data, ...state });
            this.props.setTableState(null);
        } else {
            if (this.props.nbStations) this.loadGreenSpacesStations();
            let projectTags = [];
            if (this.props.project?.tags)
                projectTags = this.props.project.tags
                    .filter(x => x.category === 'Espace vert')
                    .map(projectTag => { return { label: projectTag.label, id: projectTag.id, category: projectTag.category } });

            const data = this.loadData(projectTags);

            this.setState({
                data: data,
                spaceFunctions: this.props.spaceFunctions.map(x => { return { label: x.label, id: x.id } }),
                spaceTypes: this.props.spaceTypes.map(x => { return { label: x.label, id: x.id } }),
                dominantCompositions: this.props.dominantCompositions.map(x => { return { label: x.label, id: x.id } }),
                managementClasses: this.props.managementClasses.map(x => { return { label: x.value, id: x.id } }),
                healthReviews: this.props.healthReviews.map(x => { return { label: x.value, id: x.id } }),
                vernacularNames: [...new Set(this.props.essences
                    .map(x => x.vernacularName))]
                    .filter(x => x)
                    .sort((a, b) => { // On trie par ordre alphabétique
                        const textA = a.toUpperCase();
                        const textB = b.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    }),
                genders: [...new Set(this.props.essences
                    .map(x => x.gender))]
                    .sort((a, b) => { // On trie par ordre alphabétique
                        const textA = a.toUpperCase();
                        const textB = b.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    }),
                species: [...new Set(this.props.essences
                    .filter(x => x.species)
                    .map(x => x.species))]
                    .sort((a, b) => { // On trie par ordre alphabétique
                        const textA = a.toUpperCase();
                        const textB = b.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    }),
                cultivars: [...new Set(this.props.essences
                    .filter(x => x.cultivar)
                    .map(x => x.cultivar))]
                    .sort((a, b) => { // On trie par ordre alphabétique
                        const textA = a.toUpperCase();
                        const textB = b.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    }),
                tags: projectTags
            });
        }
        document.addEventListener('keydown', this.handleKeyDown);
        document.addEventListener('click', ({ target }) => {
            const isExportButton = target.classList?.contains('download') || target.firstChild?.classList?.contains('download');
            if (!isExportButton && this.state.showExports) this.setState({ showExports: false });
        });

        if (this.props.webSocketHubs.elementsHub) {
            this.props.webSocketHubs.elementsHub.on('SendElements', (elements) => {
                let areColumnsInitialized = false;
                while (!areColumnsInitialized)
                    if (this.state.data.columns) {
                        const elementsParsed = JSON.parse(elements);
                        const type = elementsParsed[0].properties.category;
                        if (type !== 'Espace vert') return;
                        setTimeout(() => {
                            let rows = JSON.parse(JSON.stringify(this.state.data.rows));
                            const layers = this.props.greenSpacesLayer.getLayers().filter(layer => elementsParsed.find(tp => tp.id === layer.feature.id));
                            if (layers.length) {
                                this.greenSpaces = [...this.greenSpaces || [], ...layers];
                                for (const key in layers)
                                    if (layers.hasOwnProperty(key))
                                        rows.push(this.getRowValue(layers[key].feature, key, this.state.tags));
                                this.setState(prevState => ({ data: { ...prevState.data, rows } }));
                            }
                        }, 500);
                        areColumnsInitialized = true;
                    }
            });

            this.props.webSocketHubs.elementsHub.on('UpdateElements', (elements) => {
                const elementsParsed = JSON.parse(elements);
                const type = elementsParsed[0].properties.category;
                if (type !== 'Espace vert') return;
                let rows = JSON.parse(JSON.stringify(this.state.data.rows));
                let greenSpacesToModify = JSON.parse(JSON.stringify(this.state.greenSpacesToModify));
                const layers = this.props.greenSpacesLayer.getLayers().filter(layer => elementsParsed.find(tp => tp.id === layer.feature.id));
                for (const key in layers)
                    if (layers.hasOwnProperty(key)) {
                        // Mise à jour de la row
                        let index = rows.findIndex(r => r.elementId === layers[key].feature.id);
                        if (index !== -1)
                            rows[index] = { ...rows[index], lat: layers[key].feature.geometry.coordinates[1], long: layers[key].feature.geometry.coordinates[0] };
                        // Mise à jour des modifications
                        index = greenSpacesToModify.findIndex(ttm => ttm.id === layers[key].feature.id);
                        if (index !== -1) greenSpacesToModify[index].geometry = layers[key].feature.geometry;
                    }
                this.setState(prevState => ({ data: { ...prevState.data, rows }, greenSpacesToModify }));
            });

            this.props.webSocketHubs.elementsHub.on('RemoveElements', (type, ids) => {
                if (type !== 'Espace vert') return;
                let rows = JSON.parse(JSON.stringify(this.state.data.rows));
                let greenSpacesToModify = JSON.parse(JSON.stringify(this.state.greenSpacesToModify));
                let modificationsHistory = JSON.parse(JSON.stringify(this.state.modificationsHistory));
                const idsParsed = JSON.parse(ids);
                this.greenSpaces = this.greenSpaces.filter(t => !idsParsed.includes(t.feature.id));
                rows = rows.filter(r => !idsParsed.includes(r.elementId));
                greenSpacesToModify = greenSpacesToModify.filter(ttm => !idsParsed.includes(ttm.id));
                for (let i = 0; i < modificationsHistory.length; i++)
                    modificationsHistory[i] = modificationsHistory[i].filter(m => !idsParsed.includes(m.elementId))
                modificationsHistory = modificationsHistory.filter(mh => mh.length);
                const modificationsHistoryIndex = modificationsHistory.length;
                this.setState(prevState => ({ data: { ...prevState.data, rows }, greenSpacesToModify, modificationsHistory, modificationsHistoryIndex }));
            });
        }
    }

    componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);
    rowRenderer = (props) => {
        return (
            <ContextMenuTrigger id='grid-context-menu' collect={() => ({ rowIdx: props.rowIdx })}>
                <GridRow {...props} />
            </ContextMenuTrigger>
        );
    }

    updateSelectedRow = (row) => this.setState({ selectedRow: row });

    handleRowChange = (row) => {
        const { data, selectedRow } = this.state;
        if (selectedRow) {
            const updatedRows = [...data.rows];
            const index = updatedRows.findIndex(row => row.id === selectedRow.id);
            updatedRows[index] = row;
            this.handleRowsChange(updatedRows);
        }
    };

    handleRowsChange = (newRows) => {
        this.setState(prevState => {
            const rows = prevState.data.rows;
            newRows.forEach(newRow => {
                const index = rows.findIndex(row => row.elementId === newRow.elementId);
                rows[index] = newRow;
            });
            return { data: { columns: prevState.data.columns, rows: rows } };
        });
    }

    getHeaderRenderer = ({ column, onSort, sortColumn, sortDirection }, color) => (
        <div className={color ? 'headerCellOverride' : null} ref={(node) => { if (color) node?.style?.setProperty('background-color', color, 'important'); }}>
            <SortableHeaderCell
                column={column}
                onSort={onSort}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
            >
                {column.name}
            </SortableHeaderCell>
        </div>
    );

    getEditor = (type, row, column, onRowChange, onClose, propertyOptions = null) => {
        const props = {
            elements: this.greenSpaces, elementsToModify: this.state.greenSpacesToModify, propertyOptions: propertyOptions,
            row: row, column: column, onRowChange: onRowChange, onClose: onClose, updateSelectedRow: this.updateSelectedRow,
            pushToModificationsHistory: this.pushToModificationsHistory, changeElementsToModify: this.changeGreenSpacesToModify,
            updateElementCustomFields: this.updateElementCustomFields
        };
        switch (type) {
            case 'dropdown': return <DropDownEditor {...props} />;
            case 'essence': return <EssenceEditor {...props} propertyName='dominantEssenceId' />;
            case 'smart': return <SmartTextEditor {...props} />;
            case 'number': return <NumberEditor {...props} />;
            case 'url': case 'text': return <TextEditor {...props} />;
            case 'boolean': return <BooleanEditor {...props} />;
            default: return;
        }
    }

    loadData = (projectTags, rows) => {
        let data = {
            columns: [],
            rows: rows || []
        };

        if (this.props.greenSpacesLayer) {
            if (!rows) {
                this.greenSpaces = [];
                this.props.greenSpacesLayer.getLayers().forEach(layer => {
                    this.greenSpaces.push(layer);
                });
            }
            const requiredFields = ProjectsUtil.getProjectRequiredFields(this.props.project).greenSpaces;
            const publicFields = ProjectsUtil.getProjectPublicFields(this.props.project, this.props.projectCollaborators);
            const mainPF = publicFields.main;
            const greenSpacesPF = publicFields.greenSpaces;
            const subscription = this.props.project?.organization.subscription;
            const activeOrganization = this.props.activeOrganization;

            const availableManagementClasses = (dominantComposition) => this.state.managementClasses.filter(managementClass => (
                this.props.dominantCompositions
                    .find(dc => dc.label === dominantComposition)?.dominantCompositionManagementClasses
                    .findIndex(dcmc => dcmc.managementClassId === managementClass.id) !== -1
            ));

            // Définition des colonnes
            this.customFieldFilters = [];
            data.columns = [
                {
                    name: i18n.t("Référence"), key: 'projectReference', width: 100,
                    sortable: true, editable: false, frozen: true, visible: mainPF.references,
                    filterRenderer: p => <NumberFilter p={p} step='1' />
                },
                {
                    name: i18n.t("Référence personnalisée"), key: 'customReference', width: 180,
                    sortable: true, frozen: true, visible: requiredFields.customReference && mainPF.references,
                    filterRenderer: p => <TextFilter p={p} />,
                    editable: () => RightsUtil.canWrite(this.props.rights?.greenSpaces),
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('text', row, column, onRowChange, onClose)
                },
                {
                    name: i18n.t("Lieu"), key: 'place', width: 250, category: 'Emplacement',
                    sortable: true, visible: requiredFields.place && greenSpacesPF.place,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Emplacement')?.color),
                    filterRenderer: p => <TextFilter p={p} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.greenSpaces),
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('text', row, column, onRowChange, onClose)
                },
                {
                    name: i18n.t("Libellé"), key: 'placeExtra', width: 180, category: 'Emplacement',
                    sortable: true, visible: requiredFields.placeExtra && greenSpacesPF.placeExtra,
                    formatter: (props) => props.row.placeExtra || '',
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Emplacement')?.color),
                    filterRenderer: p => <TextFilter p={p} />,
                    editable: () => RightsUtil.canWrite(this.props.rights?.greenSpaces),
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('text', row, column, onRowChange, onClose)
                },
                {
                    name: i18n.t("Stations"), key: 'stations', width: 250, category: 'Emplacement',
                    sortable: true, visible: this.props.nbStations > 0,
                    formatter: (props) => <div className='disabled'>
                        {this.state.greenSpacesStations ? props.row.stations : <div>
                            <Loader active inline size='mini' style={{ marginRight: '5px' }} />
                            <small>{i18n.t("Chargement en cours...")}</small>
                        </div>}
                    </div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Emplacement')?.color),
                    filterRenderer: p => <TextFilter p={p} />,
                },
                {
                    name: i18n.t("Tags"), key: 'tags', width: 200, category: 'Emplacement',
                    sortable: false, visible: requiredFields.tags && greenSpacesPF.tags,
                    formatter: (props) => {
                        if (props.row.tags === null) return '';
                        const lastIndex = props.row.tags.lastIndexOf(',');
                        const tags = props.row.tags.substring(lastIndex + 1, lastIndex + 3).trim() === '-'
                            ? props.row.tags.slice(0, lastIndex + 1) + props.row.tags.slice(lastIndex + 3, props.row.tags.length)
                            : props.row.tags || '';
                        return tags;
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Emplacement')?.color),
                    filterRenderer: p => <TextFilter p={p} />,
                    editable: () => RightsUtil.canWrite(this.props.rights?.greenSpaces),
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('smart', row, column, onRowChange, onClose, this.state.tags)
                },
                {
                    name: i18n.t("Pied d'arbre"), key: 'isTreeBase', width: 110, category: 'Emplacement',
                    sortable: true, visible: requiredFields.isTreeBase && greenSpacesPF.isTreeBase,
                    formatter: (props) => props.row.isTreeBase || '',
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Emplacement')?.color),
                    filterRenderer: p => <BooleanFilter p={p} />,
                    editable: () => RightsUtil.canWrite(this.props.rights?.greenSpaces),
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('boolean', row, column, onRowChange, onClose),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Fonction de l'espace"), key: 'spaceFunction', width: 150, category: 'Emplacement',
                    sortable: true, visible: requiredFields.spaceFunction && greenSpacesPF.spaceFunction,
                    formatter: (props) => props.row.spaceFunction || '',
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Emplacement')?.color),
                    filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.spaceFunctions} isNullable />,
                    editable: () => RightsUtil.canWrite(this.props.rights?.greenSpaces),
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('dropdown', row, column, onRowChange, onClose, this.state.spaceFunctions),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Type de surface"), key: 'spaceType', width: 350, category: 'Emplacement',
                    sortable: true, visible: requiredFields.spaceType && greenSpacesPF.spaceType,
                    formatter: (props) => props.row.spaceType || '',
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Emplacement')?.color),
                    filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.spaceTypes} isNullable />,
                    editable: () => RightsUtil.canWrite(this.props.rights?.greenSpaces),
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('dropdown', row, column, onRowChange, onClose, this.state.spaceTypes),
                    editorOptions: { editOnClick: true }
                },
                ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Emplacement')),
                {
                    name: i18n.t("Composition dominante"), key: 'dominantComposition', width: 180, category: 'Composition',
                    sortable: true, visible: requiredFields.dominantComposition && greenSpacesPF.dominantComposition,
                    formatter: (props) => props.row.dominantComposition || '',
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Composition')?.color),
                    filterRenderer: p => <DropDownFilter p={p} propertyOptions={this.state.dominantCompositions} isNullable />,
                    editable: () => RightsUtil.canWrite(this.props.rights?.greenSpaces),
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('dropdown', row, column, onRowChange, onClose, this.state.dominantCompositions),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Composition détaillée"), key: 'detailedComposition', width: 180, category: 'Composition',
                    sortable: true, visible: requiredFields.detailedComposition && greenSpacesPF.detailedComposition,
                    formatter: (props) => !props.row.dominantComposition
                        ? <div className='disabled'></div>
                        : props.row.detailedComposition || '',
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Composition')?.color),
                    filterRenderer: p => <TextFilter p={p} />,
                    //! Ne fonctionne pas sans le '? true : false'
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.greenSpaces) && props.dominantComposition ? true : false,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('text', row, column, onRowChange, onClose)
                },
                ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Composition')),
                {
                    name: i18n.t("Classe de gestion"), key: 'managementClass', width: 130, category: 'Entretien',
                    sortable: true, visible: requiredFields.managementClass && greenSpacesPF.managementClass,
                    formatter: (props) => {
                        const linkedManagementClass = availableManagementClasses(props.row.dominantComposition).find(mc => mc.label === props.row.managementClass);
                        const isDisabled = !props.row.dominantComposition || availableManagementClasses(props.row.dominantComposition).length < 1;
                        return isDisabled ? <div className='disabled'></div> : linkedManagementClass ? props.row.managementClass : ''
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Entretien')?.color),
                    filterRenderer: p => <DropDownNumberFilter p={p} propertyOptions={this.state.managementClasses} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.greenSpaces) &&
                        (props.dominantComposition && availableManagementClasses(props.dominantComposition).length > 0 ? true : false),
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor(
                        'dropdown', row, column, onRowChange, onClose,
                        availableManagementClasses(row.dominantComposition)
                    ),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Fréquence annuelle d'entretien"), key: 'annualMaintenanceFrequency', width: 220, category: 'Entretien',
                    sortable: true, visible: requiredFields.annualMaintenanceFrequency && greenSpacesPF.annualMaintenanceFrequency,
                    formatter: (props) => {
                        const isDisabled = !props.row.dominantComposition || !props.row.managementClass
                            || availableManagementClasses(props.row.dominantComposition).length < 1
                            || !availableManagementClasses(props.row.dominantComposition).find(mc => mc.label === props.row.managementClass);
                        return isDisabled
                            ? <div className='disabled'>0</div>
                            : props.row.annualMaintenanceFrequency
                    },
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Entretien')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.1' />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.greenSpaces) && (
                        props.dominantComposition && props.managementClass
                            && availableManagementClasses(props.dominantComposition).length > 0
                            && availableManagementClasses(props.dominantComposition).find(mc => mc.label === props.managementClass)
                            ? true : false
                    ),
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('number', row, column, onRowChange, onClose)
                },
                ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Entretien')),
                {
                    name: i18n.t("Observation"), key: 'observation', width: 500, category: 'Observation',
                    sortable: true, visible: requiredFields.observation && greenSpacesPF.observation,
                    formatter: (props) => props.row.observation || '',
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Observation')?.color),
                    filterRenderer: p => <TextFilter p={p} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.greenSpaces) && props.dominantCompositionId === 7,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('text', row, column, onRowChange, onClose)
                },
                ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Observation')),
                {
                    name: i18n.t("Nombre d'arbres"), key: 'nbTrees', width: 150, category: 'Massif arboré',
                    sortable: true, visible: requiredFields.density && greenSpacesPF.density,
                    formatter: (props) => props.row.dominantCompositionId === 7
                        ? props.row.nbTrees || ''
                        : <div className='disabled'></div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='1' />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.greenSpaces) && props.dominantCompositionId === 7,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('number', row, column, onRowChange, onClose)
                },
                {
                    name: i18n.t("Densité"), key: 'density', width: 110, category: 'Massif arboré',
                    sortable: true, visible: requiredFields.density && greenSpacesPF.density,
                    formatter: (props) => props.row.dominantCompositionId === 7
                        ? props.row.density || ''
                        : <div className='disabled'></div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='1' />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.greenSpaces) && props.dominantCompositionId === 7,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('number', row, column, onRowChange, onClose)
                },
                {
                    name: i18n.t("Distance entre les troncs"), key: 'distanceBetweenTrunks', width: 270, category: 'Massif arboré',
                    sortable: true, visible: requiredFields.density && greenSpacesPF.density,
                    formatter: (props) => props.row.dominantCompositionId === 7
                        ? props.row.distanceBetweenTrunks || ''
                        : <div className='disabled'></div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='1' />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.greenSpaces) && props.dominantCompositionId === 7,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('number', row, column, onRowChange, onClose)
                },
                {
                    name: i18n.t("Nom vernaculaire dominant"), key: 'vernacularName', width: 150, category: 'Massif arboré',
                    sortable: true, visible: requiredFields.dominantEssence && greenSpacesPF.dominantEssence,
                    formatter: (props) => props.row.dominantCompositionId === 7
                        ? props.row.vernacularName || ''
                        : <div className='disabled'></div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                    filterRenderer: p => <EssenceFilter p={p} propertyOptions={this.state.vernacularNames} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.greenSpaces) && props.dominantCompositionId === 7,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('essence', row, column, onRowChange, onClose, this.state.vernacularNames),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Genre dominant"), key: 'gender', width: 150, category: 'Massif arboré',
                    sortable: true, visible: requiredFields.dominantEssence && greenSpacesPF.dominantEssence,
                    formatter: (props) => props.row.dominantCompositionId === 7
                        ? props.row.gender || ''
                        : <div className='disabled'></div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                    filterRenderer: p => <EssenceFilter p={p} propertyOptions={this.state.genders} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.greenSpaces) && props.dominantCompositionId === 7,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('essence', row, column, onRowChange, onClose, this.state.genders),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Espèce dominante"), key: 'species', width: 150, category: 'Massif arboré',
                    sortable: true, visible: requiredFields.dominantEssence && greenSpacesPF.dominantEssence,
                    formatter: (props) => props.row.dominantCompositionId === 7
                        ? props.row.species || ''
                        : <div className='disabled'></div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                    filterRenderer: p => <EssenceFilter p={p} propertyOptions={this.state.species} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.greenSpaces) && props.dominantCompositionId === 7 && (props.gender ? true : false),
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('essence', row, column, onRowChange, onClose, this.state.species),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Cultivar dominant"), key: 'cultivar', width: 150, category: 'Massif arboré',
                    sortable: true, visible: requiredFields.dominantEssence && greenSpacesPF.dominantEssence,
                    formatter: (props) => props.row.dominantCompositionId === 7
                        ? props.row.cultivar || ''
                        : <div className='disabled'></div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                    filterRenderer: p => <EssenceFilter p={p} propertyOptions={this.state.cultivars} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.greenSpaces) && props.dominantCompositionId === 7 && (props.species ? true : false),
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('essence', row, column, onRowChange, onClose, this.state.cultivars),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Cote sanitaire moyenne"), key: 'averageHealthReview', width: 175, category: 'Massif arboré',
                    sortable: true, visible: requiredFields.averageHealthReview && greenSpacesPF.averageHealthReview,
                    formatter: (props) => props.row.dominantCompositionId === 7
                        ? props.row.averageHealthReview || props.row.averageHealthReview === 0 ? props.row.averageHealthReview : ''
                        : <div className='disabled'></div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                    filterRenderer: p => <DropDownNumberFilter p={p} propertyOptions={this.state.healthReviews} />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.greenSpaces) && props.dominantCompositionId === 7,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('dropdown', row, column, onRowChange, onClose, this.state.healthReviews),
                    editorOptions: { editOnClick: true }
                },
                {
                    name: i18n.t("Hauteur moyenne") + ' (m)', key: 'averageHeight', width: 160, category: 'Massif arboré',
                    sortable: true, visible: requiredFields.averageHeight && greenSpacesPF.averageHeight,
                    formatter: (props) => props.row.dominantCompositionId === 7
                        ? props.row.averageHeight || ''
                        : <div className='disabled'></div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.1' />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.greenSpaces) && props.dominantCompositionId === 7,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('number', row, column, onRowChange, onClose)
                },
                {
                    name: i18n.t("Diamètre moyen des couronnes") + ' (m)', key: 'averageCrownDiameter', width: 250, category: 'Massif arboré',
                    sortable: true, visible: requiredFields.averageCrownDiameter && greenSpacesPF.averageCrownDiameter,
                    formatter: (props) => props.row.dominantCompositionId === 7
                        ? props.row.averageCrownDiameter || ''
                        : <div className='disabled'></div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.1' />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.greenSpaces) && props.dominantCompositionId === 7,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('number', row, column, onRowChange, onClose)
                },
                {
                    name: i18n.t("Circonférence moyenne des troncs") + ' (cm)', key: 'averageCircumference', width: 280, category: 'Massif arboré',
                    sortable: true, visible: requiredFields.averageCircumference && greenSpacesPF.averageCircumference,
                    formatter: (props) => props.row.dominantCompositionId === 7
                        ? props.row.averageCircumference || ''
                        : <div className='disabled'></div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.1' />,
                    editable: (props) => RightsUtil.canWrite(this.props.rights?.greenSpaces) && props.dominantCompositionId === 7,
                    editor: ({ row, column, onRowChange, onClose }) => this.getEditor('number', row, column, onRowChange, onClose)
                },
                ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Massif arboré')),
                {
                    name: `${i18n.t("Surface")} (m²)`, key: 'surface', width: 165, category: 'Indicateurs',
                    sortable: true, editable: false, visible: requiredFields.surface && greenSpacesPF.surface,
                    formatter: (props) => <div className='disabled'>{props.row.surface}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Indicateurs')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.01' />
                },
                {
                    name: `${i18n.t("Longueur")} (m)`, key: 'length', width: 165, category: 'Indicateurs',
                    sortable: true, editable: false, visible: requiredFields.length && greenSpacesPF.length,
                    formatter: (props) => <div className='disabled'>{props.row.length || ''}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Indicateurs')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.01' />
                },
                {
                    name: `${i18n.t("Stockage annuel CO2")} (kg/an)`, key: 'carbonStock', width: 210, category: 'Indicateurs',
                    sortable: true, editable: false, visible: requiredFields.carbonStock && greenSpacesPF.carbonStock && subscription.carbonStockFormula && activeOrganization.subscription.carbonStockFormula,
                    formatter: (props) => <div className='disabled'>{props.row.isEmpty === i18n.t("Oui") ? '' : props.row.carbonStock}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Indicateurs')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.01' />
                },
                {
                    name: `${i18n.t("Stockage total CO2")} (kg/an)`, key: 'totalCarbonStock', width: 195, category: 'Indicateurs',
                    sortable: true, editable: false, visible: requiredFields.carbonStock && greenSpacesPF.carbonStock && subscription.carbonStockFormula && activeOrganization.subscription.carbonStockFormula,
                    formatter: (props) => <div className='disabled'>{props.row.isEmpty === i18n.t("Oui") ? '' : props.row.totalCarbonStock}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Indicateurs')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.01' />
                },
                {
                    name: `${i18n.t("Rafraîchissement (énergie)")} (kWh/an)`, key: 'coolingEnergyIndicator', width: 255, category: 'Indicateurs',
                    sortable: true, editable: false, visible: requiredFields.coolingIndicator && greenSpacesPF.coolingIndicator && subscription.coolingFormula && activeOrganization.subscription.coolingFormula,
                    formatter: (props) => <div className='disabled'>{props.row.isEmpty === i18n.t("Oui") ? '' : props.row.coolingEnergyIndicator}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Indicateurs')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.01' />
                },
                {
                    name: `${i18n.t("Rafraîchissement (valeur)")} (€/an)`, key: 'coolingEconomicValue', width: 230, category: 'Indicateurs',
                    sortable: true, editable: false, visible: requiredFields.coolingIndicator && greenSpacesPF.coolingIndicator && subscription.coolingFormula && activeOrganization.subscription.coolingFormula,
                    formatter: (props) => <div className='disabled'>{props.row.isEmpty === i18n.t("Oui") ? '' : props.row.coolingEconomicValue}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Indicateurs')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.01' />
                },
                {
                    name: `${i18n.t("Production d'oxygène")} (kg/an)`, key: 'oxygenProduction', width: 210, category: 'Indicateurs',
                    sortable: true, editable: false, visible: requiredFields.oxygenProduction && greenSpacesPF.carbonStock,
                    formatter: (props) => <div className='disabled'>{props.row.isEmpty === i18n.t("Oui") ? '' : props.row.oxygenProduction}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Indicateurs')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.01' />
                },
                {
                    name: `${i18n.t("Potentiel de biodiversité")} (/100)`, key: 'ecopotentialIndex', width: 210, category: 'Indicateurs',
                    sortable: true, editable: false, visible: requiredFields.spaceType && greenSpacesPF.spaceType,
                    formatter: (props) => <div className='disabled'>{props.row.isEmpty === i18n.t("Oui") || isNaN(props.row.ecopotentialIndex) ? '' : props.row.ecopotentialIndex * 100}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props, this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Indicateurs')?.color),
                    filterRenderer: p => <NumberFilter p={p} step='0.01' />
                },
                ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Indicateurs')),
                ...this.getCustomCategoriesColumns(),
                ...this.getCustomColumns(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === 'Autres')),
                {
                    name: i18n.t("Date de création"), key: 'creationDate', width: 170,
                    sortable: true, editable: false, visible: true,
                    formatter: (props) => <div className='disabled'>{props.row.creationDate}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props),
                    filterRenderer: p => <TextFilter p={p} />
                },
                {
                    name: i18n.t("Créateur"), key: 'creatorName', width: 170,
                    sortable: true, editable: false, visible: true,
                    formatter: (props) => <div className='disabled'>{props.row.creatorName}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props),
                    filterRenderer: p => <TextFilter p={p} />
                },
                {
                    name: i18n.t("Dernière modification"), key: 'modificationDate', width: 170,
                    sortable: true, editable: false, visible: true,
                    formatter: (props) => <div className='disabled'>{props.row.modificationDate}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props),
                    filterRenderer: p => <TextFilter p={p} />
                },
                {
                    name: i18n.t("Éditeur"), key: 'editorName', width: 170,
                    sortable: true, editable: false, visible: true,
                    formatter: (props) => <div className='disabled'>{props.row.editorName}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props),
                    filterRenderer: p => <TextFilter p={p} />
                },
                {
                    name: i18n.t("Projet"), key: 'projectLabel', width: 170,
                    sortable: true, editable: false, visible: this.props.project?.type === 'folder',
                    formatter: (props) => <div className='disabled'>{props.row.projectLabel}</div>,
                    headerRenderer: (props) => this.getHeaderRenderer(props),
                    filterRenderer: p => <TextFilter p={p} />
                }
            ];

            if (this.props.project.orderConfig)
                data.columns.sort((a, b) => {
                    const aOrder = FieldsUtil.getCategoryOrder(this.props.project.orderConfig, 'Espace vert', a.category)
                    const bOrder = FieldsUtil.getCategoryOrder(this.props.project.orderConfig, 'Espace vert', b.category)
                    return aOrder - bOrder;
                });

            this.setState(({ filters }) => {
                this.customFieldFilters.forEach(customFieldId => filters[customFieldId] = '');
                return { filters };
            });

            // Ajout des données
            if (!rows) {
                const layers = this.greenSpaces;
                for (const key in layers)
                    if (layers.hasOwnProperty(key))
                        data.rows.push(this.getRowValue(layers[key].feature, key, projectTags));

                data.rows.sort((a, b) => a.projectReference - b.projectReference);
                let initialOrder = [];
                data.rows.forEach(row => {
                    initialOrder.push(row.elementId);
                });
                this.setState({ initialOrder: initialOrder });
            }
        }
        return data;
    }

    loadGreenSpacesStations = () => {
        return new Promise((resolve, reject) => {
            StationsService.getElementStations('GreenSpaces', this.props.project.id).then((greenSpacesStations) => {
                if (greenSpacesStations) {
                    this.setState(prevState => ({
                        greenSpacesStations,
                        data: {
                            ...prevState.data,
                            rows: prevState.data.rows.map(row => {
                                row.stations = greenSpacesStations[row.elementId];
                                return row;
                            })
                        }
                    }), () => resolve);
                } else if (this.props.isOnline) {
                    this.setState({ greenSpacesStations: {} });
                    reject();
                }
                else {
                    this.setState({ greenSpacesStations: {} });
                    resolve();
                }
            });
        });
    }

    getCustomColumns = (category, isDefaultCategory = true) => {
        const { activeOrganization, customFields, project } = this.props;
        const areCustomFieldsAvailable = (project.organization || activeOrganization).subscription.customFields;
        if (!areCustomFieldsAvailable) return [];

        const projectCustomFields = [...(project.projectCustomFields || [])].sort((a, b) => a.order - b.order);
        const pcfColumns = projectCustomFields.filter(pcf => pcf.fieldCategoryId === category.id).map((projectCustomField) => {
            const customField = customFields.find(cf => cf.id === projectCustomField.customFieldId);
            this.customFieldFilters.push(String(customField.id));

            return customField?.category === 'Espace vert' ? {
                name: customField.label + (['number', 'formula'].includes(customField.type) && customField.unit?.trim() ? ` (${customField.unit})` : ''), key: customField.id, width: 180, category: isDefaultCategory ? category.label : `${category.id}`,
                sortable: true, visible: true, customField,
                formatter: (props) => (
                    props.row[String(customField.id)] ? (
                        customField.type === 'url'
                            ? <a href={props.row[String(customField.id)]?.includes('http') ? props.row[String(customField.id)] : '//' + props.row[String(customField.id)]} target='_blank' rel='noreferrer'>{props.row[String(customField.id)]}</a>
                            : customField.type === 'formula'
                                ? FormattersUtil.formatFormulaCustomField(customField, props.row[String(customField.id)])
                                : props.row[String(customField.id)] + (customField.type === 'number' && customField.unit?.trim() ? customField.unit : '')
                    ) : ''
                ),
                headerRenderer: (props) => this.getHeaderRenderer(props, category.color),
                filterRenderer: p => (
                    customField.type === 'boolean' ? <BooleanFilter p={p} />
                        : ['text', 'url', 'date'].includes(customField.type) || (customField.type === 'list' && customField.isMultiple) ? <TextFilter p={p} />
                            : customField.type === 'number' ? <NumberFilter p={p} step={customField.step} />
                                : customField.type === 'list' ? <DropDownFilter p={p} propertyOptions={customField.dropdownCustomFieldValues.map(dcfv => ({ label: dcfv.label, id: dcfv.id }))} isNullable />
                                    : null
                ),
                editable: (props) => RightsUtil.canWrite(this.props.rights?.greenSpaces) && !(
                    (props.isEmpty === i18n.t("Oui") && !customField.forEmpty) ||
                    (props.isDead === i18n.t("Oui") && !customField.forDead) ||
                    (props.isStump === i18n.t("Oui") && !customField.forStump)
                ),
                editor: ({ row, column, onRowChange, onClose }) => this.getEditor(
                    customField.type === 'list' ? customField.isMultiple ? 'smart' : 'dropdown' : customField.type,
                    row, column, onRowChange, onClose, customField.dropdownCustomFieldValues?.map(dcfv => ({ label: dcfv.label, id: dcfv.id }))
                ),
                editorOptions: { editOnClick: ['boolean', 'list'].includes(customField.type) }
            } : null;
        }).filter(pcf => pcf);

        return pcfColumns;
    }

    getCustomCategoriesColumns = () => {
        const { project } = this.props;
        const fieldCategories = project?.fieldCategories || [];
        const fieldCategoriesToRender = fieldCategories.filter(fieldCategory => fieldCategory.category === 'Espace vert' && (project?.projectCustomFields || []).find(pcf => pcf.fieldCategoryId === fieldCategory.id));
        return fieldCategoriesToRender.flatMap(fieldCategory => this.getCustomColumns(fieldCategory, false));
    }

    getFilteredRows = () => {
        const { selectedElements } = this.props;
        const { filters, data, enableFilterRow } = this.state;
        let rows = [...data.rows];

        const $ = (str) => FormattersUtil.getNormalizedString(str);
        return rows.filter(r => {
            return (!selectedElements?.length || selectedElements.find(el => el.feature.id === r.elementId))
                && (!filters.global?.trim().length || Object.keys(r).filter(property => !['id', 'elementId'].includes(property)).some(property => (r[property] || r[property] === 0) && $(`${r[property]}`).includes($(filters.global))))
                && (!enableFilterRow || (
                    (filters.projectReference ? r.projectReference === Number(filters.projectReference) : true)
                    && (filters.customReference ? $(r.customReference)?.includes($(filters.customReference)) : true)
                    && (filters.place ? $(r.place)?.includes($(filters.place)) : true)
                    && (filters.placeExtra ? $(r.placeExtra)?.includes($(filters.placeExtra)) : true)
                    && (filters.tags ? $(r.tags)?.includes($(filters.tags)) : true)
                    && (filters.detailedComposition ? $(r.detailedComposition)?.includes($(filters.detailedComposition)) : true)
                    && (filters.observation ? $(r.observation)?.includes($(filters.observation)) : true)
                    && (filters.creationDate ? $(r.creationDate)?.includes($(filters.creationDate)) : true)
                    && (filters.creatorName ? $(r.creatorName)?.includes($(filters.creatorName)) : true)
                    && (filters.modificationDate ? $(r.modificationDate)?.includes($(filters.modificationDate)) : true)
                    && (filters.editorName ? $(r.editorName)?.includes($(filters.editorName)) : true)
                    && (filters.projectLabel ? $(r.projectLabel)?.includes($(filters.projectLabel)) : true)
                    && (filters.isTreeBase ? (r.isTreeBase === filters.isTreeBase || (filters.isTreeBase === 'empty' && !r.isTreeBase)) : true)
                    && (filters.spaceFunction ? (r.spaceFunction === filters.spaceFunction || (filters.spaceFunction === 'empty' && !r.spaceFunction)) : true)
                    && (filters.spaceType ? (r.spaceType === filters.spaceType || (filters.spaceType === 'empty' && !r.spaceType)) : true)
                    && (filters.dominantComposition ? (r.dominantComposition === filters.dominantComposition || (filters.dominantComposition === 'empty' && !r.dominantComposition)) : true)
                    && (filters.managementClass ? (r.managementClass === filters.managementClass || (filters.managementClass === 'empty' && !r.managementClass)) : true)
                    && (filters.annualMaintenanceFrequency ? Number(r.annualMaintenanceFrequency) === Number(filters.annualMaintenanceFrequency) : true)
                    && (filters.nbTrees ? Number(r.nbTrees) === Number(filters.nbTrees) : true)
                    && (filters.density ? Number(r.density) === Number(filters.density) : true)
                    && (filters.distanceBetweenTrunks ? Number(r.distanceBetweenTrunks) === Number(filters.distanceBetweenTrunks) : true)
                    && (filters.vernacularName ? (r.vernacularName === filters.vernacularName || (filters.vernacularName === 'empty' && !r.vernacularName)) : true)
                    && (filters.gender ? (r.gender === filters.gender || (filters.gender === 'empty' && !r.gender)) : true)
                    && (filters.species ? (r.species === filters.species || (filters.species === 'empty' && !r.species)) : true)
                    && (filters.cultivar ? (r.cultivar === filters.cultivar || (filters.cultivar === 'empty' && !r.cultivar)) : true)
                    && (filters.averageHealthReview || filters.averageHealthReview === 0 ? (r.averageHealthReview === filters.averageHealthReview || (filters.averageHealthReview === 'empty' && !r.averageHealthReview && r.averageHealthReview !== 0)) : true)
                    && (filters.averageHeight ? Number(r.averageHeight) === Number(filters.averageHeight) : true)
                    && (filters.averageCircumference ? Number(r.averageCircumference) === Number(filters.averageCircumference) : true)
                    && (filters.averageCrownDiameter ? Number(r.averageCrownDiameter) === Number(filters.averageCrownDiameter) : true)
                    && (filters.surface ? Number(r.surface) === Number(filters.surface) : true)
                    && (filters.length ? Number(r.length) === Number(filters.length) : true)
                    && (filters.carbonStock ? Number(r.carbonStock) === Number(filters.carbonStock) : true)
                    && (filters.totalCarbonStock ? Number(r.totalCarbonStock) === Number(filters.totalCarbonStock) : true)
                    && (filters.coolingEnergyIndicator ? Number(r.coolingEnergyIndicator) === Number(filters.coolingEnergyIndicator) : true)
                    && (filters.coolingEconomicValue ? Number(r.coolingEconomicValue) === Number(filters.coolingEconomicValue) : true)
                    && (filters.oxygenProduction ? Number(r.oxygenProduction) === Number(filters.oxygenProduction) : true)
                    && (filters.ecopotentialIndex ? Number(r.ecopotentialIndex) === Number(filters.ecopotentialIndex) : true)
                    && data.columns.filter(column => column.customField).every(({ key, customField }) => (
                        filters[key] ? (
                            customField.type === 'boolean' ? r[key] === filters[key]
                                : ['text', 'url', 'date'].includes(customField.type) || (customField.type === 'list' && customField.isMultiple) ? $(r[key])?.includes($(filters[key]))
                                    : (r[key] === filters[key] || (filters[key] === 'empty' && !r[key]))
                        ) : true
                    )))
                );
        });
    }

    getHistoryPropertyValue = (properties) => {
        if (properties.length < 1) return;

        if (properties.find(x => ['vernacularName', 'gender', 'species', 'cultivar'].includes(x.property))) {
            const vernacularName = properties.find(x => x.property === 'vernacularName').value;
            const gender = properties.find(x => x.property === 'gender').value;
            const species = properties.find(x => x.property === 'species').value;
            const cultivar = properties.find(x => x.property === 'cultivar').value;

            const essence = this.props.essences.find(x => // On recherche l'essence correspondante aux données saisies
                x.vernacularName === (vernacularName || null)
                && x.gender === gender
                && x.species === (species || null)
                && x.cultivar === (cultivar || null)
            );

            return essence?.id || 0;
        } else return this.searchForPropertyOptions(properties[0].property, properties[0].value, properties[0].customField);
    }

    getRowValue = (feature, key, projectTags) => {
        const properties = feature.properties;
        const projectReference = properties.projectReference;
        const customReference = properties.customReference;
        const place = properties.place;
        const placeExtra = properties.placeExtra;
        let tags = '';
        if (properties.tagId)
            for (let i = 0; i < properties.tagId.length; i++) {
                const tag = projectTags.find(x => x.id === properties.tagId[i]);
                if (tag) tags += !tags ? tag.label : ', ' + tag.label
            }
        if (tags === '') tags = null; // Important pour différencier avec les enfants
        const isTreeBase = properties.isTreeBase ? i18n.t("Oui") : i18n.t("Non");
        const spaceFunction = this.props.spaceFunctions.find(x => x.id === properties.spaceFunctionId)?.label;
        const spaceType = this.props.spaceTypes.find(x => x.id === properties.spaceTypeId);
        const dominantCompositionId = properties.dominantCompositionId;
        const dominantComposition = this.props.dominantCompositions.find(x => x.id === dominantCompositionId)?.label;
        const detailedComposition = properties.detailedComposition;
        const managementClass = this.props.managementClasses.find(x => x.id === properties.managementClassId)?.value;
        const annualMaintenanceFrequency = properties.annualMaintenanceFrequency;
        const observation = properties.observation;
        const essence = this.props.essences.find(x => x.id === properties.dominantEssenceId);
        const nbTrees = properties.nbTrees;
        const density = properties.density;
        const distanceBetweenTrunks = properties.distanceBetweenTrunks;
        const vernacularName = essence?.vernacularName;
        const gender = essence?.gender;
        const species = essence?.species;
        const cultivar = essence?.cultivar;
        const averageHeight = Math.round((properties.averageHeight / 100) * 100) / 100;
        const averageCircumference = properties.averageCircumference;
        const averageCrownDiameter = Math.round((properties.averageCrownDiameter / 100) * 100) / 100;
        const averageHealthReview = this.props.healthReviews.find(x => x.id === properties.averageHealthReviewId)?.value;
        const surface = properties.surface;
        const length = properties.baseLine?.length;
        const carbonStock = properties.carbonStock?.toFixed(2);
        let totalCarbonStock = 0;
        if (dominantCompositionId === 7) totalCarbonStock = FormulasUtil.getTreeTotalCarbonStock(carbonStock, averageCircumference)?.toFixed(2);
        const coolingEnergyIndicator = properties.coolingEnergyIndicator?.toFixed(2);
        const coolingEconomicValue = FormulasUtil.getCoolingEconomicValue(coolingEnergyIndicator)?.toFixed(2);
        const oxygenProduction = FormulasUtil.getOxygenProduction(carbonStock)?.toFixed(2);
        const ecopotentialIndex = spaceType?.ecopotentialIndex;
        const creatorName = properties.creatorName;
        const creationDate = `${DatesUtil.getFormattedLocaleDateString(properties.creationDate)}, ${DatesUtil.getFormattedLocaleTimeString(properties.creationDate)}`;
        const editorName = properties.editorName;
        const modificationDate = editorName ? `${DatesUtil.getFormattedLocaleDateString(properties.modificationDate)}, ${DatesUtil.getFormattedLocaleTimeString(properties.modificationDate)}` : null;
        const projectLabel = this.props.project?.projectLabels?.find(pl => pl.id === feature.projectId)?.label;
        const stations = this.state.greenSpacesStations ? this.state.greenSpacesStations[feature.id] : null;

        const row = {
            id: key, elementId: feature.id, projectReference, customReference, place, placeExtra, tags, isTreeBase, spaceFunction,
            spaceType: spaceType?.label, dominantCompositionId, dominantComposition, detailedComposition, managementClass, annualMaintenanceFrequency, observation,
            nbTrees, density, distanceBetweenTrunks, vernacularName, gender, species, cultivar, averageHeight, averageCircumference, averageCrownDiameter,
            averageHealthReview, surface, length, carbonStock, totalCarbonStock, coolingEnergyIndicator, coolingEconomicValue, oxygenProduction, ecopotentialIndex,
            creationDate, creatorName, modificationDate, editorName, projectLabel, stations
        };

        if (properties.customFields) {
            Object.keys(properties.customFields).forEach(key => {
                const customField = this.props.customFields.find(customField => customField.id === Number(key));
                if (customField) {
                    row[key] = customField.type === 'boolean' ? properties.customFields[key] === 'true' ? i18n.t("Oui") : i18n.t("Non")
                        : customField.type === 'date'
                            ? DatesUtil.getFormattedLocaleDateString(properties.customFields[key])
                            : customField.type === 'list'
                                ? customField.isMultiple
                                    ? properties.customFields[key].split(',').map(id => customField.dropdownCustomFieldValues.find(dcfv => dcfv.id === Number(id))?.label).join(', ')
                                    : customField.dropdownCustomFieldValues.find(dcfv => dcfv.id === Number(properties.customFields[key]))?.label
                                : properties.customFields[key];
                }
            });
        }

        return row;
    }

    getProperties = (properties) => {
        let newProperties = { ...properties };

        if (!properties.dominantCompositionId) {
            newProperties = {
                ...newProperties,
                managementClassId: 0,
                detailedComposition: null
            };
        } else if (!this.props.dominantCompositions.find(dc => dc.id === properties.dominantCompositionId)?.dominantCompositionManagementClasses
            .map(dcmc => dcmc.managementClassId)
            .includes(properties.managementClassId)) { // Si il y a une composition dominante mais qu'elle n'admet pas la gestion sélectionnée
            newProperties = { ...newProperties, managementClassId: 0 };
        }

        if (!properties.managementClassId) {
            newProperties = {
                ...newProperties,
                annualMaintenanceFrequency: 0
            };
        }

        if (properties.dominantCompositionId !== 7) {
            newProperties = {
                ...newProperties,
                nbTrees: 0,
                density: 0,
                distanceBetweenTrunks: 0,
                dominantEssenceId: 0,
                averageHealthReviewId: 0,
                averageHeight: 0,
                averageCircumference: 0,
                averageCrownDiameter: 0
            };
        }

        return newProperties;
    }

    getPropertyName = (columnKey) => {
        let property = columnKey;
        if (['vernacularName', 'gender', 'species', 'cultivar'].includes(columnKey)) property = 'dominantEssenceId';
        if (['spaceFunction', 'spaceType', 'dominantComposition', 'managementClass', 'averageHealthReviewId'].includes(columnKey))
            property = property + 'Id';
        else if (columnKey === 'tags') property = property.substring(0, property.length - 1) + 'Id'
        return property;
    }

    getTargetRowPropertyValue = (columnKey, sourceRow, targetRow, customField) => {
        if (!sourceRow[columnKey]) return;
        if (['vernacularName', 'gender', 'species', 'cultivar'].includes(columnKey)) {
            const vernacularName = columnKey === 'vernacularName' ? sourceRow?.vernacularName : null;
            const gender = columnKey !== 'gender' && !sourceRow[columnKey] ? targetRow.gender : sourceRow.gender;
            const species = columnKey === 'cultivar' && !sourceRow.cultivar
                ? targetRow.species
                : (['species', 'cultivar'].includes(columnKey) ? sourceRow?.species : null);
            const cultivar = columnKey === 'cultivar' ? sourceRow?.cultivar : null;
            const essence = this.props.essences.find(x => // On recherche l'essence correspondante aux données saisies
                x.vernacularName === (vernacularName || null)
                && x.gender === gender
                && x.species === (species || null)
                && x.cultivar === (cultivar || null)
            );
            return essence?.id;
        }
        return this.searchForPropertyOptions(columnKey, sourceRow[columnKey], customField);
    }

    updateElementCustomFields = (feature, property, value) => {
        if (value?.toISOString) value = value.toISOString();
        if (value) {
            if (!feature.properties.customFields) feature.properties.customFields = {};
            feature.properties.customFields[property] = value;
        } else if (feature.properties.customFields)
            delete feature.properties.customFields[property];
    }

    changeGreenSpacesToModify = (greenSpacesToModify) => {
        if (!this.props.elementsHaveBeenModified) this.props.setElementsHaveBeenModified(true);
        this.setState({ greenSpacesToModify: greenSpacesToModify });
    }

    changeGreenSpacesToModifyLocally = (elementId, property, value, greenSpacesToModify, customField) => {
        const index = greenSpacesToModify.findIndex(greenSpace => greenSpace.id === elementId);
        if (['nbTrees', 'density', 'distanceBetweenTrunks', 'averageHeight', 'averageCircumference', 'averageCrownDiameter'].includes(property))
            greenSpacesToModify[index].properties[property] = Number(['averageHeight', 'averageCrownDiameter'].includes(property) ? value * 100 : value);
        else if (customField) this.updateElementCustomFields(greenSpacesToModify[index], property, value);
        else greenSpacesToModify[index].properties[property] = value;

        return greenSpacesToModify;
    }

    pushToModificationsHistory = (modifications) => {
        let modificationsHistory = this.state.modificationsHistory;
        modificationsHistory = modificationsHistory.slice(0, this.state.modificationsHistoryIndex);
        modificationsHistory.push(modifications);
        this.setState(prevState => ({
            modificationsHistory: modificationsHistory,
            modificationsHistoryIndex: prevState.modificationsHistoryIndex + 1
        }));
    }

    searchForPropertyOptions = (property, value, customField) => {
        let propertyOptions;
        switch (property) {
            case 'spaceFunction': propertyOptions = this.state.spaceFunctions; break;
            case 'spaceType': propertyOptions = this.state.spaceTypes; break;
            case 'dominantComposition': propertyOptions = this.state.dominantCompositions; break;
            case 'managementClass': propertyOptions = this.state.managementClasses; break;
            case 'averageHealthReview': propertyOptions = this.state.healthReviews; break;
            case 'tags': propertyOptions = this.state.tags; break;
            default:
                if (customField?.type === 'boolean')
                    propertyOptions = [{ label: i18n.t("Oui"), id: 'true' }, { label: i18n.t("Non"), id: 'false' }];
                else if (customField?.dropdownCustomFieldValues)
                    propertyOptions = customField.dropdownCustomFieldValues.map(dcfv => ({ label: dcfv.label, id: dcfv.id }));
                break;
        }
        if (!propertyOptions) return value;
        else {
            if (customField?.isMultiple || property === 'tags') {
                const values = value?.split(',') || [];
                let ids = [];
                values.forEach(value => {
                    let linkedValue;
                    if (value.trim() !== '') linkedValue = propertyOptions.find(x => x.label === value.trim());
                    if (linkedValue) ids.push(linkedValue.id);
                    else {
                        let trueValue = value.substring(0, 2).trim() === '-' ? value.substring(2).trim() : value.trim();
                        if (trueValue !== '')
                            ids.push(trueValue);
                    }
                });
                return customField ? ids.join(',') : ids;
            }

            let option = propertyOptions.find(x => x.label === value);
            return customField ? (option?.id ? String(option.id) : '') : option?.id || 0;
        };
    }

    handleKeyDown = (e) => {
        if (e.ctrlKey && e.key === 'z') this.restorePreviousModification();
        else if (e.ctrlKey && e.key === 'y') this.restoreNextModification();
        else if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            const { selectedColumn, selectedRow } = this.state;
            if (selectedColumn && selectedRow) navigator.clipboard.writeText(selectedRow[selectedColumn.key] || '');
        }
    }

    restorePreviousModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const previousModification = this.state.modificationsHistory[index - 1];
        if (previousModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { greenSpacesToModify, greenSpacesToDelete } = this.state;

            let modificationsToCreate = [];
            let previousElementsId = [];
            let previousElementsProperties = [];
            let essences = [];
            let rowsToSend = [];
            previousModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                previousElementsId.push(elementId);
                previousElementsProperties.push(property);

                if (property !== 'delete') {
                    let row = data.rows.find(x => x.elementId === modification.elementId);
                    modificationsToCreate.push({ property: modification.property, elementId: modification.elementId, oldValue: row[modification.property], customField: modification.customField });
                    row[modification.property] = modification.oldValue;
                    if (row.elementId === this.state.selectedRow?.elementId) this.updateSelectedRow(row);
                    rowsToSend.push(row);

                    const property = this.getPropertyName(modification.property);
                    if (property !== 'dominantEssenceId') {
                        let value = this.getHistoryPropertyValue([{ property: modification.property, value: modification.oldValue, customField: modification.customField }]);
                        greenSpacesToModify = this.changeGreenSpacesToModifyLocally(modification.elementId, property, value, greenSpacesToModify, modification.customField);
                    } else {
                        const essenceIndex = essences.findIndex(essence => essence.elementId === modification.elementId);
                        if (essenceIndex === -1) essences.push({ elementId: modification.elementId, properties: [{ property: modification.property, value: modification.oldValue }] });
                        else essences[essenceIndex].properties = [...essences[essenceIndex].properties, { property: modification.property, value: modification.oldValue }];
                    }
                } else {
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: oldValue });
                    greenSpacesToDelete = greenSpacesToDelete.filter(element => element.id !== oldValue.id);
                    data.rows.splice(elementId, 0, oldValue);
                }
            });

            essences.forEach(essence => {
                const essenceId = greenSpacesToModify.find(x => x.id === essence.elementId).properties.dominantEssenceId;
                const greenSpaceToModifyEssence = this.props.essences.find(x => x.id === essenceId);
                const pushIfNotFound = (property) => {
                    if (!essence.properties.find(x => x.property === property))
                        essence.properties.push({
                            property: property,
                            value: greenSpaceToModifyEssence[property]
                        });
                }
                pushIfNotFound('vernacularName');
                pushIfNotFound('gender');
                pushIfNotFound('species');
                pushIfNotFound('cultivar');

                const value = this.getHistoryPropertyValue(essence.properties);
                greenSpacesToModify = this.changeGreenSpacesToModifyLocally(essence.elementId, 'dominantEssenceId', value, greenSpacesToModify);
            });

            let modificationsHistory;
            if (index === this.state.modificationsHistory.length) {
                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory.push(modificationsToCreate);
            } else {
                let actualElementsId = [];
                let actualElementsProperties = [];
                this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
                this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));
                if (JSON.stringify(previousElementsId) !== JSON.stringify(actualElementsId)
                    || JSON.stringify(previousElementsProperties) !== JSON.stringify(actualElementsProperties)) {
                    modificationsHistory = this.state.modificationsHistory;
                    modificationsHistory[index] = modificationsToCreate;
                }
            }

            this.setState(prevState => ({
                data, greenSpacesToModify, greenSpacesToDelete,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index - 1,
            }), () => WebSocketUtil.updateForm(this.props.webSocketHubs, 'GreenSpaceTable' + this.props.project.id, { rows: rowsToSend, greenSpacesToModify }));
        }
    }

    restoreNextModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const nextModification = this.state.modificationsHistory[index + 1];
        if (nextModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { greenSpacesToModify, greenSpacesToDelete } = this.state;

            let modificationsToCreate = [];
            let nextElementsId = [];
            let nextElementsProperties = [];
            let essences = [];
            let rowsToSend = [];
            nextModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                nextElementsId.push(elementId);
                nextElementsProperties.push(property);

                if (property !== 'delete') {
                    let row = data.rows.find(x => x.elementId === modification.elementId);
                    modificationsToCreate.push({ property: modification.property, elementId: modification.elementId, oldValue: row[modification.property], customField: modification.customField });
                    row[modification.property] = modification.oldValue;
                    if (row.elementId === this.state.selectedRow?.elementId) this.updateSelectedRow(row);
                    rowsToSend.push(row);

                    const property = this.getPropertyName(modification.property);
                    if (property !== 'dominantEssenceId') {
                        let value = this.getHistoryPropertyValue([{ property: modification.property, value: modification.oldValue, customField: modification.customField }]);
                        greenSpacesToModify = this.changeGreenSpacesToModifyLocally(modification.elementId, property, value, greenSpacesToModify, modification.customField);
                    } else {
                        const essenceIndex = essences.findIndex(essence => essence.elementId === modification.elementId);
                        if (essenceIndex === -1) essences.push({ elementId: modification.elementId, properties: [{ property: modification.property, value: modification.oldValue }] });
                        else essences[essenceIndex].properties = [...essences[essenceIndex].properties, { property: modification.property, value: modification.oldValue }];
                    }
                } else {
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: oldValue });
                    greenSpacesToDelete.push(oldValue);
                    data.rows.splice(elementId, 1);
                }
            });

            essences.forEach(essence => {
                const essenceId = greenSpacesToModify.find(x => x.id === essence.elementId).properties.dominantEssenceId;
                const greenSpaceToModifyEssence = this.props.essences.find(x => x.id === essenceId);
                const pushIfNotFound = (property) => {
                    if (!essence.properties.find(x => x.property === property))
                        essence.properties.push({
                            property: property,
                            value: greenSpaceToModifyEssence[property]
                        });
                }
                pushIfNotFound('vernacularName');
                pushIfNotFound('gender');
                pushIfNotFound('species');
                pushIfNotFound('cultivar');

                const value = this.getHistoryPropertyValue(essence.properties);
                greenSpacesToModify = this.changeGreenSpacesToModifyLocally(essence.elementId, 'dominantEssenceId', value, greenSpacesToModify);
            });

            let modificationsHistory;
            let actualElementsId = [];
            let actualElementsProperties = [];
            this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
            this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));
            if (JSON.stringify(nextElementsId) !== JSON.stringify(actualElementsId)
                || nextElementsProperties !== actualElementsProperties) {
                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory[index] = modificationsToCreate;
            }

            if (index === this.state.modificationsHistory.length - 2)
                modificationsHistory = this.state.modificationsHistory.slice(0, this.state.modificationsHistory.length - 1);

            this.setState(prevState => ({
                data, greenSpacesToModify, greenSpacesToDelete,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index + 1
            }), () => WebSocketUtil.updateForm(this.props.webSocketHubs, 'GreenSpaceTable' + this.props.project.id, { rows: rowsToSend, greenSpacesToModify }));
        }
    }

    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);

    handleFill = ({ columnKey, sourceRow, targetRows }) => {
        const { greenSpacesToModify, data } = this.state;
        let property = this.getPropertyName(columnKey);
        const customField = data.columns.find(column => column.key === columnKey)?.customField;

        const shouldUpdate = (row, columnKey) => {
            if ((!row.dominantComposition) && ['detailedComposition', 'managementClass'].includes(columnKey))
                return false;
            if ((!row.dominantComposition || !row.managementClass) && columnKey === 'annualMaintenanceFrequency')
                return false;
            return true;
        }

        let rowsUpdated = false;
        targetRows.forEach(row => {
            if (shouldUpdate(row, columnKey)) {
                rowsUpdated = true;
                const value = this.getTargetRowPropertyValue(columnKey, sourceRow, row, customField);
                const index = greenSpacesToModify.findIndex(greenSpace => greenSpace.id === row.elementId);
                const feature = index === -1
                    ? JSON.parse(JSON.stringify(this.greenSpaces[row.id].feature))
                    : greenSpacesToModify[index];

                if (['nbTrees', 'density', 'distanceBetweenTrunks', 'averageHeight', 'averageCircumference', 'averageCrownDiameter'].includes(property))
                    feature.properties[property] = Number(['averageHeight', 'averageCrownDiameter'].includes(property) ? value * 100 : value);
                else if (customField) this.updateElementCustomFields(feature, property, value);
                else feature.properties[property] = value;
                if (index === -1) greenSpacesToModify.push(feature);
            }
        });
        if (rowsUpdated) this.changeGreenSpacesToModify(greenSpacesToModify);

        let modificationsToCreate = [];
        const newRows = targetRows.map(row => {
            if (!shouldUpdate(row, columnKey)) return row;
            modificationsToCreate.push({ elementId: row.elementId, property: columnKey, oldValue: row[columnKey], customField });
            return { ...row, [columnKey]: sourceRow[columnKey] };
        });

        WebSocketUtil.updateForm(this.props.webSocketHubs, 'GreenSpaceTable' + this.props.project.id, { rows: newRows, greenSpacesToModify });
        this.pushToModificationsHistory(modificationsToCreate);
        return newRows;
    }

    handleSubmit = (shouldClose) => {
        const { project, projects, formulas } = this.props;
        const tableToShow = this.state.tableToShow;
        if (tableToShow) this.setState({ tableToShow: null });

        const guidRegExp = new RegExp('({){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(}){0,1}');
        let tagsToAdd = [];
        let { greenSpacesToModify, greenSpacesToDelete } = this.state;
        let layersToModify = [];
        let greenSpacesNotToModifyAnymore = [];
        greenSpacesToModify.forEach(greenSpaceToModify => {
            const properties = this.getProperties(greenSpaceToModify.properties);
            if (JSON.stringify(properties) === JSON.stringify(this.greenSpaces.find(x => x.feature.id === greenSpaceToModify.id).feature.properties)
                || greenSpacesToDelete.find(greenSpace => greenSpace.id === greenSpaceToModify.id))
                greenSpacesNotToModifyAnymore.push(greenSpaceToModify);
            else greenSpaceToModify.properties = properties;
        });
        greenSpacesToModify = greenSpacesToModify.filter(greenSpace => !greenSpacesNotToModifyAnymore.includes(greenSpace));
        for (let i = 0; i < greenSpacesToModify.length; i++) { // Pour chaque espace vert à modifier
            layersToModify.push(this.greenSpaces.find(x => x.feature.id === greenSpacesToModify[i].id));
            let tagId = greenSpacesToModify[i].properties.tagId;
            if (tagId) {
                for (let j = 0; j < tagId.length; j++) { // Pour chaque tag de l'espace vert
                    let tag = tagId[j];
                    // Si le tag n'est pas un GUID et n'est pas encore dans la liste d es tags à ajouter
                    if (!guidRegExp.test(tag)) {
                        if (!tagsToAdd.find(x => x.label === tag))
                            tagsToAdd.push({ id: uuidv4(), label: tag, projectId: this.props.project.id, category: 'Espace vert' });
                        tagId[j] = tagsToAdd.find(x => x.label === tag).id;
                    }
                }
            }
        }

        if (tagsToAdd.length > 0) {
            ProjectsService.addProjectTags(tagsToAdd).then(response => {
                if (response) {
                    let project = this.props.project;
                    project.tags = response;
                    this.props.setProject(project);
                }
            });
        }

        if (greenSpacesToModify.length > 0 && greenSpacesToModify.length === layersToModify.length) {
            this.props.showLoader(true, 'modal', i18n.t("Mise à jour des espaces verts en cours..."));
            UpdatesUtil.bulkUpdateGreenSpaces(layersToModify, greenSpacesToModify, this.props.fieldList, this.props.project.id, this.props.greenSpacesLayer.activeChild, 'updating', { webSocketHubs: this.props.webSocketHubs, thematicMaps: this.props.project.thematicMaps }).finally(() => {
                this.props.showLoader(false);
                this.props.updateLegend(i18n.t("Espaces verts"));
                if (shouldClose) tableToShow ? this.props.changeModalContentType(tableToShow, i18n.t("Tableau de données"), true) : this.props.hideForm(true);
            });
        } else {
            if (greenSpacesToModify.length > 0) showToast('greenspaces_updated');
            if (shouldClose) tableToShow ? this.props.changeModalContentType(tableToShow, i18n.t("Tableau de données"), true) : this.props.hideForm(true);
        }

        if (greenSpacesToDelete.length > 0) {
            const layersToDelete = this.greenSpaces.filter(greenSpace => greenSpacesToDelete.find(greenSpaceToDelete => greenSpaceToDelete.elementId === greenSpace.feature.id));

            this.props.showLoader(true, 'modal', i18n.t("Suppression des espaces verts en cours..."));
            GreenSpacesService.removeGreenSpaces(layersToDelete.map(greenSpace => greenSpace.feature), this.props.webSocketHubs).finally(() => {
                // Suppression des espaces verts sur la carte
                this.props.greenSpacesLayer.eachLayer(layer => {
                    if (layersToDelete.find(l => l.feature.id === layer.feature?.id))
                        this.props.greenSpacesLayer.removeLayer(layer); // On supprime l'élément du layer
                });

                // Suppression des liens
                const ids = greenSpacesToDelete.map(gstd => gstd.elementId);
                if (ids.length && project?.linkedElements?.length) {
                    let updateProject = JSON.parse(JSON.stringify(project));
                    updateProject.linkedElements = updateProject.linkedElements.filter(le => !ids.includes(le.elementId) && !ids.includes(le.linkedElementId));
                    if (updateProject.linkedElements.length !== project.linkedElements.length)
                        ProjectsUtil.updateProjectsInProps(updateProject, projects, formulas, project, this.props.setProjects, this.props.setProject);
                }

                this.props.showLoader(false);
                this.props.updateLegend(i18n.t("Espaces verts"));
                if (shouldClose) tableToShow ? this.props.changeModalContentType(tableToShow, i18n.t("Tableau de données"), true) : this.props.hideForm(true);
            });
        } else if (shouldClose) tableToShow ? this.props.changeModalContentType(tableToShow, i18n.t("Tableau de données"), true) : this.props.hideForm(true);

        this.props.setElementsHaveBeenModified(false);
        this.setState({ greenSpacesToModify: [], greenSpacesToDelete: [], modificationsHistory: [], modificationsHistoryIndex: 0 });
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow }));

    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (let property in this.state.filters)
            if (this.state.filters[property]) filtersApplied = true;
        return filtersApplied;
    }

    clearFilters = () => {
        const filters = JSON.parse(JSON.stringify(initialFilters));
        this.state.data.columns.filter(column => column.customField).forEach(column => filters[String(column.customField.id)] = '');
        this.setState({ filters });
    }

    sortRows = () => {
        const sortDirection = this.state.sortDirection;
        let rows = [...this.state.data.rows];
        if (sortDirection === 'NONE') {
            for (let i = 0; i < this.state.initialOrder.length; i++) {
                let temp = rows[i];
                const index = rows.findIndex(x => x.elementId === this.state.initialOrder[i]);
                rows[i] = rows[index];
                rows[index] = temp;
            }

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: rows
                }
            }));
        } else {
            const sortColumn = this.state.sortColumn;
            const customField = this.state.data.columns.find(column => column.key === sortColumn)?.customField;
            if (['projectReference', 'managementClass', 'annualMaintenanceFrequency', 'surface', 'length', 'carbonStock', 'totalCarbonStock', 'coolingEnergyIndicator',
                'coolingEconomicValue', 'oxygenProduction', 'ecopotentialIndex', 'nbTrees', 'density', 'distanceBetweenTrunks', 'healthReview', 'averageHeight',
                'averageCircumference', 'averageCrownDiameter'].includes(sortColumn))
                rows = rows.sort((a, b) => (a[sortColumn] || 0) - (b[sortColumn] || 0));
            else if (['creationDate', 'modificationDate'].includes(sortColumn))
                rows = rows.sort((a, b) => {
                    const aDate = DatesUtil.convertDateStringToDate(a[sortColumn]), bDate = DatesUtil.convertDateStringToDate(b[sortColumn]);
                    return !aDate ? -1 : !bDate ? 1 : aDate - bDate;
                });
            else if (sortColumn === 'customReference')
                rows = rows.sort(({ customReference: a }, { customReference: b }) => {
                    if (!a && b) return sortDirection === 'DESC' ? -1 : 1;
                    const aIsNumber = a && !isNaN(a) ? true : false, bIsNumber = b && !isNaN(b) ? true : false;
                    return aIsNumber && bIsNumber ? (parseInt(a) - parseInt(b)) : (
                        aIsNumber ? -1 : (bIsNumber ? 1 : (a || '').localeCompare((b || '')))
                    );
                });
            else if (customField?.type === 'number')
                rows = rows.sort((a, b) => {
                    if (!a[sortColumn]) return -1;
                    if (!b[sortColumn]) return 1;
                    return Number(a[sortColumn]) - Number(b[sortColumn]);
                });
            else rows = rows.sort((a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: sortDirection === 'DESC' ? rows.reverse() : rows
                }
            }));
        }
    }

    resetRow = (_, { rowIdx }) => {
        let greenSpacesToModify = this.state.greenSpacesToModify;
        let data = {
            columns: [...this.state.data.columns],
            rows: [...this.state.data.rows]
        };

        // On reset les données de la ligne sélectionnée
        const layers = this.greenSpaces;
        let filteredRows = this.getFilteredRows();
        let rowToSend;
        for (const key in layers) {
            if (layers[key].feature.id === filteredRows[rowIdx].elementId) {
                const index = greenSpacesToModify.findIndex(x => x.id === filteredRows[rowIdx].elementId);
                if (index !== -1) {
                    greenSpacesToModify[index] = JSON.parse(JSON.stringify(layers[key].feature));
                    const newDisplayedData = this.getRowValue(layers[key].feature, key, this.state.tags);
                    let modificationsToCreate = [];
                    let rowIndex = data.rows.findIndex(x => x.elementId === filteredRows[rowIdx].elementId);
                    const properties = [...new Set([...Object.keys(newDisplayedData), ...Object.keys(data.rows[rowIndex])])];
                    for (const property of properties)
                        if (data.rows[rowIndex][property] !== newDisplayedData[property]) {
                            const customField = data.columns.find(column => column.key === Number(property))?.customField;
                            modificationsToCreate.push({ property: property, elementId: data.rows[rowIndex].elementId, oldValue: data.rows[rowIndex][property], customField });
                        }
                    if (modificationsToCreate.length > 0)
                        this.pushToModificationsHistory(modificationsToCreate);
                    data.rows[rowIndex] = newDisplayedData;
                    rowToSend = data.rows[rowIndex];
                    this.updateSelectedRow(newDisplayedData);
                }
            }
        }

        this.setState({ data, greenSpacesToModify }, () => {
            if (rowToSend) WebSocketUtil.updateForm(this.props.webSocketHubs, 'GreenSpaceTable' + this.props.project.id, { rows: [rowToSend], greenSpacesToModify })
        });
    }

    deleteRow = (_, { rowIdx }) => {
        let greenSpacesToDelete = this.state.greenSpacesToDelete;
        let data = {
            columns: [...this.state.data.columns],
            rows: [...this.state.data.rows]
        };

        // On supprime la ligne sélectionnée et l'ajoute aux éléments à supprimer
        let filteredRows = this.getFilteredRows();
        const initialElement = this.state.data.rows.find(element => filteredRows[rowIdx].id === element.id);
        greenSpacesToDelete.push(JSON.parse(JSON.stringify(initialElement)));
        let rowIndex = data.rows.findIndex(row => row.id === filteredRows[rowIdx].id);
        this.pushToModificationsHistory([{ property: 'delete', elementId: initialElement.elementId, oldValue: data.rows[rowIndex] }]);
        data.rows.splice(rowIndex, 1);

        this.setState({ data, greenSpacesToDelete, selectedRow: null, selectedColumn: null });
    }

    exportXLSX = () => {
        const elementsToExport = this.getFilteredRows().map(row => row.elementId);
        const projection = this.props.projections.find(x => x.id === (this.props.project.projectionId || 1));
        GreenSpacesService.exportGreenSpacesFromProjectAsExcel(this.props.project.label, this.props.project.id, elementsToExport, projection);
    }

    exportSHP = () => {
        const elementsToExport = this.getFilteredRows().map(row => row.elementId);
        const projection = this.props.projections.find(x => x.id === (this.props.project.projectionId || 1));
        GreenSpacesService.exportGreenSpacesAsSHP(this.props.project.label, this.props.project.id, elementsToExport, projection);
    }

    exportPDF = () => {
        const elementsToExport = this.getFilteredRows().map(row => row.elementId);
        GreenSpacesService.exportGreenSpacesAsPDF(this.props.project.id, elementsToExport);
        showToast('pdfs_exporting');
    }

    exportPhotos = () => {
        const elementsToExport = this.getFilteredRows().map(row => row.elementId);
        FilesService.exportProjectPhotos(this.props.project.id, ['greenSpaces'], elementsToExport);
        showToast('photos_exporting');
    }

    showRowElement = (_, { rowIdx }) => {
        this.props.setTableState({ ...this.state, greenSpaces: this.greenSpaces, timestamp: new Date().toISOString(), selectedRow: null, selectedColumn: null }).then(() => {
            const layers = this.greenSpaces;
            const rowToShow = this.getFilteredRows()[rowIdx];
            for (const key in layers) {
                if (layers[key].feature.id === rowToShow.elementId)
                    this.props.showElement('Espaces verts', layers[key].feature, 'GreenSpaceTable');
            }
        });
    }

    showRowElementOnMinimap = (_, { rowIdx }) => {
        const layers = this.greenSpaces;
        for (const key in layers)
            if (layers[key].feature.id === this.getFilteredRows()[rowIdx].elementId)
                this.setState({ featureToShow: layers[key].feature });
    }

    showFilteredElements = () => {
        this.props.setTableState({ ...this.state, greenSpaces: this.greenSpaces, timestamp: new Date().toISOString(), selectedRow: null, selectedColumn: null }).then(() => {
            this.props.showElements('Espaces verts', this.getFilteredRows())
        });
    }

    updateForm = (state) => {
        let rows = JSON.parse(JSON.stringify(this.state.data.rows));
        let greenSpacesToModify = JSON.parse(JSON.stringify(this.state.greenSpacesToModify));
        let selectedRow = this.state.selectedRow ? JSON.parse(JSON.stringify(this.state.selectedRow)) : null;
        const stateParsed = JSON.parse(state);
        const newRows = stateParsed.rows;
        newRows.forEach(newRow => {
            const index = rows.findIndex(r => r.elementId === newRow.elementId);
            if (index !== -1) rows[index] = newRow;
            if (selectedRow?.elementId === newRow.elementId) selectedRow = newRow;
        });
        const newGreenSpacesToModify = stateParsed.greenSpacesToModify;
        newGreenSpacesToModify.forEach(newGreenSpaceToModify => {
            const index = greenSpacesToModify.findIndex(ttm => ttm.id === newGreenSpaceToModify.id);
            if (index !== -1) greenSpacesToModify[index] = newGreenSpaceToModify;
            else greenSpacesToModify.push(newGreenSpaceToModify);
        });

        this.setState(prevState => ({ isLoading: false, data: { ...prevState.data, rows }, greenSpacesToModify, selectedRow }));
    }
}

const mapStateToProps = (state) => {
    return {
        spaceFunctions: state.spaceFunctions,
        spaceTypes: state.spaceTypes,
        dominantCompositions: state.dominantCompositions,
        managementClasses: state.managementClasses,
        essences: state.essences,
        healthReviews: state.healthReviews,
        project: state.project,
        projects: state.projects,
        formulas: state.formulas,
        projectCollaborators: state.projectCollaborators,
        rights: state.rights,
        tableState: state.tableState,
        isDarkTheme: state.isDarkTheme,
        isOnline: state.isOnline,
        projections: state.projections,
        activeOrganization: state.activeOrganization,
        webSocketHubs: state.webSocketHubs,
        defaultFieldCategories: state.defaultFieldCategories,
        customFields: state.project
            ? [...state.customFields, ...state.organizationCustomFields || [], ...(state.projectsCustomFields[state.project?.id] || [])]
            : state.customFields
    };
};

const mapDispatchToProps = {
    setTableState,
    setProject,
    setProjects
}

export default connect(mapStateToProps, mapDispatchToProps)(GreenSpaceTable);