import React, { Component } from 'react';
// Composants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Button, Message, Segment, Input, Select, Divider } from 'semantic-ui-react';
import InfoIcon from '../../Utils/InfoIcon';
import ReactJson from 'react-json-view';
// Librairies
import { isMobileOnly, isMobile } from 'react-device-detect';
import i18n from '../../../locales/i18n';
import { connect } from 'react-redux';
// Redux
import { setElementHistory, setLayer } from '../../../actionCreators/elementsActions';
// Ressources
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import HybridPng from '../../../resources/pngs/hybrid.png';
// Services
import AIService from '../../../services/AIService';
// Utils
import FormattersUtil from '../../../utils/FormattersUtil';
import UpdatesUtil from '../../../utils/UpdatesUtil';
import WebSocketUtil from '../../../utils/WebSocketUtil';
import AppSettings from '../../../AppSettings';

const initialMessage = {
    type: null,
    content: []
};

const jsonExample = { "result": "12.4" };

class FieldCompletionForm extends Component {
    state = {
        url: '',
        customFields: [],
        customFieldId: null,
        message: initialMessage,
        selectedLayer: 'google',
        isLoading: false
    };

    render() {
        const { customFields, customFieldId, url, responseJson, message, isLoading } = this.state;

        return (
            <Form className='modal-content' onSubmit={this.handleSubmit} loading={isLoading} error>
                <Segment className='modal-content-body' style={{ marginTop: 0, marginBottom: isMobile ? 0 : null, paddingTop: 0, paddingBottom: '5px', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ padding: '14px' }}>
                        <Form.Group widths='equal' style={{ marginBottom: 0 }}>
                            <Form.Field
                                control={Input} label={i18n.t("URL") + '* : '} placeholder={i18n.t("Indiquez une URL")}
                                name='url' value={url || ''} error={message.url} onChange={this.handleUrlChange}
                            />
                            <Form.Field
                                control={Select} selectOnBlur={false} clearable search={FormattersUtil.searchList} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                label={<label>{i18n.t("Champs à compléter")} <InfoIcon content={i18n.t("Pour l'instant, seuls les champs de type texte, nombre ou booléen peuvent être complétés via une API externe")} iconStyle={{ marginLeft: '3px' }} /> :</label>} placeholder={i18n.t("Sélectionnez un champs personnalisé")}
                                name='customFieldId' options={customFields} value={customFieldId}
                                onChange={(_, { value }) => this.setState({ customFieldId: value })}
                            />
                        </Form.Group>
                        {this.props.project.wmsServices?.length > 0 &&
                            <div style={{ overflow: 'auto', flexGrow: 1, paddingRight: '5px', marginTop: '10px' }}>
                                <label style={{ fontSize: '.92857143em' }}><b>{i18n.t("Fond de carte à utiliser")} :</b></label>
                                <div style={{ overflowY: !isMobileOnly && 'overlay', minHeight: isMobileOnly && '90px' }}>
                                    <div className='layer-list' style={{ display: 'flex' }}>
                                        {this.renderBaseLayersList()}
                                    </div>
                                </div>
                            </div>}
                        <Message
                            hidden={!message.type} error={message.type === 'error'} warning={message.type === 'warning'} success={message.type === 'success'}
                            header={message.type === 'error' ? i18n.t("Erreur") : message.type === 'warning' ? i18n.t("Attention") : i18n.t("Succès")}
                            list={message.content} style={{ textAlign: 'left', overflow: 'auto', margin: '14px', display: message.type ? 'block' : 'none' }}
                            content={
                                responseJson ?
                                    <div>
                                        <span className='no-themed' style={{ fontSize: '.92857143em', fontWeight: 'bold' }}>{i18n.t("Réponse")} :</span>
                                        <Segment style={{ marginTop: '3px' }}>
                                            <ReactJson name={null} src={responseJson} enableClipboard={false} displayObjectSize={false} />
                                        </Segment>
                                    </div> : null
                            }
                        />
                        <Divider />
                        <p style={{ marginBottom: 0 }}>
                            {i18n.t("Afin de pouvoir utiliser votre propre modèle IA pour la détection d'arbres dans Grality, il est nécessaire de mettre à notre disposition une API que nous contacterons via le endpoint renseigné ci-dessus.")}<br /><br />
                            {i18n.t("Cet endpoint doit être capable de :")}
                        </p>
                        <ul style={{ margin: '4px 21px' }}>
                            <li style={{ marginBottom: '4px' }}>{i18n.t("Recevoir une requête POST contenant une image en format JPEG & et les données de l'arbre (Content-Type: form-data)", { interpolation: { escapeValue: false } })}</li>
                            <li style={{ marginBottom: '4px' }}>
                                {i18n.t("Retourner un résultat avec la structure JSON suivante :")}
                                <Segment style={{ marginTop: '5px' }}>
                                    <ReactJson name={null} src={jsonExample} enableClipboard={false} displayObjectSize={false} />
                                </Segment>
                                <u>{i18n.t("Description des attributs")} :</u>
                                <ul>
                                    <li><b>result :</b> {i18n.t("Valeur à insérer dans le champs personnalisé sélectionné")}</li>
                                </ul>
                            </li>
                        </ul>
                        <p style={{ marginBottom: 0 }}>
                            {i18n.t("Notre API prend en charge les choses suivantes :")}
                        </p>
                        <ul style={{ margin: '4px 21px' }}>
                            <li style={{ marginBottom: '4px' }}>{i18n.t("Chargement & envoi de l'image orthophoto de l'arbre à votre API")}</li>
                            <li style={{ marginBottom: '4px' }}>{i18n.t("Chargement & envoi des données de l'arbre à votre API")}</li>
                            <li style={{ marginBottom: '4px' }}>{i18n.t("Conversion de la chaîne de caractères résultat dans le bon format")}</li>
                        </ul>
                        {i18n.t("Lors de la complétion d'un champs via ce système, notre API enverra une requête contenant une image ainsi que les données de l'arbre sous forme de form-data.")}<br />
                    </div>
                </Segment>
                {!this.props.isKeyboardOpen &&
                    <div className='modal-content-footer'>
                        {this.renderSubmitButton()}
                    </div>}
            </Form >
        );
    }

    componentDidMount = () => {
        this.setState({
            customFields: this.props.customFields
                .filter(customField => customField.category === this.props.layer[0].feature.properties.category && ['boolean', 'number', 'text'].includes(customField.type))
                .map(customField => ({ text: customField.label, value: customField.id }))
        });
    }

    renderSubmitButton = () => {
        const { url, customFieldId } = this.state;

        const isUrl = (url) => {
            try { return Boolean(new URL(url)); }
            catch (e) { return false; }
        };

        const isDisabled = !isUrl(url?.trim()) || !customFieldId;
        return (
            <Button type='submit' className='form-button' color='green' disabled={isDisabled}>
                <FontAwesomeIcon icon={faCheck} style={{ marginRight: !isMobileOnly && '10px' }} />{!isMobileOnly && i18n.t("Valider")}
            </Button>
        );
    }

    renderBaseLayersList = () => {
        const blobInfos = AppSettings.getBlobInfos();
        return [
            { id: 'google', label: i18n.t("Par défaut"), url: null, layers: null, preview: HybridPng },
            ...this.props.project.wmsServices
                .filter(({ type }) => type === 'baseLayer')
                .map(service => ({ ...service, preview: service?.preview && `${blobInfos.endpoint}${blobInfos.containers.photos}/${service.preview}` }))
        ].map((wmsService, index) => (
            <div
                key={index} className='base-layer-select' onClick={() => this.setState({ selectedLayer: wmsService.id, wmsService: wmsService.id !== 'google' ? wmsService : null })}
                style={{
                    border: `solid 2px ${this.state.selectedLayer === wmsService.id ? 'var(--primary-100)' : 'transparent'}`,
                    display: 'flex', flexDirection: 'column', margin: '1px', alignItems: 'center', width: '100px', textAlign: 'center', padding: '2px 4px', borderRadius: '5px', cursor: 'pointer'
                }}
            >
                <div
                    className='base-layer-preview no-hover'
                    style={{
                        background: wmsService.preview ? `url(${wmsService.preview})` : null,
                        display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '14pt', fontWeight: 'bold'
                    }}
                >
                    {!wmsService.preview && `${wmsService.label.slice(0, 1).toUpperCase()}${wmsService.label.slice(1, 2).toLowerCase()}`}
                </div>
                <div title={wmsService.label} style={{ fontSize: '10pt', marginLeft: '5px', fontWeight: 'bold', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '100%' }}>
                    {wmsService.label}
                </div>
            </div>
        ));
    }

    handleUrlChange = (_, { value }) => {
        this.setState(prevState => ({
            url: value || null,
            responseJson: null,
            message: { ...prevState.message, type: null, content: [] }
        }));
    }

    handleSubmit = () => {
        const { layer, fieldList, treesLayer, webSocketHubs, project, elementHistory } = this.props;
        const { url, customFieldId, wmsService } = this.state;

        this.setState({ isLoading: true });
        AIService.getFieldPrediction({ customAPI: url, customFieldId, wmsService, treeId: this.props.layer[0].feature.id, projectId: project.id }).then((response) => {
            const isError = response?.status !== 200;
            this.setState({
                isLoading: !isError,
                responseJson: isError && !response?.data?.status && response?.data,
                message: isError ? {
                    type: 'error', content: [i18n.t("L'API n'a pas pu être contactée ou n'a retourné aucune réponse valable")]
                } : initialMessage
            });

            if (!isError) {
                const properties = layer[0].feature.properties;
                properties.customFields = { ...(properties.customFields || {}), [customFieldId]: response.data };

                UpdatesUtil.updateTree(layer[0], properties, layer, true, fieldList, treesLayer, 'updating', layer[0].feature.projectId, webSocketHubs, { thematicMaps: project?.thematicMaps })
                    .then(response => {
                        if (response?.data?.history && elementHistory) {
                            this.props.setElementHistory([...elementHistory, response.data.history]);
                            if (this.props.project) WebSocketUtil.sendElementsHistories(this.props.webSocketHubs, this.props.project.id, [response.data.history]);
                        }
                    })
                    .finally(() => {
                        this.setState({ isLoading: false });
                        this.props.setLayer([...this.props.layer]);
                        this.props.updateLegend('Arbres');
                        if (properties.trunks?.length)
                            properties.trunks.forEach(trunk => {
                                if (trunk.circumference)
                                    trunk.circumference = FormattersUtil.getTrunkCircumference(trunk.circumference, this.props.project.trunkCircumferenceUnit);
                            });
                    });
            }
        })
    }
}

const mapStateToProps = (state) => {
    return {
        layer: state.layer,
        project: state.project,
        isKeyboardOpen: state.isKeyboardOpen,
        elementHistory: state.elementHistory,
        webSocketHubs: state.webSocketHubs,
        customFields: state.project
            ? [...state.customFields, ...state.organizationCustomFields || [], ...(state.projectsCustomFields[state.project?.id] || [])]
            : state.customFields
    };
};

const mapDispatchToProps = {
    setElementHistory,
    setLayer
};

export default connect(mapStateToProps, mapDispatchToProps)(FieldCompletionForm);