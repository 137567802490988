import React, { Component } from 'react';
// Composants
/*     Editors     */
import BooleanEditor from '../Editors/BooleanEditor';
import DateEditor from '../Editors/DateEditor';
import DropDownEditor from '../Editors/DropDownEditor';
import NumberEditor from '../Editors/NumberEditor';
import TextEditor from '../Editors/TextEditor';
/*     Filters     */
import TextFilter from '../../Tables/Filters/TextFilter';
import BooleanFilter from '../../Tables/Filters/BooleanFilter';
import NumberFilter from '../../Tables/Filters/NumberFilter';
import DropDownFilter from '../../Tables/Filters/DropDownFilter';
// Librairies
import DataGrid, { Row as GridRow } from 'react-data-grid';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import i18n from '../../../locales/i18n';
// Redux
import { connect } from 'react-redux';
import { setProjects } from '../../../actionCreators/projectsActions';
// Semantic UI
import { Dimmer, Loader, Form, Button, Menu, Input, Icon, Segment } from 'semantic-ui-react';
// Services
import ProjectsService from '../../../services/ProjectsService';
// Styles
import '../../../styles/react-contextmenu.css';
import '../../../styles/rdg.css';
// Utils
import { showToast } from '../../../utils/ToastsUtil';
import FormattersUtil from '../../../utils/FormattersUtil';
import StylesUtil from '../../../utils/StylesUtil';
import ProjectsUtil from '../../../utils/ProjectsUtil';
import DatesUtil from '../../../utils/DatesUtil';

const initialFilters = {
    id: '',
    parentLabel: '',
    label: '',
    type: '',
    owner: '',
    isImporting: '',
    isDuplicating: '',
    deletionDate: '',
    treeIncrement: '',
    greenSpaceIncrement: '',
    furnitureIncrement: '',
    markerIncrement: ''
};

class ProjectTable extends Component {
    state = {
        data: {
            columns: [],
            rows: []
        },
        elementsToModify: [],
        modificationsHistory: [],
        modificationsHistoryIndex: 0,
        rowIndex: 0,
        sortColumn: null,
        sortDirection: 'NONE',
        enableFilterRow: false,
        filters: initialFilters,
        areAllProjectsLoaded: false,
        isLoading: true,
        isUpdating: false,
        isExporting: false,
        joiningProject: 0
    }

    render() {
        const {
            data, elementsToModify, modificationsHistory, modificationsHistoryIndex,
            sortColumn, sortDirection, enableFilterRow, filters, rowIndex, selectedRow, selectedColumn, isUpdating, isExporting, isLoading, areAllProjectsLoaded
        } = this.state;
        const rows = this.getFilteredRows();
        const isCommercial = this.props.activeOrganization?.subscription.shortName == 'Com';

        return (
            <Segment style={{ display: 'flex', flexFlow: 'column', padding: 0, width: '100%', height: '100%' }}>
                <Dimmer active={isUpdating || isExporting} style={StylesUtil.getMapStyles().dimmerStyle}>
                    <Loader content={isExporting ? i18n.t("Export des projets en cours...") : i18n.t("Mise à jour des projets en cours...")} />
                </Dimmer>
                {data?.columns &&
                    <>
                        <h3 style={{ textAlign: 'center', margin: '10px 0 0 0' }}>{i18n.t("Gestion des projets")}</h3>
                        <Menu attached='top' tabular style={{ margin: 0, flexWrap: 'wrap' }}>
                            <Menu.Item>
                                <Form.Field
                                    control={Input} type='number' step='1' placeholder={i18n.t("Numéro de ligne")}
                                    value={rowIndex || ''}
                                    onChange={(e, { value }) => this.setState({ rowIndex: value })}
                                />
                                <Button
                                    className='button--secondary' icon='arrow down' style={{ marginLeft: '10px' }}
                                    onClick={() => { if (this.gridRef.current) this.gridRef.current.scrollToRow(rowIndex - 1) }}
                                />
                            </Menu.Item>
                            <Menu.Item>
                                <Button.Group>
                                    <Button
                                        title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                                        className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                                        onClick={this.toggleFilters}
                                    />
                                    <Button
                                        title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                                        onClick={this.clearFilters} disabled={!this.areFiltersApplied()}
                                    />
                                    <Button
                                        title={i18n.t("Exporter les données")} className='button--secondary' icon='download' style={{ position: 'relative' }}
                                        disabled={!this.props.isOnline} onClick={this.exportXLSX}
                                    />
                                    {!isCommercial && <>
                                        <Button
                                            title={i18n.t("Annuler la dernière modification")} className='button--secondary' icon='undo'
                                            onClick={this.restorePreviousModification} disabled={modificationsHistoryIndex < 1}
                                        />
                                        <Button
                                            title={i18n.t("Rétablir la modification suivante")} className='button--secondary' icon='redo'
                                            disabled={modificationsHistoryIndex === modificationsHistory.length}
                                            onClick={this.restoreNextModification}
                                        />
                                        <Button
                                            title={i18n.t("Valider les modifications")} className='button--secondary' icon='check'
                                            onClick={() => this.handleSubmit(false)} disabled={elementsToModify.length < 1}
                                        />
                                    </>}
                                </Button.Group>
                            </Menu.Item>
                            {!areAllProjectsLoaded &&
                                <div style={{ display: 'flex', alignItems: 'center', color: 'var(--grey-100)' }}>
                                    <Loader active inline size='mini' style={{ marginRight: '5px' }} />
                                    <small>{i18n.t("Chargement des projets en cours ({{step}}/2)...", { step: isLoading ? 0 : 1 })}</small>
                                </div>}
                            <Menu.Item style={{ width: '100%', padding: '1px 0', border: 'none', height: '32px' }} className='full-width-input-item'>
                                {(selectedColumn?.editable === true || (typeof selectedColumn?.editable === 'function' && selectedColumn.editable(selectedRow))) ?
                                    selectedColumn.editor({
                                        row: selectedRow, column: selectedColumn,
                                        onRowChange: this.handleRowChange,
                                        onClose: (commitChanges) => { if (commitChanges) this.handleRowChange(selectedRow) }
                                    })
                                    : <Input disabled placeholder={i18n.t("Sélectionnez une cellule éditable")} />}
                                <div style={{ position: 'absolute', width: '150px', right: 0, paddingRight: '10px', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <span style={{ borderLeft: 'solid 1px var(--grey-100)', paddingLeft: '10px', fontWeight: 'bold' }}>{rows?.length}</span>
                                    {rows?.length !== data?.rows?.length && <span style={{ marginLeft: '4px' }}>{` / ${data?.rows?.length}`}</span>}
                                </div>
                            </Menu.Item>
                        </Menu>
                        <DataGrid
                            ref={this.gridRef} className={this.props.isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                            style={{ flex: '1 1 auto' }}
                            columns={data.columns}
                            rows={rows} rowRenderer={this.rowRenderer}
                            defaultColumnOptions={{ sortable: true, resizable: true }}
                            cellNavigationMode='LOOP_OVER_ROW'
                            sortColumn={sortColumn} sortDirection={sortDirection}
                            onSort={this.handleSort} onFill={this.handleFill} enableFilterRow={enableFilterRow}
                            filters={filters} onFiltersChange={filters => this.setState({ filters: filters })}
                            emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}>
                                <span>{isLoading ? i18n.t("Chargement en cours...") : i18n.t("Aucun résultat trouvé")}</span>
                            </div>)}
                            onSelectedCellChange={({ idx, rowIdx }) => this.setState({ selectedRow: rows[rowIdx], selectedColumn: data.columns[idx] })}
                            onRowsChange={this.handleRowsChange}
                        />
                        <ContextMenu id='grid-context-menu'>
                            <MenuItem onClick={this.resetRow}>{i18n.t("Réinitialiser")}</MenuItem>
                        </ContextMenu>
                    </>}
            </Segment>
        );
    }

    componentDidMount = () => {
        this.gridRef = React.createRef();
        this.loadData();

        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);

    rowRenderer = (props) => {
        return (
            <ContextMenuTrigger id='grid-context-menu' collect={() => ({ rowIdx: props.rowIdx })}>
                <GridRow {...props} />
            </ContextMenuTrigger>
        );
    }

    updateSelectedRow = (row) => this.setState({ selectedRow: row });

    handleRowChange = (row) => {
        const { data, selectedRow } = this.state;
        if (selectedRow) {
            const updatedRows = [...data.rows];
            const index = updatedRows.findIndex(row => row.id === selectedRow.id);
            updatedRows[index] = row;
            this.handleRowsChange(updatedRows);
        }
    };

    handleRowsChange = (newRows) => {
        this.setState(prevState => {
            let rows = prevState.data.rows;
            newRows.forEach(newRow => {
                const index = rows.findIndex(row => row.id === newRow.id);
                rows[index] = newRow;
            });
            return { data: { columns: prevState.data.columns, rows: rows } };
        });
    }

    loadData = async () => {
        let data = {
            columns: [],
            rows: []
        };

        const getEditor = (type, row, column, onRowChange, onClose, config = {}) => {
            const props = {
                elements: this.state.elements, elementsToModify: this.state.elementsToModify, ...config,
                row: row, column: column, onRowChange: onRowChange, onClose: onClose, updateSelectedRow: this.updateSelectedRow,
                pushToModificationsHistory: this.pushToModificationsHistory, changeElementsToModify: this.changeElementsToModify
            };
            switch (type) {
                case 'boolean': return <BooleanEditor {...props} />;
                case 'date': return <DateEditor {...props} />;
                case 'dropdown': return <DropDownEditor {...props} />;
                case 'number': return <NumberEditor {...props} />;
                case 'text': return <TextEditor {...props} />;
                default: return;
            }
        }

        // Définition des colonnes
        const userId = jwtDecode(new Cookies().get('token')).id;
        const isCommercial = this.props.activeOrganization?.subscription.shortName == 'Com';
        data.columns = [
            {
                name: i18n.t("Id"), key: 'id', width: 80, sortable: true, frozen: true,
                formatter: (props) => <div className='disabled'>{props.row.id}</div>,
                filterRenderer: (props) => <NumberFilter p={props} />
            },
            {
                name: i18n.t("Type"), key: 'type', width: 150, sortable: true,
                formatter: (props) => <div className='disabled'>{props.row.type}</div>,
                filterRenderer: (props) => <DropDownFilter p={props} propertyOptions={[{ label: i18n.t("Projet") }, { label: i18n.t("Dossier") }]} />
            },
            {
                name: i18n.t("Dossier parent"), key: 'parentLabel', width: 250, sortable: true,
                formatter: (props) => <div className='disabled'>{props.row.parentLabel}</div>,
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Libellé"), key: 'label', width: 250,
                sortable: true, editable: !isCommercial,
                formatter: (props) => <div className={isCommercial ? 'disabled' : null}>{props.row.label}</div>,
                filterRenderer: (props) => <TextFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('text', row, column, onRowChange, onClose)
            },
            {
                name: i18n.t("Propriétaire"), key: 'owner', width: 300,
                sortable: true, editable: (props) => !isCommercial && props.owner ? true : false,
                formatter: (props) => {
                    const owner = props.row.owner || (props.row.path && this.state.data.rows.find(row => row.id === Number(props.row.path.split('/')[1]))?.owner) || i18n.t("Aucun");
                    return !isCommercial && props.row.userBaseProjects.find(collaborator => collaborator.projectRole.type === 'owner') ? owner : <div className='disabled'>{owner}</div>
                },
                filterRenderer: (props) => <TextFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor(
                    'dropdown', row, column, onRowChange, onClose,
                    { options: row.userBaseProjects.map(c => ({ label: FormattersUtil.formatLastNameAndFirstName(c.user.lastName, c.user.firstName), value: c.user.id })) }
                ),
                editorOptions: { editOnClick: true }
            },
            {
                name: i18n.t("Import en cours"), key: 'isImporting', width: 160,
                sortable: true, editable: (props) => !isCommercial && props.type === 'Projet',
                formatter: (props) => <div className={isCommercial || props.row.type !== 'Projet' ? 'disabled' : null} style={{ alignItems: 'flex-start' }}>
                    {props.row.type === 'Projet' && <>
                        {props.row.isImporting === i18n.t("Oui")
                            ? <Icon name='check' color='green' />
                            : <Icon name='times' color='red' />}
                    </>}
                </div>,
                filterRenderer: (props) => <BooleanFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('boolean', row, column, onRowChange, onClose),
                editorOptions: { editOnClick: true }
            },
            {
                name: i18n.t("Duplication en cours"), key: 'isDuplicating', width: 160,
                sortable: true, editable: (props) => !isCommercial && props.type === 'Projet',
                formatter: (props) => <div className={isCommercial || props.row.type !== 'Projet' ? 'disabled' : null} style={{ alignItems: 'flex-start' }}>
                    {props.row.type === 'Projet' && <>
                        {props.row.isDuplicating === i18n.t("Oui")
                            ? <Icon name='check' color='green' />
                            : <Icon name='times' color='red' />}
                    </>}
                </div>,
                filterRenderer: (props) => <BooleanFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('boolean', row, column, onRowChange, onClose),
                editorOptions: { editOnClick: true }
            },
            {
                name: i18n.t("Supprimé"), key: 'deletionDate', width: 160,
                sortable: true, editable: !isCommercial,
                formatter: (props) => <div className={isCommercial || props.row.type !== 'Projet' ? 'disabled' : null} style={{ alignItems: 'flex-start' }}>
                    {props.row.deletionDate === i18n.t("Oui")
                        ? <Icon name='check' color='green' />
                        : <Icon name='times' color='red' />}
                </div>,
                filterRenderer: (props) => <BooleanFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('boolean', row, column, onRowChange, onClose),
                editorOptions: { editOnClick: true }
            },
            {
                name: i18n.t("Incrément (arbres)"), key: 'treeIncrement', width: 155,
                sortable: true, editable: (props) => !isCommercial && props.type === 'Projet',
                formatter: (props) => <div className={isCommercial || props.row.type !== 'Projet' ? 'disabled' : null}>{props.row.type === 'Projet' ? props.row.treeIncrement : ''}</div>,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: 0, step: 1 })
            },
            {
                name: i18n.t("Incrément (espaces verts)"), key: 'greenSpaceIncrement', width: 200,
                sortable: true, editable: (props) => !isCommercial && props.type === 'Projet',
                formatter: (props) => <div className={isCommercial || props.row.type !== 'Projet' ? 'disabled' : null}>{props.row.type === 'Projet' ? props.row.greenSpaceIncrement : ''}</div>,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: 0, step: 1 })
            },
            {
                name: i18n.t("Incrément (mobilier urbain)"), key: 'furnitureIncrement', width: 215,
                sortable: true, editable: (props) => !isCommercial && props.type === 'Projet',
                formatter: (props) => <div className={isCommercial || props.row.type !== 'Projet' ? 'disabled' : null}>{props.row.type === 'Projet' ? props.row.furnitureIncrement : ''}</div>,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: 0, step: 1 })
            },
            {
                name: i18n.t("Incrément (repères)"), key: 'markerIncrement', width: 160,
                sortable: true, editable: (props) => !isCommercial && props.type === 'Projet',
                formatter: (props) => <div className={isCommercial || props.row.type !== 'Projet' ? 'disabled' : null}>{props.row.type === 'Projet' ? props.row.markerIncrement : ''}</div>,
                filterRenderer: (props) => <NumberFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('number', row, column, onRowChange, onClose, { min: 0, step: 1 })
            },
            {
                name: '', key: 'join', width: 130,
                sortable: true, resizable: false,
                formatter: (props) => {
                    return props.row.deletionDate === i18n.t("Oui") || props.row.userBaseProjects.find(c => c.user.id === userId) || (
                        props.row.path && this.state.data.rows.find(row => row.id === Number(props.row.path.split('/')[1]))?.userBaseProjects.find(c => c.user.id === userId)
                    )
                        ? ''
                        :
                        <Button
                            color='blue' style={{ padding: '6px 20px' }} content={i18n.t("Rejoindre")}
                            loading={this.state.joiningProject === props.row.id} disabled={this.state.joiningProject === props.row.id}
                            onClick={() => this.joinProject(props.row.id)}
                        />
                },
                filterRenderer: (props) => <TextFilter p={props} />
            }
        ];

        const processProjects = (baseProjects => {
            const elements = [];

            baseProjects.filter(baseProject => baseProject.type === 'project').forEach(project => {
                const owner = project.userBaseProjects?.find(up => up.projectRole.type === 'owner')?.user;
                const parentId = project.path?.split('/').filter(id => id).map(id => Number(id)).at(-1);

                data.rows.push({
                    id: project.id,
                    type: i18n.t("Projet"),
                    path: project.path,
                    parentLabel: parentId ? baseProjects.find(bp => bp.id === parentId)?.label || '' : '',
                    label: project.label,
                    owner: owner ? FormattersUtil.formatLastNameAndFirstName(owner.lastName, owner.firstName) : '',
                    isImporting: project.isImporting ? i18n.t("Oui") : i18n.t("Non"),
                    isDuplicating: project.isDuplicating ? i18n.t("Oui") : i18n.t("Non"),
                    deletionDate: project.deletionDate ? i18n.t("Oui") : i18n.t("Non"),
                    treeIncrement: project.treeIncrement,
                    greenSpaceIncrement: project.greenSpaceIncrement,
                    furnitureIncrement: project.furnitureIncrement,
                    markerIncrement: project.markerIncrement,
                    userBaseProjects: project.userBaseProjects || []
                });

                elements.push({
                    id: project.id,
                    type: 'project',
                    label: project.label,
                    owner: owner?.id,
                    isImporting: project.isImporting,
                    isDuplicating: project.isDuplicating,
                    deletionDate: project.deletionDate,
                    treeIncrement: project.treeIncrement,
                    greenSpaceIncrement: project.greenSpaceIncrement,
                    furnitureIncrement: project.furnitureIncrement,
                    markerIncrement: project.markerIncrement
                });
            });

            baseProjects.filter(baseProject => baseProject.type === 'folder').forEach(folder => {
                const owner = folder.userBaseProjects?.find(up => up.projectRole.type === 'owner')?.user;
                const parentId = folder.path?.split('/').filter(id => id).map(id => Number(id)).at(-1);

                data.rows.push({
                    id: folder.id,
                    type: i18n.t("Dossier"),
                    path: folder.path,
                    parentLabel: parentId ? baseProjects.find(bp => bp.id === parentId)?.label || '' : '',
                    label: folder.label,
                    owner: owner ? FormattersUtil.formatLastNameAndFirstName(owner.lastName, owner.firstName) : '',
                    deletionDate: folder.deletionDate ? i18n.t("Oui") : i18n.t("Non"),
                    userBaseProjects: folder.userBaseProjects || []
                });

                elements.push({
                    id: folder.id,
                    type: 'folder',
                    label: folder.label,
                    owner: owner?.id,
                    deletionDate: folder.deletionDate
                });
            });

            const initialOrder = data.rows.map(row => row.id);
            this.setState(prevState => ({
                data,
                elements: [...(prevState.elements || []), ...elements],
                initialOrder: [...(prevState.initialOrder || []), ...initialOrder].toSorted(),
                isLoading: false
            }));
        });


        // Ajout des données
        let nbProjectsToLoad = 500;
        let nbProjectsLoaded = nbProjectsToLoad;
        let nbProjects = await ProjectsService.getNbProjects();
        if (nbProjects >= nbProjectsToLoad) nbProjects -= nbProjectsToLoad;
        else {
            nbProjectsToLoad = nbProjects;
            nbProjectsLoaded = nbProjects;
        }
        const nbRequestToDo = Math.ceil(nbProjects / nbProjectsToLoad);
        ProjectsService.getAllProjects(nbProjectsToLoad).then(processProjects);
        setTimeout(async () => {
            const promises = [];
            for (let i = 0; i < nbRequestToDo; i++) {
                if (nbProjects < nbProjectsToLoad) {
                    nbProjectsToLoad = nbProjects;
                }
                promises.push(ProjectsService.getAllProjects(nbProjectsToLoad, nbProjectsLoaded));
                nbProjectsLoaded += nbProjectsToLoad;
                nbProjects -= nbProjectsToLoad;
            }

            if (promises.length) {
                const baseProjects = await Promise.all(promises);
                processProjects(baseProjects.flat());
            }

            this.setState({ areAllProjectsLoaded: true });
        }, 250);
    }

    // Filtres
    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (const property in this.state.filters)
            if (this.state.filters[property]) filtersApplied = true;
        return filtersApplied;
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow }));
    clearFilters = () => this.setState({ filters: initialFilters });

    getFilteredRows = () => {
        const filters = this.state.filters;
        let rows = [...this.state.data.rows];

        const $ = (str) => FormattersUtil.getNormalizedString(str);
        return rows.filter(r => {
            return !this.state.enableFilterRow || (
                (filters.id ? r.id === Number(filters.id) : true)
                && (filters.parentLabel ? $(r.parentLabel).includes($(filters.parentLabel)) : true)
                && (filters.label ? $(r.label).includes($(filters.label)) : true)
                && (filters.type ? r.type === filters.type : true)
                && (filters.owner ? $(r.owner || (r.path && rows.find(row => row.id === Number(r.path.split('/')[1]))?.owner) || i18n.t("Aucun")).includes($(filters.owner)) : true)
                && (filters.isImporting ? r.isImporting === filters.isImporting : true)
                && (filters.isDuplicating ? r.isDuplicating === filters.isDuplicating : true)
                && (filters.deletionDate ? r.deletionDate === filters.deletionDate : true)
                && (filters.treeIncrement ? r.treeIncrement === Number(filters.treeIncrement) : true)
                && (filters.greenSpaceIncrement ? r.greenSpaceIncrement === Number(filters.greenSpaceIncrement) : true)
                && (filters.furnitureIncrement ? r.furnitureIncrement === Number(filters.furnitureIncrement) : true)
                && (filters.markerIncrement ? r.markerIncrement === Number(filters.markerIncrement) : true)
            );
        });
    }

    // Tri
    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);
    sortRows = () => {
        const sortDirection = this.state.sortDirection;
        let rows = [...this.state.data.rows];
        if (sortDirection === 'NONE') {
            for (let i = 0; i < this.state.initialOrder.length; i++) {
                let temp = rows[i];
                const index = rows.findIndex(row => row.id === this.state.initialOrder[i]);
                rows[i] = rows[index];
                rows[index] = temp;
            }

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: rows
                }
            }));
        } else {
            const sortColumn = this.state.sortColumn;
            if (['id', 'treeIncrement', 'greenSpaceIncrement', 'furnitureIncrement', 'markerIncrement'].includes(sortColumn))
                rows = rows.sort((a, b) => (a[sortColumn] || 0) - (b[sortColumn] || 0));
            else rows = rows.sort((a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: sortDirection === 'DESC' ? rows.reverse() : rows
                }
            }));
        }
    }

    // Gestion des modifications
    changeElementsToModify = (elementsToModify) => this.setState({ elementsToModify: elementsToModify });
    changeElementsToModifyLocally = (id, property, value, elementsToModify) => {
        const index = elementsToModify.findIndex(element => element.id === id);
        elementsToModify[index][property] = value;
        return elementsToModify;
    }

    /*     Historique     */
    pushToModificationsHistory = (modifications) => {
        let modificationsHistory = this.state.modificationsHistory;
        modificationsHistory = modificationsHistory.slice(0, this.state.modificationsHistoryIndex);
        modificationsHistory.push(modifications);
        this.setState(prevState => ({
            modificationsHistory: modificationsHistory,
            modificationsHistoryIndex: prevState.modificationsHistoryIndex + 1
        }));
    }

    handleKeyDown = (e) => {
        if (e.ctrlKey && e.key === 'z') this.restorePreviousModification();
        else if (e.ctrlKey && e.key === 'y') this.restoreNextModification();
        else if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            const { selectedColumn, selectedRow } = this.state;
            if (selectedColumn && selectedRow) navigator.clipboard.writeText(selectedRow[selectedColumn.key] || '');
        }
    }

    restorePreviousModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const previousModification = this.state.modificationsHistory[index - 1];

        if (previousModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { elementsToModify } = this.state;

            let modificationsToCreate = [], previousElementsId = [], previousElementsProperties = [];
            previousModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                previousElementsId.push(elementId);
                previousElementsProperties.push(property);

                let row = data.rows.find(row => row.id === elementId);
                modificationsToCreate.push({ property: property, elementId: elementId, oldValue: row[property] });
                row[property] = oldValue;
                if (row.id === this.state.selectedRow?.id) this.updateSelectedRow(row);
                const value = this.getPropertyValue(property, oldValue);
                elementsToModify = this.changeElementsToModifyLocally(elementId, property, value, elementsToModify);

            });

            let modificationsHistory;
            if (index === this.state.modificationsHistory.length) {
                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory.push(modificationsToCreate);
            } else {
                let actualElementsId = [], actualElementsProperties = [];

                this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
                this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));

                if (JSON.stringify(previousElementsId) !== JSON.stringify(actualElementsId)
                    || JSON.stringify(previousElementsProperties) !== JSON.stringify(actualElementsProperties)) {
                    modificationsHistory = this.state.modificationsHistory;
                    modificationsHistory[index] = modificationsToCreate;
                }
            }

            this.setState(prevState => ({
                data: data,
                elementsToModify: elementsToModify,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index - 1,
            }));
        }
    }

    restoreNextModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const nextModification = this.state.modificationsHistory[index + 1];

        if (nextModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { elementsToModify } = this.state;

            let modificationsToCreate = [], nextElementsId = [], nextElementsProperties = [];
            nextModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                nextElementsId.push(elementId);
                nextElementsProperties.push(property);

                let row = data.rows.find(row => row.id === elementId);
                modificationsToCreate.push({ property: property, elementId: elementId, oldValue: row[property] });
                row[property] = oldValue;
                if (row.id === this.state.selectedRow?.id) this.updateSelectedRow(row);
                const value = this.getPropertyValue(property, oldValue);
                elementsToModify = this.changeElementsToModifyLocally(elementId, property, value, elementsToModify);
            });

            let modificationsHistory, actualElementsId = [], actualElementsProperties = [];

            this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
            this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));

            if (JSON.stringify(nextElementsId) !== JSON.stringify(actualElementsId)
                || nextElementsProperties !== actualElementsProperties) {
                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory[index] = modificationsToCreate;
            }

            if (index === this.state.modificationsHistory.length - 2)
                modificationsHistory = this.state.modificationsHistory.slice(0, this.state.modificationsHistory.length - 1);

            this.setState(prevState => ({
                data: data,
                elementsToModify: elementsToModify,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index + 1
            }));
        }
    }

    getPropertyValue = (property, value) => { // Map les valeurs affichées aux valeurs réelles
        switch (property) {
            case 'isImporting': return value === i18n.t("Oui");
            case 'isDuplicating': return value === i18n.t("Oui");
            case 'deletionDate': return value === i18n.t("Oui");
            default: return value;
        }
    }

    /*     Remplissage     */
    handleFill = ({ columnKey, sourceRow, targetRows }) => {
        let elementsToModify = this.state.elementsToModify;

        let rowsUpdated = false;
        targetRows.forEach(row => {
            rowsUpdated = true;
            const value = this.getPropertyValue(columnKey, sourceRow[columnKey]);;
            const index = elementsToModify.findIndex(element => element.id === row.id);
            if (index === -1) {
                let element = JSON.parse(JSON.stringify(this.state.elements.find(element => element.id === row.id)));
                element[columnKey] = value;
                elementsToModify.push(element);
            } else elementsToModify[index][columnKey] = value;
        });
        if (rowsUpdated) this.changeElementsToModify(elementsToModify);

        let modificationsToCreate = [];
        const newRows = targetRows.map(row => {
            modificationsToCreate.push({ property: columnKey, elementId: row.id, oldValue: row[columnKey] });
            return { ...row, [columnKey]: sourceRow[columnKey] };
        });
        this.pushToModificationsHistory(modificationsToCreate);

        return newRows;
    }

    /*     Réinitialisation     */
    resetRow = (e, { rowIdx }) => {
        let elementsToModify = this.state.elementsToModify;
        let data = {
            columns: [...this.state.data.columns],
            rows: [...this.state.data.rows]
        };

        // On reset les données de la ligne sélectionnée
        let filteredRows = this.getFilteredRows();
        const index = elementsToModify.findIndex(element => element.id === filteredRows[rowIdx].id);
        if (index !== -1) {
            const initialElement = this.state.elements.find(element => filteredRows[rowIdx].id === element.id);
            elementsToModify[index] = JSON.parse(JSON.stringify(initialElement));
            const newDisplayedData = { ...filteredRows[rowIdx], ...this.getRowValue(initialElement) };
            let modificationsToCreate = [];
            let rowIndex = data.rows.findIndex(row => row.id === filteredRows[rowIdx].id);
            for (const property in newDisplayedData)
                if (data.rows[rowIndex][property] !== newDisplayedData[property])
                    modificationsToCreate.push({ property: property, elementId: data.rows[rowIndex].id, oldValue: data.rows[rowIndex][property] });
            if (modificationsToCreate.length > 0)
                this.pushToModificationsHistory(modificationsToCreate);
            data.rows[rowIndex] = newDisplayedData;
            this.updateSelectedRow(newDisplayedData);
        }

        this.setState({
            data: data,
            elementsToModify: elementsToModify
        });
    }

    getRowValue = (element) => {
        return {
            isImporting: element.isImporting ? i18n.t("Oui") : i18n.t("Non"),
            isDuplicating: element.isDuplicating ? i18n.t("Oui") : i18n.t("Non"),
            deletionDate: element.deletionDate ? i18n.t("Oui") : i18n.t("Non")
        };
    }

    joinProject = (baseProjectId) => {
        this.setState({ joiningProject: baseProjectId });
        ProjectsService.joinProject(baseProjectId).then(baseProject => {
            this.setState(({ data }) => {
                data.rows.find(row => row.id === baseProjectId).userBaseProjects.push(
                    baseProject.userBaseProjects.find(up => up.user.id === jwtDecode(new Cookies().get('token')).id)
                );
                return { data: { ...data, columns: data.columns.slice() } }; // Le .slice() permet de refresh les données du tableau (pour pas de bouton sur les enfants)
            });
            if (baseProject && this.props.projects) {
                if (baseProject.type === 'project') ProjectsUtil.assignProjectFormulaVersionsType(baseProject, this.props.formulas);
                const projects = this.props.projects.filter(baseProject => !baseProject.path?.includes(`/${baseProjectId}/`));
                this.props.setProjects([...projects, baseProject]);
            }
            this.setState({ joiningProject: 0 });
        });
    }

    exportXLSX = () => {
        this.setState({ isExporting: true });
        ProjectsService.exportProjectsAsExcel(this.getFilteredRows().map(row => ({ ...row, isDeleted: row.deletionDate }))).then(() => this.setState({ isExporting: false }));
    }

    handleSubmit = () => {
        let { elementsToModify, elements } = this.state;
        let elementsNotToModifyAnymore = [];

        elementsToModify.forEach(elementToModify => {
            const element = this.state.elements.find(element => element.id === elementToModify.id);
            if (JSON.stringify(elementToModify) === JSON.stringify(element))
                elementsNotToModifyAnymore.push(elementToModify);
            else {
                if (!element.deletionDate && elementToModify.deletionDate === true) elementToModify.deletionDate = DatesUtil.getUTCDate();
                else if (elementToModify.deletionDate === false) elementToModify.deletionDate = null;
                if (element.treeIncrement !== elementToModify.treeIncrement) elementToModify.updateTreeIncrement = true;
                if (element.greenSpaceIncrement !== elementToModify.greenSpaceIncrement) elementToModify.updateGreenSpaceIncrement = true;
                if (element.furnitureIncrement !== elementToModify.furnitureIncrement) elementToModify.updateFurnitureIncrement = true;
                if (element.markerIncrement !== elementToModify.markerIncrement) elementToModify.updateMarkerIncrement = true;
            }
        });
        elementsToModify = elementsToModify.filter(element => !elementsNotToModifyAnymore.includes(element));

        if (elementsToModify.length > 0) {
            this.setState({ isUpdating: true });
            const { projects } = this.props;
            ProjectsService.updateBaseProjects(elementsToModify).then(response => {
                if (response === 200) {
                    elementsToModify.forEach(elementToModify => {
                        delete elementToModify.updateTreeIncrement;
                        delete elementToModify.updateGreenSpaceIncrement;
                        delete elementToModify.updateFurnitureIncrement;
                        delete elementToModify.updateMarkerIncrement;
                        const index = elements.findIndex(element => element.id === elementToModify.id);
                        elements[index] = elementToModify;

                        if (projects) {
                            const project = projects.find(project => project.id === elementToModify.id);
                            if (project) {
                                project.label = elementToModify.label;
                                const owner = project.userBaseProjects.find(ubp => ubp.projectRole.type === 'owner');
                                if (owner.id !== elementToModify.owner) {
                                    const ownerRole = project.projectRoles.find(projectRole => projectRole.type === 'owner');
                                    const managerRole = project.projectRoles.find(projectRole => projectRole.type === 'manager');
                                    const newOwner = project.userBaseProjects.find(ubp => ubp.userId === elementToModify.owner);
                                    owner.projectRole = managerRole; owner.projectRoleId = managerRole.id;
                                    newOwner.projectRole = ownerRole; newOwner.projectRoleId = ownerRole.id;
                                }
                            }
                        }
                    });
                    if (projects) this.props.setProjects([...projects]);
                    this.setState({ elements: elements, elementsToModify: [], modificationsHistory: [], modificationsHistoryIndex: 0, isUpdating: false, });
                }
            });
        } else {
            this.setState({ elementsToModify: [], modificationsHistory: [], modificationsHistoryIndex: 0 });
            showToast('users_updated');
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme,
        projects: state.projects,
        formulas: state.formulas,
        activeOrganization: state.activeOrganization,
        isOnline: state.isOnline
    };
};

const mapDispatchToProps = {
    setProjects
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTable);