import React, { Component } from 'react';
// Composants
import DatePicker from '../../Utils/DatePicker';
import { Button, Checkbox, Form, Grid, Input, Message, Select } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfoIcon from '../../Utils/InfoIcon';
// Librairies
import i18n from '../../../locales/i18n';
import { connect } from 'react-redux';
import { setProjectActions } from '../../../actionCreators/projectsActions';
import { addDays, isSameDay } from 'date-fns';
import { isMobileOnly, isTablet } from 'react-device-detect';
import { faCheck, faTimes, faLink, faWarning } from '@fortawesome/pro-solid-svg-icons';
// Services
import ActionsService from '../../../services/ActionsService';
// Utils
import ActionsUtil from '../../../utils/ActionsUtil';
import WebSocketUtil from '../../../utils/WebSocketUtil';
import FormattersUtil from '../../../utils/FormattersUtil';
import DatesUtil from '../../../utils/DatesUtil';

const initialNewProjectAction = {
    action: null,
    actionId: 0,
    startDate: null,
    endDate: null,
    isUrgent: false,
    recurrenceValue: 1,
    recurrenceUnit: 'Années',
    projectActionElements: []
}

const initialError = {
    hidden: true,
    messages: [],
    actionId: false,
    startDate: false,
    endDate: false
}

class PACreationForm extends Component {
    state = {
        actionList: [],
        newProjectAction: { ...initialNewProjectAction, projectId: this.props.project.id },
        recurrence: false, // Bouton permettant d'activer les récurrences lors de la création d'un projectAction
        error: initialError,
        isLoading: false
    }

    render() {
        const { selectElementsToLink } = this.props;
        const { newProjectAction, actionList, recurrence, error, isLoading } = this.state;
        // Date de début
        let maxDateDP;
        if (newProjectAction.endDate) maxDateDP = new Date(newProjectAction.endDate.valueOf());
        // Date de fin
        let minDateDP;
        if (newProjectAction.startDate) {
            minDateDP = new Date(newProjectAction.startDate.valueOf());
            if (recurrence) minDateDP = addDays(minDateDP, 1);
        }
        const recurrenceUnits = [
            { text: i18n.t("Jours"), value: 'Jours' },
            { text: i18n.t("Semaines"), value: 'Semaines' },
            { text: i18n.t("Mois"), value: 'Mois' },
            { text: i18n.t("Ans"), value: 'Années' }
        ];
        let recurrenceBtnDisabled = false;
        if (newProjectAction && newProjectAction.actionId && newProjectAction.actionId !== '') {
            const action = this.props.actions.find(x => x.id === newProjectAction.actionId);
            if (action) recurrenceBtnDisabled = !action.recurrencesAllowed;
            if (recurrenceBtnDisabled && recurrence) {
                this.setState(prevState => ({
                    recurrence: false,
                    newProjectAction: {
                        ...prevState.newProjectAction,
                        endDate: null
                    }
                }));
            }
        }

        const isValid = !newProjectAction.actionId || (!recurrence && !newProjectAction.startDate) || (
            recurrence && (!newProjectAction.startDate || !newProjectAction.endDate || parseInt(newProjectAction.recurrenceValue) <= 0 ||
                error.actionId || error.startDate || error.endDate || error.recurrence)
        ) ? false : true;

        const category = this.props.category.toLowerCase() === 'arbre' ? i18n.t("arbres")
            : this.props.category.toLowerCase() === 'espace vert' ? i18n.t("espaces verts")
                : i18n.t("mobiliers");

        return (
            <Form onSubmit={this.handleSubmit} loading={isLoading} error>
                <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                    <Grid.Row columns={isMobileOnly ? 1 : isTablet ? 2 : 3} style={{ padding: 0, marginBottom: '5px' }}>
                        <Grid.Column>
                            <h3>{i18n.t("Nouvelle action ({{category}})", { category })}</h3>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={isMobileOnly ? 1 : isTablet ? 2 : 3} style={{ padding: 0 }}>
                        <Grid.Column>
                            <Grid.Row>
                                <Grid.Column computer={8} tablet={8} mobile={16}>
                                    <Form.Field
                                        control={Select} options={actionList} search={FormattersUtil.searchList} clearable
                                        name='actionId' value={newProjectAction.actionId || ''}
                                        label={`${i18n.t("Action")} : `} placeholder={i18n.t("Sélectionnez une action")}
                                        selectOnBlur={false} error={error.actionId} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                        onChange={this.handleActionChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row style={{ height: '59.59px', display: 'flex', alignItems: 'center' }}>
                                <Grid.Column computer={8} tablet={8} mobile={16}>
                                    <Checkbox label={i18n.t("Action récurrente")} id='1EVc8sib' checked={recurrence} disabled={recurrenceBtnDisabled} onChange={this.handleRecurrenceChange} />
                                    <Checkbox label={i18n.t("Action urgente")} name='isUrgent' checked={newProjectAction.isUrgent} style={{ marginLeft: '10px' }} onChange={this.handleCheckboxChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Column>
                            <Grid.Row>
                                <Grid.Column>
                                    <DatePicker
                                        label={`${!recurrence ? i18n.t("Date de réalisation") : i18n.t("Date de début")} :`} name='startDate' value={newProjectAction.startDate}
                                        maxDate={maxDateDP} error={error.startDate} onChange={this.handleDateChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {(recurrence || selectElementsToLink) &&
                                <Grid.Row>
                                    <Grid.Column>
                                        {recurrence &&
                                            <DatePicker
                                                label={`${i18n.t("Date de fin")} :`} name='endDate' value={newProjectAction.endDate} style={{ marginTop: '5px' }}
                                                minDate={minDateDP} error={error.endDate} onChange={this.handleDateChange}
                                            />}
                                        {selectElementsToLink &&
                                            <Button className='form-button' type='button' color='blue' disabled={!newProjectAction.actionId} onClick={() => selectElementsToLink(newProjectAction)} style={{ marginTop: '10px' }}>
                                                <FontAwesomeIcon icon={faLink} style={{ marginRight: '10px' }} />{i18n.t("Sélectionner les éléments ({{nbElements}})", { nbElements: newProjectAction.projectActionElements.length })}
                                            </Button>}
                                    </Grid.Column>
                                </Grid.Row>}
                        </Grid.Column>
                        {recurrence &&
                            <Grid.Column>
                                <Grid.Row>
                                    <Grid.Column computer={16} tablet={16} mobile={16}>
                                        <span className='field' style={{ flexGrow: 0, fontSize: '.92857143em', fontWeight: 700 }}>
                                            {i18n.t("Récurrence")} :
                                        </span>
                                        <Form.Group widths='equal'>
                                            <Form.Field
                                                control={Input} fluid disabled
                                                type='text' value={newProjectAction.recurrenceUnit !== 'Semaines' ? i18n.t("Tous les") : i18n.t("Toutes les")}
                                            />
                                            <Form.Field
                                                control={Input} fluid type='number' step='1' error={error.recurrence}
                                                name='recurrenceValue' value={newProjectAction.recurrenceValue || ''}
                                                onChange={this.handleChange}
                                            />
                                            <Form.Field
                                                control={Select} options={recurrenceUnits} fluid selectOnBlur={false}
                                                name='recurrenceUnit' value={newProjectAction.recurrenceUnit}
                                                onChange={this.handleChange}
                                            />
                                        </Form.Group>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid.Column>}
                    </Grid.Row>
                    {!error.hidden &&
                        <Grid.Row>
                            <Grid.Column>
                                <Message
                                    error hidden={error.hidden} style={{ textAlign: 'left' }}
                                    header='Erreur' list={error.messages}
                                />
                            </Grid.Column>
                        </Grid.Row>}
                    <Grid.Row>
                        <Grid.Column style={!isMobileOnly ? { display: 'flex', gap: '5px' } : {}}>
                            <Button className='form-button' type='button' color='red' onClick={this.props.cancel}>
                                <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                            </Button>
                            <Button className='form-button' type='submit' color='green' id='rigRXCCA' disabled={!isValid || isLoading || (selectElementsToLink && !newProjectAction.projectActionElements.length)}>
                                <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                            </Button>
                            {this.checkIfProjectActionExists() &&
                                <div className='form-button' style={{ display: 'flex', alignItems: 'center', justifyContent: isMobileOnly ? 'center' : 'flex-start', borderRadius: '5px', backgroundColor: 'var(--yellow-100)', padding: isMobileOnly ? '10px' : '0 10px' }}>
                                    <FontAwesomeIcon icon={faWarning} style={{ marginRight: '10px' }} />
                                    {i18n.t("Une action similaire existe déjà")}
                                </div>}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        );
    }

    componentDidMount = () => {
        const { projectAction } = this.props;
        if (this.state.actionList.length === 0) this.createActionList(); // Chargement de la liste des actions disponibles
        if (projectAction)
            this.setState({
                newProjectAction: projectAction,
                recurrence: projectAction.startDate && projectAction.endDate && JSON.stringify(projectAction.startDate) !== JSON.stringify(projectAction.endDate)
            });
    }

    componentDidUpdate = (prevProps) => {
        const actionToAdd = this.props.actionToAdd;
        const prevActionToAdd = prevProps.actionToAdd;

        if (actionToAdd && prevActionToAdd && actionToAdd.id !== prevActionToAdd.id)
            this.setState(prevState => ({
                newProjectAction: {
                    ...prevState.newProjectAction,
                    action: actionToAdd,
                    actionId: actionToAdd.id
                }
            }));

        if (prevProps.projectAction && this.props.projectAction && (JSON.stringify(prevProps.projectAction.projectActionElements) !== JSON.stringify(this.props.projectAction.projectActionElements)))
            this.setState(prevState => ({ newProjectAction: { ...prevState.newProjectAction, projectActionElements: this.props.projectAction.projectActionElements } }));
        if (prevProps.projectAction && !this.props.projectAction) this.setState({ newProjectAction: initialNewProjectAction });

        if (prevProps.category !== this.props.category) this.createActionList();

        if (prevProps.startDate !== this.props.startDate) this.setState(prevState => ({ newProjectAction: { ...prevState.newProjectAction, startDate: this.props.startDate } }));
    }

    createActionList = () => {
        let actions = this.props.actions.filter(x => x.category === this.props.category);
        actions = actions.map(x => { // On map les actions pour qu'elles soient utilisables par le Select
            return { text: <span>{x.label}{x.definition && <InfoIcon content={x.definition} iconStyle={{ float: 'right', marginLeft: '5px' }} />}</span>, rawText: x.label, value: x.id }
        });

        actions = FormattersUtil.sortByAlphabeticalOrder(actions);
        this.setState({ actionList: actions }); // Options du Select
    }

    handleChange = (_, { name, value }) => {
        if (value === '') value = 0;
        this.setState(prevState => ({ newProjectAction: { ...prevState.newProjectAction, [name]: value } }));
    }

    handleCheckboxChange = (_, { name, checked }) => {
        this.setState(prevState => ({ newProjectAction: { ...prevState.newProjectAction, [name]: checked } }));
    }

    handleActionChange = (_, { name, value }) => {
        const action = this.props.actions.find(action => action.id === value);
        this.setState({
            newProjectAction: { ...this.state.newProjectAction, [name]: value, action },
            error: { ...this.state.error, [name]: false }
        });
    }

    handleRecurrenceChange = (_, { checked }) => this.setState({ recurrence: checked, endDate: null });

    handleDateChange = (_, { name, value }) => {
        let error = false; let hidden = true;
        let messages = [];
        const addError = (message) => {
            value = null; error = true; hidden = false;
            messages.push(message);
        }

        if (value && value.getFullYear().toString().length !== 4)
            addError(i18n.t("Veuillez saisir une date valide"));
        else if (name === 'startDate' && this.state.newProjectAction.endDate && value > this.state.newProjectAction.endDate)
            addError(i18n.t("Veuillez saisir une date de début valide"));
        else if (name === 'endDate' && this.state.newProjectAction.startDate && this.state.newProjectAction.startDate > value)
            addError(i18n.t("Veuillez saisir une date de fin valide"));

        this.setState({
            newProjectAction: { ...this.state.newProjectAction, [name]: value },
            error: { ...this.state.error, [name]: error, messages: messages, hidden: hidden }
        });
    }

    handleSubmit = () => {
        const stateNewProjectAction = this.state.newProjectAction;

        let flag = true, actionError = false, recurrenceError = false, messagesError = [];
        if (!stateNewProjectAction.actionId) {
            messagesError = [...(messagesError || []), i18n.t("Veuillez sélectionner une action")];
            actionError = true;
            flag = false;
        }

        if (parseInt(stateNewProjectAction.recurrenceValue) <= 0) {
            messagesError = [...(messagesError || []), i18n.t("Veuillez sélectionner une récurrence valide")];
            recurrenceError = true;
            flag = false;
        }

        if (flag) {
            let newProjectAction = {
                projectId: stateNewProjectAction.projectId,
                actionId: stateNewProjectAction.actionId,
                action: stateNewProjectAction.action,
                startDate: DatesUtil.getUTCDate(stateNewProjectAction.startDate),
                endDate: this.state.recurrence ? DatesUtil.getUTCDate(stateNewProjectAction.endDate) : DatesUtil.getUTCDate(stateNewProjectAction.startDate),
                projectActionElements: stateNewProjectAction.projectActionElements || [],
                isUrgent: stateNewProjectAction.isUrgent
            };

            newProjectAction.recurrence = this.state.recurrence
                ? stateNewProjectAction.recurrenceValue + ' - ' + stateNewProjectAction.recurrenceUnit
                : '1 - Années';

            this.setState({ isLoading: true });
            if (this.props.autoLink) newProjectAction.projectActionElements = [{ elementId: this.props.layer[0].feature.id, elementType: this.props.layer[0].feature.properties.category }]
            ActionsService.addProjectAction(newProjectAction, this.props.project.id).then(response => { // On ajoute l'actionId à la liste d'actions et dans la DB
                if (response) {
                    // Mapping des actions
                    response.action = this.props.actions.find(x => x.id === response.actionId);
                    WebSocketUtil.sendProjectActions(this.props.webSocketHubs, this.props.project.id, [response]);
                    // On ajoute l'action dans redux
                    const projectActions = ActionsUtil.pushProjectActionElements(response, this.props.projectActions);
                    this.props.setProjectActions(projectActions);
                    this.props.submit(response);
                }
                this.setState({
                    newProjectAction: { ...initialNewProjectAction, projectId: this.props.project.id },
                    recurrence: false,
                    error: initialError,
                    isLoading: false
                });
            });
        } else {
            this.setState(prevState => ({ // On affiche les messages d'erreurs correspondants
                error: {
                    ...prevState.error,
                    hidden: false,
                    messages: messagesError,
                    actionId: actionError,
                    recurrence: recurrenceError
                }
            }));
        }
    }

    checkIfProjectActionExists = () => {
        const { projectActions } = this.props;
        const { newProjectAction, recurrence } = this.state;
        const { actionId, startDate, endDate, recurrenceValue, recurrenceUnit, isUrgent } = newProjectAction;

        if (!actionId || !startDate || (recurrence && !endDate)) return false;
        return !recurrence ? projectActions.find(pa => pa.actionId === actionId && isSameDay(new Date(pa.startDate), startDate) && isSameDay(new Date(pa.endDate), startDate) && pa.isUrgent === isUrgent)
            : projectActions.find(pa => pa.actionId === actionId && isSameDay(new Date(pa.startDate), startDate) && isSameDay(new Date(pa.endDate), endDate) && pa.recurrence === `${recurrenceValue} - ${recurrenceUnit}` && pa.isUrgent === isUrgent);
    }
}

const mapStateToProps = (state) => {
    return {
        actions: state.actions,
        projectActions: state.projectActions,
        project: state.project,
        editedProperties: state.editedProperties,
        webSocketHubs: state.webSocketHubs,
        layer: state.layer
    };
};

const mapDispatchToProps = {
    setProjectActions
}


export default connect(mapStateToProps, mapDispatchToProps)(PACreationForm);