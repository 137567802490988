import React, { Component } from 'react';
// Composants
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import ExtraStep from '../ExtraStep';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCheck, faEye, faMapMarkedAlt, faPenField, faTachometerAlt, faTh, faTimes, faTools, faTrash, faTrees } from '@fortawesome/pro-solid-svg-icons';
import { Form, Button, Message, Segment, Grid, Input, Select } from 'semantic-ui-react';
import InfoIcon from '../../../Utils/InfoIcon';
import DatePicker from '../../../Utils/DatePicker';
import FormMemberList from '../../../Lists/FormMemberList';
// Librairies
import L from 'leaflet';
import { isMobileOnly, isMobile } from 'react-device-detect';
import { v4 as uuidv4, validate } from 'uuid';
import i18n from '../../../../locales/i18n';
import { length, lineString } from '@turf/turf';
// Redux
import { connect } from 'react-redux';
import { setEditedProperties, unlockEditedProperties, setExitFormWithChanges } from '../../../../actionCreators/componentsActions';
import { setPlace, setElementHistory, setPhotosGalleries, setFilesGalleries, setLayer } from '../../../../actionCreators/elementsActions';
import { setProject } from '../../../../actionCreators/projectsActions';
// Services
import ProjectsService from '../../../../services/ProjectsService';
import LocationsService from '../../../../services/LocationsService';
import GeoJsonUtil from '../../../../utils/GeoJsonUtil';
import GreenSpacesService from '../../../../services/GreenSpacesService';
// Utils
import { showToast } from '../../../../utils/ToastsUtil';
import UpdatesUtil from '../../../../utils/UpdatesUtil';
import ProjectsUtil from '../../../../utils/ProjectsUtil';
import WebSocketUtil from '../../../../utils/WebSocketUtil';
import TooltipsUtil from '../../../../utils/TooltipsUtil';
import FormattersUtil from '../../../../utils/FormattersUtil';
import FieldsUtil from '../../../../utils/FieldsUtil';

const initialError = {
    hidden: true,
    messages: [],
    observation: false,
    spaceType: false,
    averageHeight: false,
    averageCircumference: false,
    averageCrownDiameter: false
}

const initialState = {
    properties: {
        category: 'Espace vert',
        place: '',
        placeExtra: null,
        customReference: null,
        tagId: [],
        spaceFunctionId: 0,
        spaceTypeCategoryId: 0,
        spaceTypeId: 0,
        dominantCompositionId: 0,
        detailedComposition: null,
        managementClassId: 0,
        annualMaintenanceFrequency: 0,
        isTreeBase: false,
        observation: null,
        nbTrees: 0,
        density: 0,
        distanceBetweenTrunks: 0,
        dominantEssenceId: 0,
        averageHealthReviewId: 0,
        averageHeight: 0,
        averageCircumference: 0,
        averageCrownDiameter: 0,
        customFields: {}
    },
    requiredFields: null,
    projectTags: null,
    projectTagsToAdd: [],
    isLoading: false,
    placeLoaded: false,
    error: initialError
}

class GreenSpaceForm extends Component {
    state = this.props.layer[0]
        ? { ...initialState, id: this.props.layer[0].feature.id }
        : initialState;

    render() {
        if (this.state.requiredFields) {
            this.checkStepsToAvoid();

            const { isLoading, requiredFields, error, id, properties, projectTags } = this.state;
            // Props à passer aux différentes étapes
            const stepProps = {
                requiredFields: requiredFields,
                properties: this.state.properties,
                error: this.state.error,
                isTabletLandscape: this.props.isTabletLandscape,
                orderConfig: this.props.project.orderConfig,
                handleChange: this.handleChange,
                completeProperties: this.completeProperties,
                renderFields: this.renderFields
            }

            const isSubmitDisabled = this.props.layer?.[0] ? !this.arePropertiesModified(this.props.layer[0].feature.properties, this.state.properties) : false;

            return (
                <Form className='modal-content' onSubmit={this.handleSubmit} loading={isLoading} error>
                    <Grid style={{ margin: 0 }}>
                        <Grid.Column textAlign='center' style={{ padding: '5px', paddingTop: 0 }}>
                            <Button.Group size='tiny' id='anchor-buttons'>
                                {this.renderAnchorButtons()}
                            </Button.Group>
                            {id &&
                                <div style={{ position: 'absolute', right: 0, top: '3px' }}>
                                    <FormMemberList
                                        id={`green-space-form-${this.props.project.id}-${id}`} stateToSend={properties} setIsLoading={(isLoading) => this.setState({ isLoading })}
                                        updateForm={(properties) => this.setState({ isLoading: false, properties })}
                                    />
                                </div>}
                        </Grid.Column>
                    </Grid>
                    <Segment className='modal-content-body' style={{ marginTop: 0, marginBottom: isMobile ? 0 : null, paddingTop: 0, paddingBottom: '5px', display: 'flex', flexDirection: 'column' }}>
                        {!this.stepsToAvoid.includes(1) &&
                            <Step1
                                {...stepProps} handleCheckboxChange={this.handleCheckboxChange} placeLoaded={this.state.placeLoaded}
                                projectTags={projectTags} handleTagsChange={this.handleTagsChange} handleAddTag={this.handleAddTag}
                            />}
                        {!this.stepsToAvoid.includes(2) &&
                            <Step2 {...stepProps} />}
                        {!this.stepsToAvoid.includes(3) &&
                            <Step3
                                {...stepProps} handleDominantCompositionChange={this.handleDominantCompositionChange}
                                handleManagementClassChange={this.handleManagementClassChange} handleNumberPropertyBlur={this.handleNumberPropertyBlur}
                            />}
                        {!this.stepsToAvoid.includes(4) &&
                            <Step4 {...stepProps} handleSpaceTypeChange={this.handleSpaceTypeChange} handleSpaceTypeCategoryChange={this.handleSpaceTypeCategoryChange} />}
                        {!this.stepsToAvoid.includes(5) &&
                            <Step5 {...stepProps} handleDominantEssenceChange={this.handleDominantEssenceChange} handleDensityChange={this.handleDensityChange} />}
                        {!this.stepsToAvoid.includes(6) &&
                            <ExtraStep {...stepProps} category='Espace vert' handleCustomFieldChange={this.handleCustomFieldChange} deleteCustomField={this.deleteCustomField} />}
                    </Segment>
                    <Message
                        error hidden={error.hidden} onDismiss={() => this.setState({ error: initialError })}
                        header={i18n.t("Erreur")} list={error.messages}
                        style={{ textAlign: 'left', overflow: 'auto', marginBottom: isMobileOnly ? 0 : '10px', marginTop: isMobileOnly ? '10px' : 0 }}
                    />
                    {!this.props.isKeyboardOpen &&
                        <div className='modal-content-footer'>
                            {!id ?
                                <>
                                    {isMobile ?
                                        <Button.Group widths={4}>
                                            {this.renderCancelButton()}
                                            {this.renderSubmitButton()}
                                        </Button.Group>
                                        :
                                        <>
                                            {this.renderCancelButton()}
                                            {this.renderSubmitButton()}
                                        </>}
                                </>
                                :
                                <>
                                    {isMobile ?
                                        <>
                                            <Button.Group widths={4}>
                                                {this.props.logged && this.renderDeleteButton()}
                                                {this.renderSubmitButton(isSubmitDisabled)}
                                            </Button.Group>
                                        </>
                                        :
                                        this.renderSubmitButton(isSubmitDisabled)}
                                </>}
                            {!isMobile && id && this.renderDeleteButton()}
                        </div>}
                </Form >
            );
        } else return (<p>{i18n.t("Chargement...")}</p>);
    }

    componentDidMount = () => this.setInitialState();
    componentDidUpdate = (prevProps) => { // Permet d'update les infos lorsqu'on passe à l'espace vert suivant ou précédent dans un projet 
        if (this.props.layer[0] && this.props.layer[0].feature.id !== this.state.id) {
            const { projectTags, ...state } = initialState;
            this.setState({ ...state, id: this.props.layer[0].feature.id }, this.setInitialState());
        } else if (this.props.project && JSON.stringify(prevProps.project.tags) !== JSON.stringify(this.props.project.tags))
            this.setState({ projectTags: this.props.project.tags });
    }

    componentWillUnmount = () => {
        // Si les propriétés du state local sont différentes de celles présentes dans le store Redux, on les sauvegarde
        if (JSON.stringify(this.state.properties) !== JSON.stringify(this.props.editedProperties))
            this.props.setEditedProperties(this.addedElement ? null : this.state.properties);
        if (this.props.webSocketHubs?.formsHub) this.props.webSocketHubs.formsHub.stop();
        this.props.setExitFormWithChanges(null);
    }

    renderFields = (category) => {
        const { project, activeOrganization } = this.props;
        const projectSubscription = project.organization.subscription;
        const areCustomFieldsAvailable = (projectSubscription || activeOrganization.subscription).customFields;
        if (!areCustomFieldsAvailable) return null;

        const projectCustomFields = [...(this.props.project.projectCustomFields || [])].sort((a, b) => a.order - b.order);
        const pcfColumns = projectCustomFields.filter(pcf => pcf.fieldCategoryId === category.id).map(projectCustomField => {
            const customField = this.props.customFields.find(cf => cf.id === projectCustomField.customFieldId);
            const value = this.state.properties.customFields?.[customField?.id] || null;

            return customField?.category === 'Espace vert' && customField.type !== 'formula' ? (
                <Grid.Column key={customField.id} computer={8} table={8} mobile={16}>
                    {['text', 'url', 'number'].includes(customField.type) ?
                        <Form.Field
                            control={Input} type={customField.type} min={customField.min} max={customField.max} step={customField.step || 1} placeholder={i18n.t("Indiquez une valeur")}
                            label={<label>{customField.label + (['number', 'formula'].includes(customField.type) && customField.unit?.trim() ? ` (${customField.unit})` : '')}{customField.description?.trim() ? <InfoIcon content={customField.description} iconStyle={{ marginLeft: '6px' }} /> : ''} :</label>}
                            name={customField.id} value={value || ''} onChange={this.handleCustomFieldChange}
                        />
                        : customField.type === 'boolean' ?
                            <Form.Field
                                control={Select} placeholder={i18n.t("Sélectionnez une valeur")} selectOnBlur={false} clearable
                                label={<label>{customField.label}{customField.description?.trim() ? <InfoIcon content={customField.description} iconStyle={{ marginLeft: '6px' }} /> : ''} :</label>}
                                name={customField.id} options={[{ text: i18n.t("Oui"), value: 'true' }, { text: i18n.t("Non"), value: 'false' }]} value={value || ''}
                                onChange={this.handleCustomFieldChange}
                            />
                            : customField.type === 'date' ?
                                <DatePicker
                                    label={<label>{customField.label}{customField.description?.trim() ? <InfoIcon content={customField.description} iconStyle={{ marginLeft: '6px' }} /> : ''} :</label>}
                                    name={customField.id} value={value ? new Date(value) : ''} style={{ marginTop: '5px' }}
                                    minDate={customField.minDate ? new Date(customField.minDate) : ''} maxDate={customField.maxDate ? new Date(customField.maxDate) : ''}
                                    onChange={this.handleCustomFieldChange}
                                />
                                :
                                <Form.Field
                                    control={Select} placeholder={i18n.t("Sélectionnez une valeur")}
                                    clearable selection={customField.isMultiple} search={FormattersUtil.searchList} selectOnBlur={false} multiple={customField.isMultiple} noResultsMessage={i18n.t("Aucun résultat trouvé")}
                                    label={<label>{customField.label}{customField.description?.trim() ? <InfoIcon content={customField.description} iconStyle={{ marginLeft: '6px' }} /> : ''} :</label>}
                                    name={customField.id} options={customField.dropdownCustomFieldValues.map(dcfv => ({ text: dcfv.label, value: String(dcfv.id) }))} value={value ? (!customField.isMultiple ? value : value.split(',')) : (customField.isMultiple ? [] : '')}
                                    onChange={this.handleCustomFieldChange}
                                />}
                </Grid.Column>
            ) : null;
        }).filter(pcf => pcf);

        return pcfColumns;
    }

    renderAnchorButtons = () => {
        const { project } = this.props;

        const categories = [
            { title: i18n.t("Emplacement"), id: 'cat--site', color: 'var(--yellow-100)', icon: faMapMarkedAlt, step: 1, order: FieldsUtil.getCategoryOrder(project.orderConfig, 'Espace vert', 'Emplacement') },
            { title: i18n.t("Indicateurs"), id: 'cat--indicators', color: 'var(--blue-100)', icon: faTachometerAlt, step: 2, order: FieldsUtil.getCategoryOrder(project.orderConfig, 'Espace vert', 'Indicateurs') },
            { title: i18n.t("Observation"), id: 'cat--observation', color: 'var(--grey-100)', icon: faEye, step: 2, order: FieldsUtil.getCategoryOrder(project.orderConfig, 'Espace vert', 'Observation') },
            { title: i18n.t("Composition"), id: 'cat--composition', color: 'var(--orange-100)', icon: faTh, step: 3, order: FieldsUtil.getCategoryOrder(project.orderConfig, 'Espace vert', 'Composition') },
            { title: i18n.t("Entretien"), id: 'cat--maintenance', color: 'var(--red-100)', icon: faTools, step: 3, order: FieldsUtil.getCategoryOrder(project.orderConfig, 'Espace vert', 'Entretien') },
            { title: i18n.t("Massif arboré"), id: 'cat--wooden-massif', color: 'var(--primary-100)', icon: faTrees, step: 5, order: FieldsUtil.getCategoryOrder(project.orderConfig, 'Espace vert', 'Massif arboré') },
            ...(project?.fieldCategories || [])
                .filter(fieldCategory => fieldCategory.category === 'Espace vert' && project?.projectCustomFields.find(pcf => pcf.fieldCategoryId === fieldCategory.id))
                .map(fieldCategory => ({ title: fieldCategory.label, id: 'cat--' + fieldCategory.id, color: fieldCategory.color, icon: fieldCategory.icon, step: 6, order: FieldsUtil.getCategoryOrder(project.orderConfig, 'Espace vert', `${fieldCategory.id}`) })),
            { title: i18n.t("Champs supplémentaires"), id: 'cat--extra', color: 'var(--grey-60)', icon: faPenField, step: 6, order: 999 }
        ];

        return categories.map(({ title, id, color, icon, step, order }, i) => !this.stepsToAvoid.includes(step) && (
            <Button
                key={i} type='button' style={{ padding: '6px 10px', backgroundColor: color, width: '40px', order }} title={title}
                onClick={() => {
                    const category = document.getElementById(id);
                    const modalBody = document.getElementsByClassName('modal-content-body')[0];
                    if (category && modalBody) modalBody.scrollTo(0, category.offsetTop - 5);
                }}
            >
                <FontAwesomeIcon icon={icon} style={{ fontSize: '12pt' }} />
            </Button>
        )).filter(cat => cat);
    }

    renderBackButton = (isFormButton, disabled, style = null) => (
        <Button
            type='button' className={isFormButton ? 'form-button' : ''} style={style} color='blue'
            disabled={disabled} onClick={this.previousStep}
        >
            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '10px' }} />{!isMobileOnly && i18n.t("Précédent")}
        </Button>
    );

    renderNextButton = (isFormButton, disabled, style = null) => (
        <Button
            id='eRZVB9Zt' type='button' style={style} color='blue' className={isFormButton ? 'form-button' : ''}
            disabled={disabled} onClick={this.nextStep}
        >
            {!isMobileOnly && i18n.t("Suivant")}<FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: !isMobileOnly && '10px' }} />
        </Button>
    );

    renderCancelButton = () => (
        <Button
            type='button' className='form-button' color='red'
            onClick={() => this.props.hideForm(false)}
        >
            <FontAwesomeIcon icon={faTimes} style={{ marginRight: !isMobileOnly && '10px' }} />{!isMobileOnly && i18n.t("Annuler")}
        </Button>
    );

    arePropertiesModified = (oldProperties, newProperties) => {
        if (!oldProperties || !newProperties) return false;
        const properties = Object.keys(initialState.properties);
        for (let i = 0; i < properties.length; i++)
            if (JSON.stringify(oldProperties[properties[i]]) !== JSON.stringify(newProperties[properties[i]]))
                return true;
        return false;
    }

    renderSubmitButton = (disabled = false) => {
        if (!disabled && !this.props.exitFormWithChanges) this.props.setExitFormWithChanges({ submit: this.handleSubmit, cancel: () => this.props.hideForm(false) });
        else if (disabled && this.props.exitFormWithChanges) this.props.setExitFormWithChanges(null);

        return (
            <Button id='I0yG1J1l' type='submit' className='form-button' color='green' disabled={disabled}>
                <FontAwesomeIcon icon={faCheck} style={{ marginRight: !isMobileOnly && '10px' }} />{!isMobileOnly && i18n.t("Valider")}
            </Button>
        );
    }

    renderDeleteButton = () => (
        <Button
            id='D9xBvoqM' type='button' className='form-button' color='red' floated='right'
            onClick={this.handleDelete}
        >
            <FontAwesomeIcon icon={faTrash} style={{ marginRight: !isMobileOnly && '10px' }} />{!isMobileOnly && i18n.t("Supprimer")}
        </Button>
    );

    checkStepsToAvoid = () => {
        this.stepsToAvoid = [];
        const requiredFields = this.state.requiredFields.greenSpaces;

        const checkIfRequired = (properties, fieldCategories, stepNumber) => {
            const isRequired = properties.some(property => requiredFields[property])
                || fieldCategories.some(category => this.renderFields(this.props.defaultFieldCategories.find(dfc => dfc.category === 'Espace vert' && dfc.label === category))?.length > 0);
            if (!isRequired) this.stepsToAvoid.push(stepNumber);
        };

        // On vérifie si au moins un champs de chaque étape est à compléter, sinon on ajoute l'étape dans les étapes à éviter
        checkIfRequired(['isTreeBase', 'place', 'placeExtra', 'customReference', 'tags', 'spaceFunction'], ['Emplacement'], 1);
        checkIfRequired(['surface', 'length', 'observation'], ['Indicateurs', 'Observation'], 2);
        checkIfRequired(['dominantComposition', 'detailedComposition', 'managementClass', 'annualMaintenanceFrequency'], ['Composition', 'Entretien'], 3);
        checkIfRequired(['spaceType'], [], 4);
        checkIfRequired(['dominantEssence', 'averageHealthReview', 'averageHeight', 'averageCircumference', 'averageCrownDiameter', 'density'], ['Massif arboré'], 5);

        if (this.state.properties.dominantCompositionId !== 7) // Massif arboré
            if (!this.stepsToAvoid.includes(5)) this.stepsToAvoid.push(5);
        if (!this.state.id && !this.props.project?.projectCustomFields?.filter(pcf => this.props.customFields?.find(customField => customField.category === 'Espace vert' && customField.id === pcf.customFieldId)).length)
            this.stepsToAvoid.push(6);
    }

    handleNumberPropertyBlur = (name) => {
        if (this.state.properties[name] === '')
            this.setState(prevState => ({
                properties: { ...prevState.properties, [name]: 0 }
            }));
    }

    handleChange = (_, { name, value }) => {
        if (['averageHeight', 'averageCircumference', 'averageCrownDiameter'].includes(name)) value = Number(value);
        if (['averageHeight', 'averageCrownDiameter'].includes(name))
            value = Math.round((value * 100) * 100) / 100;
        if (['spaceFunctionId', 'averageHealthReviewId'].includes(name) && !value) value = 0;
        if (value === '') value = null;

        this.setState(prevState => ({
            properties: { ...prevState.properties, [name]: value },
            error: { ...prevState.error, [name]: false }
        }), () => {
            if (this.props.project && this.state.id) {
                if (name !== 'detailedComposition') WebSocketUtil.updateForm(this.props.webSocketHubs, this.state.id, this.state.properties);
                else {
                    clearTimeout(this.updateFormTimeout);
                    this.updateFormTimeout = setTimeout(() => {
                        this.updateFormTimeout = null;
                        WebSocketUtil.updateForm(this.props.webSocketHubs, this.state.id, this.state.properties);
                    }, 1000);
                }
            }
        });
    }

    handleSpaceTypeCategoryChange = (_, { value }) => {
        if (!value) value = 0;
        const spaceTypeId = this.state.properties.spaceTypeId;
        const availableSpaceTypes = this.props.spaceTypes.filter(spaceType => spaceType.spaceTypeCategoryId === value)?.map(spaceType => spaceType.id) || [];
        this.setState(prevState => ({
            properties: {
                ...prevState.properties,
                spaceTypeCategoryId: value,
                spaceTypeId: availableSpaceTypes.length === 1
                    ? availableSpaceTypes[0]
                    : (!availableSpaceTypes.includes(spaceTypeId) ? 0 : prevState.properties.spaceTypeId)
            }
        }), () => {
            if (this.state.id) WebSocketUtil.updateForm(this.props.webSocketHubs, this.state.id, this.state.properties);
        });
    }

    handleSpaceTypeChange = (_, { value }) => {
        if (!value) value = 0;
        const spaceTypeCategoryId = this.props.spaceTypes.find(st => st.id === value)?.spaceTypeCategoryId;
        this.setState(prevState => ({
            properties: {
                ...prevState.properties,
                spaceTypeCategoryId: spaceTypeCategoryId || prevState.properties.spaceTypeCategoryId,
                spaceTypeId: value
            },
            error: { ...prevState.error, spaceType: false }
        }), () => {
            if (this.state.id)
                WebSocketUtil.updateForm(this.props.webSocketHubs, this.state.id, this.state.properties);
        });
    }

    setAnnualMaintenanceFrequency = (dominantCompositionId, managementClassId) => {
        const annualMaintenanceFrequency = this.props.dominantCompositions
            .find(dominantComposition => dominantComposition.id === dominantCompositionId)
            ?.dominantCompositionManagementClasses
            .find(dominantCompositionManagementClass => dominantCompositionManagementClass.managementClassId === managementClassId)
            ?.recommendedAnnualMaintenanceFrequency
        this.setState(prevState => ({
            properties: { ...prevState.properties, annualMaintenanceFrequency: annualMaintenanceFrequency || 0 },
            error: { ...prevState.error, annualMaintenanceFrequency: false }
        }), () => {
            if (this.state.id)
                WebSocketUtil.updateForm(this.props.webSocketHubs, this.state.id, this.state.properties);
        });
    }

    handleDominantCompositionChange = (_, { value }) => {
        if (!value) value = 0;
        const managementClassId = this.state.properties.managementClassId;
        const dominantComposition = this.props.dominantCompositions.find(dc => dc.id === value);
        const availableManagementClasses = dominantComposition?.dominantCompositionManagementClasses.map(dcmc => dcmc.managementClassId) || [];
        const availableSpaceTypeCategories = dominantComposition?.dominantCompositionSpaceTypeCategories.map(dcstc => dcstc.spaceTypeCategoryId) || [];
        this.setState(prevState => ({
            properties: {
                ...prevState.properties,
                dominantCompositionId: value,
                detailedComposition: [0, -1].includes(value) ? null : prevState.properties.detailedComposition,
                managementClassId: !availableManagementClasses.includes(managementClassId) ? 0 : prevState.properties.managementClassId,
            }
        }), () => {
            const { dominantCompositionId, spaceTypeCategoryId, spaceTypeId } = this.state.properties;
            this.setAnnualMaintenanceFrequency(dominantCompositionId, managementClassId);
            if (dominantCompositionId) {
                if (availableSpaceTypeCategories.length === 1) this.handleSpaceTypeCategoryChange(null, { value: availableSpaceTypeCategories[0] });
                else {
                    const stcId = spaceTypeCategoryId ? spaceTypeCategoryId : this.props.spaceTypes.find(spaceType => spaceType.id === spaceTypeId)?.spaceTypeCategoryId;
                    if (stcId && !availableSpaceTypeCategories.includes(stcId)) this.handleSpaceTypeCategoryChange(null, { value: null });
                }
            }
        });
    }

    handleManagementClassChange = (_, { value }) => {
        if (!value) value = 0;
        this.setState(prevState => ({
            properties: { ...prevState.properties, managementClassId: value }
        }), () => {
            const { dominantCompositionId, managementClassId } = this.state.properties;
            this.setAnnualMaintenanceFrequency(dominantCompositionId, managementClassId);
        });
    }

    handleDominantEssenceChange = (_, { name, value }) => {
        this.setState(prevState => ({
            properties: {
                ...prevState.properties,
                dominantEssence: {
                    id: 0,
                    vernacularName: name === 'vernacularName' ? value || null : prevState.properties.dominantEssence?.vernacularName,
                    gender: name === 'gender' ? value || null : prevState.properties.dominantEssence?.gender,
                    species: name === 'species' ? value || null : (name === 'cultivar' ? prevState.properties.dominantEssence?.species : null),
                    cultivar: name === 'cultivar' ? value || null : null
                },
                dominantEssenceId: 0
            }
        }), () => this.setDominantEssence(name === 'vernacularName'));
    }

    setDominantEssence = (isVernacularName) => { // Si une essence correspondante est trouvée dans la liste d'essences, on met la bonne essence dans le state (avec id etc...)
        const dominantEssence = this.props.essences.find(x =>
            (!isVernacularName
                && x.gender === this.state.properties.dominantEssence.gender
                && x.species === this.state.properties.dominantEssence.species
                && x.cultivar === this.state.properties.dominantEssence.cultivar)
            || (isVernacularName && x.vernacularName === this.state.properties.dominantEssence.vernacularName)
        );

        if (dominantEssence) {
            this.setState(prevState => ({
                properties: {
                    ...prevState.properties,
                    dominantEssence: dominantEssence,
                    dominantEssenceId: dominantEssence.id
                }
            }), () => {
                if (this.props.project && this.state.id)
                    WebSocketUtil.updateForm(this.props.webSocketHubs, this.state.id, this.state.properties);
            });
        }
    }

    handleTagsChange = (_, { value }) => {
        let newValues = [];
        value.forEach(v => {
            if (validate(v))
                newValues.push(v);
        });

        this.setState({ properties: { ...this.state.properties, tagId: newValues } }, () => {
            if (this.state.id)
                WebSocketUtil.updateForm(this.props.webSocketHubs, this.state.id, this.state.properties);
        });
    }

    handleAddTag = (tag) => {
        tag = tag.trim();
        let found = false;
        for (let i = 0; i < this.state.projectTags.length && !found; i++)
            if (this.state.projectTags[i].label.toUpperCase() === tag.toUpperCase()
                && this.state.projectTags[i].category === 'Espace vert')
                found = true;

        if (!found) {
            const id = uuidv4();
            this.setState(prevState => ({
                properties: {
                    ...prevState.properties,
                    tagId: [...prevState.properties.tagId, id]
                },
                projectTags: [...prevState.projectTags, { id: id, label: tag, projectId: this.props.project.id, category: 'Espace vert' }],
                projectTagsToAdd: [...prevState.projectTagsToAdd, { id: id, label: tag, projectId: this.props.project.id, category: 'Espace vert' }]
            }));
        }
    }

    handleCheckboxChange = (e, { name, checked }) => {
        this.setState(prevState => ({ properties: { ...prevState.properties, [name]: checked } }), () => {
            if (this.props.project && this.state.id)
                WebSocketUtil.updateForm(this.props.webSocketHubs, this.state.id, this.state.properties);
        });
    }

    handleDensityChange = (_, { name, value }) => {
        this.setState(prevState => {
            const properties = { ...prevState.properties };
            properties[name] = value;

            if (name !== 'nbTrees')
                properties.nbTrees = name === 'density'
                    ? properties.density / 10000 * properties.surface
                    : properties.surface / Math.pow(properties.distanceBetweenTrunks, 2);
            if (name !== 'density') properties.density = properties.nbTrees / properties.surface * 10000;
            if (name !== 'distanceBetweenTrunks') properties.distanceBetweenTrunks = Math.sqrt(properties.surface / properties.nbTrees);

            properties.nbTrees = Math.round(properties.nbTrees);
            properties.density = Math.round(properties.density * 100) / 100;
            properties.distanceBetweenTrunks = Math.round(properties.distanceBetweenTrunks * 100) / 100;

            return { properties };
        });
    }

    deleteCustomField = (name) => {
        this.setState(prevState => {
            let newState = JSON.parse(JSON.stringify(prevState));
            delete newState.properties.customFields[name];
            newState.properties = { ...newState.properties };
            return { properties: newState.properties };
        }, () => {
            if (this.props.project && this.state.id)
                WebSocketUtil.updateForm(this.props.webSocketHubs, this.state.id, this.state.properties)
        });
    }

    handleCustomFieldChange = (_, { name, value }) => {
        if (value?.toISOString) value = value.toISOString();
        if (Array.isArray(value)) value = value.join(',');
        this.setState(prevState => {
            if (!value) {
                delete prevState.properties.customFields[name];
                prevState.properties = { ...prevState.properties };
            } else prevState.properties = { ...prevState.properties, customFields: { ...prevState.properties.customFields, [name]: value } };
            return { properties: prevState.properties };
        }, () => {
            if (this.props.project && this.state.id)
                WebSocketUtil.updateForm(this.props.webSocketHubs, this.state.id, this.state.properties)
        });
    }

    handleSubmit = () => {
        if (this.verifyProperties()) {
            let properties = JSON.parse(JSON.stringify(this.state.properties));
            delete properties.spaceTypeCategoryId;
            this.props.setPlace(properties.place);
            if (properties.dominantCompositionId !== 7) {
                properties = {
                    ...properties,
                    nbTrees: 0,
                    density: 0,
                    distanceBetweenTrunks: 0,
                    dominantEssenceId: 0,
                    averageHealthReviewId: 0,
                    averageHeight: 0,
                    averageCircumference: 0,
                    averageCrownDiameter: 0
                };
            }

            if (this.state.projectTagsToAdd.length > 0) {
                let project = { ...this.props.project };
                project.tags = this.state.projectTags;
                this.props.setProject(project);
                ProjectsService.addProjectTags(this.state.projectTagsToAdd).then(response => {
                    if (response) {
                        project.tags = response;
                        this.props.setProject(project);
                        WebSocketUtil.sendTags(this.props.webSocketHubs, this.props.project.id, this.state.projectTagsToAdd);
                    }
                });
            }

            if (!this.state.id) {
                const feature = GeoJsonUtil.generatePolygonFeature(properties, this.props.layer.getLatLngs(), this.props.project?.id || 0);

                this.setState({ isLoading: true });
                GreenSpacesService.addGreenSpace(feature, 'adding', this.props.webSocketHubs).finally(() => {
                    this.addedElement = true;
                    this.props.addGreenSpace(this.props.layer, feature, { showContainer: true, showModal: true });
                });
            } else { // Si on met à jour
                if (JSON.stringify(properties) !== JSON.stringify({ ...this.props.layer[0].feature.properties })) {
                    const { layer, elementHistory } = this.props;
                    this.setState({ isLoading: true });
                    UpdatesUtil.updateGreenSpace(layer[0].feature.id, properties, layer, true, this.props.fieldList, this.props.greenSpacesLayer.activeChild, 'updating', layer[0].feature.projectId, { webSocketHubs: this.props.webSocketHubs, thematicMaps: this.props.project.thematicMaps })
                        .then(response => {
                            if (response?.data?.history && elementHistory) {
                                this.props.setElementHistory([...elementHistory, response.data.history]);
                                if (this.props.project) WebSocketUtil.sendElementsHistories(this.props.webSocketHubs, this.props.project.id, [response.data.history]);
                            }
                        })
                        .finally(() => {
                            if (this.props.references.greenSpaces === 'customReference')
                                this.props.layer.forEach(layer => {
                                    TooltipsUtil.setGreenSpaceTooltip(layer.feature.properties.customReference, layer, this.props.referencesLayer, true);
                                });

                            this.setState({ isLoading: false });
                            this.props.setLayer([...this.props.layer]);
                            this.props.updateLegend(i18n.t("Espaces verts"));
                            this.setState({ properties });
                        });
                } else showToast('element_update_not_allowed');
            }
        }
    }

    handleDelete = () => {
        if (!this.props.project || !ProjectsUtil.isElementLocked(this.props.lockedElements, { id: this.state.id }, this.props.project, this.props.projectCollaborators, []))
            this.props.showRemoveForm();
    }

    completeProperties = (newProperties) => {
        this.setState(prevState => ({ properties: { ...prevState.properties, ...newProperties } }));
    }

    verifyProperties = () => {
        let isValid = true;
        const error = {
            messages: [],
            observation: false,
            spaceType: false,
            averageHeight: false,
            averageCircumference: false,
            averageCrownDiameter: false
        };

        const addError = (property, message) => {
            error.messages = [...(error.messages || []), message];
            error[property] = true;
            isValid = false;
        };

        // Étape 2
        if (this.state.properties.observation && this.state.properties.observation.length > 5000)
            addError('observation', i18n.t("L'observation doit être composée de 5000 caractères maximum"));

        // Étape 4
        if (this.state.properties.spaceTypeCategoryId && (!this.state.properties.spaceTypeId || this.state.properties.spaceTypeId === -1)) {
            addError('spaceType', i18n.t("Le type d'espace est obligatoire si une catégorie est sélectionnée"));
        }

        // Étape 5
        if (this.state.properties.dominantCompositionId === 7) {
            if (this.state.requiredFields.greenSpaces.averageHeight &&
                (this.state.properties.averageHeight < 0 || this.state.properties.averageHeight > 15000))
                addError('averageHeight', i18n.t("La hauteur doit être comprise entre 0 et 150m"));
            if (this.state.requiredFields.greenSpaces.averageCircumference &&
                (this.state.properties.averageCircumference < 0 || this.state.properties.averageCircumference > 5000))
                addError('averageCircumference', i18n.t("La circonférence doit être comprise entre 0 et 5000cm"));
            if (this.state.requiredFields.greenSpaces.averageCrownDiameter &&
                (this.state.properties.averageCrownDiameter < 0 || this.state.properties.averageCrownDiameter > 5000))
                addError('averageCrownDiameter', i18n.t("Le diamètre de la couronne doit être compris entre 0 et 50m"));
        }

        if (!isValid) this.setState({ error: { hidden: error.messages.length > 0 ? false : true, ...error } });
        else this.setState({ error: initialError });

        return isValid;
    }

    setInitialState = () => {
        this.props.unlockEditedProperties(); // On établit la possibilité de modifier les propriétés dans Redux
        this.stepsToAvoid = [];

        if (!this.state.requiredFields) {
            const requiredFields = ProjectsUtil.getProjectRequiredFields(this.props.project);

            if (!this.state.projectTags && this.props.project && requiredFields.greenSpaces.tags) {
                ProjectsService.getTags(this.props.project.id).then(response => {
                    let projectTags = [];
                    if (response) {
                        projectTags = response;
                        let project = { ...this.props.project };
                        project.tags = projectTags;
                        this.props.setProject(project);
                    } else if (!this.props.isOnline)
                        projectTags = this.props.project.tags;

                    this.setState({ projectTags: projectTags });
                });
            }

            this.setState({ requiredFields: requiredFields }, () => this.checkStepsToAvoid());
        }

        const layer = Array.isArray(this.props.layer) ? this.props.layer[0] : this.props.layer;
        const feature = layer?.feature ? JSON.parse(JSON.stringify(layer.feature)) : null;
        if (feature?.id) { // Si l'utilisateur est en train d'éditer un espace vert déjà existant
            const properties = this.props.editedProperties ? this.props.editedProperties : feature.properties;
            const dominantEssence = this.props.essences.find(x => x.id === properties.dominantEssenceId);
            this.setState({ properties: { ...properties, dominantEssence }, placeLoaded: true });
        } else { // Si l'utilisateur est en train d'ajouter un nouvel espace vert
            const surface = Math.round(L.GeometryUtil.geodesicArea(this.props.layer._latlngs[0]) * 100) / 100;
            const baseLineLength = this.props.layer.baseLine && Math.round(length(lineString(this.props.layer.baseLine.coordinates)) * 100000) / 100;

            // Attribution du lieu
            const center = this.props.layer.getCenter();
            LocationsService.getPlace(center.lat, center.lng).then(place => { // On rempli le champs 'Lieu' avec le lieu récupérer depuis les coordonnées
                if (place) this.props.setPlace(place);
                this.setState(prevState => ({ properties: { ...prevState.properties, place: place || this.props.place }, placeLoaded: true }));
            });

            this.setState(prevState => ({ properties: { ...prevState.properties, surface, baseLine: this.props.layer.baseLine ? { ...this.props.layer.baseLine, length: baseLineLength } : null } }));
        }
    }
}

const mapStateToProps = (state) => {
    return {
        activeOrganization: state.activeOrganization,
        projectCollaborators: state.projectCollaborators,
        dominantCompositions: state.dominantCompositions,
        managementClasses: state.managementClasses,
        spaceTypeCategories: state.spaceTypeCategories,
        spaceTypes: state.spaceTypes,
        essences: state.essences,
        place: state.place,
        editedProperties: state.editedProperties,
        layer: state.layer,
        rights: state.rights,
        project: state.project,
        isOnline: state.isOnline,
        isDarkTheme: state.isDarkTheme,
        isKeyboardOpen: state.isKeyboardOpen,
        elementHistory: state.elementHistory,
        photosGalleries: state.photosGalleries,
        filesGalleries: state.filesGalleries,
        webSocketHubs: state.webSocketHubs,
        lockedElements: state.lockedElements,
        customFields: state.project
            ? [...state.customFields, ...state.organizationCustomFields || [], ...(state.projectsCustomFields[state.project?.id] || [])]
            : state.customFields,
        exitFormWithChanges: state.exitFormWithChanges,
        defaultFieldCategories: state.defaultFieldCategories
    };
};

const mapDispatchToProps = {
    setEditedProperties,
    unlockEditedProperties,
    setProject,
    setPlace,
    setElementHistory,
    setPhotosGalleries,
    setFilesGalleries,
    setLayer,
    setExitFormWithChanges
}

export default connect(mapStateToProps, mapDispatchToProps)(GreenSpaceForm);