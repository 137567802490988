import React, { Component } from 'react';
// Composants
/*     Editors     */
import BooleanEditor from '../Editors/BooleanEditor';
import DateEditor from '../Editors/DateEditor';
import DropDownEditor from '../Editors/DropDownEditor';
import NumberEditor from '../Editors/NumberEditor';
import TextEditor from '../Editors/TextEditor';
/*     Filters     */
import TextFilter from '../../Tables/Filters/TextFilter';
import BooleanFilter from '../../Tables/Filters/BooleanFilter';
import NumberFilter from '../../Tables/Filters/NumberFilter';
// Librairies
import DataGrid, { Row as GridRow } from 'react-data-grid';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import Cookies from 'universal-cookie';
import { jwtDecode } from 'jwt-decode';
import i18n from '../../../locales/i18n';
import { setHomeInfos } from '../../../actionCreators/usersActions';
// Redux
import { connect } from 'react-redux';
// Ressources
import UrbasenseLogo from '../../../resources/pngs/urbasense.png';
// Semantic UI
import { Dimmer, Loader, Form, Button, Menu, Input, Icon, Segment, Popup, Label, TransitionablePortal, Modal, Message, Select, Checkbox } from 'semantic-ui-react';
// ServicesToUniversalTime
import SubscriptionsService from '../../../services/SubscriptionsService';
import UsersService from '../../../services/UsersService';
// Styles
import '../../../styles/react-contextmenu.css';
import '../../../styles/rdg.css';
// Utils
import { showToast } from '../../../utils/ToastsUtil';
import DatesUtil from '../../../utils/DatesUtil';
import FormattersUtil from '../../../utils/FormattersUtil';
import StylesUtil from '../../../utils/StylesUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightArrowLeft, faCheck, faCrown, faEye, faGear, faTimes, faUser } from '@fortawesome/pro-solid-svg-icons';
import UserProjects from './UserProjects';

const initialFilters = {
    username: '',
    email: '',
    organizations: '',
    emailConfirmed: '',
    registrationDate: '',
    lastLoginDate: '',
    projects: '',
};

const initialError = {
    messages: [],
    sourceUser: false,
    targetUser: false
};

const initialTransfer = {
    sourceUser: null,
    targetUser: null,
    baseProjects: false,
    customCharts: false,
    filterLists: false,
    wmsServices: false,
    thematicMaps: false
};

class UserTable extends Component {
    state = {
        data: {
            columns: [],
            rows: []
        },
        elementsToModify: [],
        elementsToDelete: [],
        modificationsHistory: [],
        modificationsHistoryIndex: 0,
        rowIndex: 0,
        sortColumn: null,
        sortDirection: 'NONE',
        enableFilterRow: false,
        filters: initialFilters,
        isLoading: true,
        isUpdating: false,
        isExporting: false,
        showTransferForm: false,
        isTransferringData: false,
        transferData: initialTransfer,
        error: initialError
    }

    render() {
        const {
            data, elementsToModify, elementsToDelete, modificationsHistory, modificationsHistoryIndex, showTransferForm,
            sortColumn, sortDirection, enableFilterRow, filters, rowIndex, selectedRow, selectedColumn, isUpdating, isExporting, isLoading, projects, transferData,
            isTransferringData, error
        } = this.state;
        const rows = this.getFilteredRows();

        const isCommercial = this.props.activeOrganization?.subscription.shortName == 'Com';
        const userOptions = rows.map(row => ({ text: `${row.username} (${row.email})`, value: row.id }));

        return (
            <Segment style={{ display: 'flex', flexFlow: 'column', padding: 0, width: '100%', height: '100%' }}>
                {showTransferForm &&
                    <Dimmer active style={{ position: 'fixed' }}>
                        <Segment loading={isTransferringData}>
                            <div style={{ display: 'flex', alignItems: 'center', minWidth: '500px' }}>
                                <span style={{ marginRight: '7px', fontWeight: 'bold' }}>{i18n.t("De")} :</span>
                                <Select
                                    placeholder={i18n.t("Sélectionner un utilisateur...")} selectOnBlur={false} search={FormattersUtil.searchList} style={{ flexGrow: 1 }}
                                    options={userOptions.filter(option => option.value !== transferData.targetUser)} value={transferData.sourceUser}
                                    error={error.sourceUser} onChange={(_, { value }) => this.setState(prevState => ({ transferData: { ...prevState.transferData, sourceUser: value }, error: initialError }))}
                                />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', minWidth: '500px', marginTop: '10px' }}>
                                <span style={{ marginRight: '7px', fontWeight: 'bold' }}>{i18n.t("À")} :</span>
                                <Select
                                    placeholder={i18n.t("Sélectionner un utilisateur...")} selectOnBlur={false} search={FormattersUtil.searchList} style={{ flexGrow: 1 }}
                                    options={userOptions.filter(option => option.value !== transferData.sourceUser)} value={transferData.targetUser}
                                    error={error.targetUser} onChange={(_, { value }) => this.setState(prevState => ({ transferData: { ...prevState.transferData, targetUser: value }, error: initialError }))}
                                />
                            </div>
                            <div style={{ marginTop: '10px', textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                <span style={{ fontWeight: 'bold' }}>{i18n.t("Données à transférer")} :</span>
                                <Checkbox style={{ margin: '5px 0 0 10px' }} label={i18n.t("Projets/dossiers")} name='baseProjects' value={transferData.baseProjects} onChange={this.handleCheckboxChange} />
                                <Checkbox style={{ margin: '5px 0 0 10px' }} label={i18n.t("Graphiques personnalisés")} name='customCharts' value={transferData.customCharts} onChange={this.handleCheckboxChange} />
                                <Checkbox style={{ margin: '5px 0 0 10px' }} label={i18n.t("Filtres")} name='filterLists' value={transferData.filterLists} onChange={this.handleCheckboxChange} />
                                <Checkbox style={{ margin: '5px 0 0 10px' }} label={i18n.t("Services WM(T)S")} name='wmsServices' value={transferData.wmsServices} onChange={this.handleCheckboxChange} />
                                <Checkbox style={{ margin: '5px 0 0 10px' }} label={i18n.t("Cartes thématiques")} name='thematicMaps' value={transferData.thematicMaps} onChange={this.handleCheckboxChange} />
                            </div>
                            <Message
                                error style={{ textAlign: 'left' }} hidden={!error.messages.length ? true : false}
                                header={i18n.t("Erreur")} list={error.messages}
                            />
                            <div style={{ display: 'flex', marginTop: '10px' }}>
                                <Button type='button' color='red' onClick={() => this.setState({ showTransferForm: false, error: initialError })}>
                                    <FontAwesomeIcon icon={faTimes} style={{ marginRight: '10px' }} />{i18n.t("Annuler")}
                                </Button>
                                <Button type='submit' color='green' onClick={this.transferData}>
                                    <FontAwesomeIcon icon={faCheck} style={{ marginRight: '10px' }} />{i18n.t("Valider")}
                                </Button>
                            </div>
                        </Segment>
                    </Dimmer>}
                <TransitionablePortal open={projects ? true : false} transition={{ animation: 'fade up', duration: 300 }}>
                    <Modal open mountNode={document.getElementById('admin-panel__grid')} style={{ maxHeight: '80%', overflowY: 'auto' }} onClose={() => { this.setState({ projects: null }) }}>
                        <UserProjects projects={projects} history={this.props.history} />
                    </Modal>
                </TransitionablePortal>
                <Dimmer active={isUpdating || isExporting} style={StylesUtil.getMapStyles().dimmerStyle}>
                    <Loader content={isExporting ? i18n.t("Export des utilisateurs en cours...") : i18n.t("Mise à jour des utilisateurs en cours...")} />
                </Dimmer>
                {data?.columns &&
                    <>
                        <h3 style={{ textAlign: 'center', margin: '10px 0 0 0' }}>{i18n.t("Gestion des utilisateurs")}</h3>
                        <Menu attached='top' tabular style={{ margin: 0, flexWrap: 'wrap' }}>
                            <Menu.Item>
                                <Form.Field
                                    control={Input} type='number' step='1' placeholder={i18n.t("Numéro de ligne")}
                                    value={rowIndex || ''}
                                    onChange={(_, { value }) => this.setState({ rowIndex: value })}
                                />
                                <Button
                                    className='button--secondary' icon='arrow down' style={{ marginLeft: '10px' }}
                                    onClick={() => { if (this.gridRef.current) this.gridRef.current.scrollToRow(rowIndex - 1) }}
                                />
                            </Menu.Item>
                            <Menu.Item>
                                <Button.Group>
                                    <Button
                                        title={enableFilterRow ? i18n.t("Désactiver les filtres") : i18n.t("Activer les filtres")}
                                        className={enableFilterRow ? 'button--secondary' : null} color={!enableFilterRow ? 'grey' : null} icon='filter'
                                        onClick={this.toggleFilters}
                                    />
                                    <Button
                                        title={i18n.t("Réinitialiser les filtres")} className='button--secondary' icon='dont'
                                        onClick={this.clearFilters} disabled={!this.areFiltersApplied()}
                                    />
                                    <Button
                                        title={i18n.t("Exporter les données")} className='button--secondary' icon='download' style={{ position: 'relative' }}
                                        disabled={!this.props.isOnline} onClick={this.exportXLSX}
                                    />
                                    {!isCommercial &&
                                        <>
                                            <Button
                                                title={i18n.t("Annuler la dernière modification")} className='button--secondary' icon='undo'
                                                onClick={this.restorePreviousModification} disabled={modificationsHistoryIndex < 1}
                                            />
                                            <Button
                                                title={i18n.t("Rétablir la modification suivante")} className='button--secondary' icon='redo'
                                                disabled={modificationsHistoryIndex === modificationsHistory.length}
                                                onClick={this.restoreNextModification}
                                            />
                                            <Button
                                                title={i18n.t("Valider les modifications")} className='button--secondary' icon='check'
                                                onClick={() => this.handleSubmit(false)} disabled={elementsToModify.length < 1 && elementsToDelete.length < 1}
                                            />
                                        </>}
                                </Button.Group>
                            </Menu.Item>
                            {/* <Menu.Item>
                                <Button className='button--secondary' style={{ padding: '11px 14px' }} onClick={() => this.setState({ showTransferForm: true })}>
                                    <FontAwesomeIcon icon={faArrowRightArrowLeft} style={{ marginRight: '8px' }} />
                                    {i18n.t("Transfert de données")}
                                </Button>
                            </Menu.Item> */}
                            {elementsToDelete.length > 0 &&
                                <Menu.Item position='right' style={{ padding: '26px 7px 0 0' }}>
                                    <Label color='red' content={`${i18n.t("Éléments supprimés")} : ` + elementsToDelete.length} />
                                    <span style={{ marginLeft: '10px' }}>|</span>
                                </Menu.Item>}
                            <Menu.Item style={{ width: '100%', padding: '1px 0', border: 'none', height: '32px' }} className='full-width-input-item'>
                                {(selectedColumn?.editable === true || (typeof selectedColumn?.editable === 'function' && selectedColumn.editable(selectedRow))) ?
                                    selectedColumn.editor({
                                        row: selectedRow, column: selectedColumn,
                                        onRowChange: this.handleRowChange,
                                        onClose: (commitChanges) => { if (commitChanges) this.handleRowChange(selectedRow) }
                                    })
                                    : <Input disabled placeholder={i18n.t("Sélectionnez une cellule éditable")} />}
                                <div style={{ position: 'absolute', width: '150px', right: 0, paddingRight: '10px', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <span style={{ borderLeft: 'solid 1px var(--grey-100)', paddingLeft: '10px', fontWeight: 'bold' }}>{rows?.length}</span>
                                    {rows?.length !== data?.rows?.length && <span style={{ marginLeft: '4px' }}>{` / ${data?.rows?.length}`}</span>}
                                </div>
                            </Menu.Item>
                        </Menu>
                        <DataGrid
                            ref={this.gridRef} className={this.props.isDarkTheme ? 'rdg-dark' : 'rdg-light'}
                            style={{ flex: '1 1 auto' }}
                            columns={data.columns}
                            rows={rows} rowRenderer={this.rowRenderer}
                            defaultColumnOptions={{ sortable: true, resizable: true }}
                            cellNavigationMode='LOOP_OVER_ROW'
                            sortColumn={sortColumn} sortDirection={sortDirection}
                            onSort={this.handleSort} onFill={this.handleFill} enableFilterRow={enableFilterRow}
                            filters={filters} onFiltersChange={filters => this.setState({ filters: filters })}
                            emptyRowsRenderer={() => (<div style={{ textAlign: 'center' }}>
                                <span>{isLoading ? i18n.t("Chargement en cours...") : i18n.t("Aucun résultat trouvé")}</span>
                            </div>)}
                            onSelectedCellChange={({ idx, rowIdx }) => this.setState({ selectedRow: rows[rowIdx], selectedColumn: data.columns[idx] })}
                            onRowsChange={this.handleRowsChange}
                        />
                        <ContextMenu id='grid-context-menu'>
                            <MenuItem onClick={this.loginAs}>{i18n.t("Se connecter au compte")}</MenuItem>
                            <MenuItem onClick={this.resetRow}>{i18n.t("Réinitialiser")}</MenuItem>
                            <MenuItem onClick={this.deleteRow}>{i18n.t("Supprimer")}</MenuItem>
                        </ContextMenu>
                        <ContextMenu id='grid-context-menu-without-deletion'>
                            <MenuItem onClick={this.loginAs}>{i18n.t("Se connecter au compte")}</MenuItem>
                            <MenuItem onClick={this.resetRow}>{i18n.t("Réinitialiser")}</MenuItem>
                        </ContextMenu>
                        <ContextMenu id='grid-context-menu-reset'>
                            <MenuItem onClick={this.resetRow}>{i18n.t("Réinitialiser")}</MenuItem>
                        </ContextMenu>
                    </>}
            </Segment>
        );
    }

    componentDidMount = () => {
        this.gridRef = React.createRef();
        this.loadData();

        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount = () => document.removeEventListener('keydown', this.handleKeyDown);

    rowRenderer = (props) => {
        const subscription = this.props.activeOrganization?.subscription;
        const contextMenu = subscription?.shortName === 'Dev' ? 'grid-context-menu'
            : subscription?.shortName === 'Admin' ? 'grid-context-menu-without-deletion'
                : 'grid-context-menu-reset';

        return (
            <ContextMenuTrigger id={contextMenu} collect={() => ({ rowIdx: props.rowIdx })}>
                <GridRow {...props} />
            </ContextMenuTrigger>
        );
    }

    updateSelectedRow = (row) => this.setState({ selectedRow: row });

    handleRowChange = (row) => {
        const { data, selectedRow } = this.state;
        if (selectedRow) {
            const updatedRows = [...data.rows];
            const index = updatedRows.findIndex(row => row.id === selectedRow.id);
            updatedRows[index] = row;
            this.handleRowsChange(updatedRows);
        }
    };

    handleRowsChange = (newRows) => {
        this.setState(prevState => {
            let rows = prevState.data.rows;
            newRows.forEach(newRow => {
                const index = rows.findIndex(row => row.id === newRow.id);
                rows[index] = newRow;
            });
            return { data: { columns: prevState.data.columns, rows: rows } };
        });
    }

    handleCheckboxChange = (_, { name, checked }) => {
        this.setState(prevState => ({
            transferData: { ...prevState.transferData, [name]: checked }, error: initialError
        }));
    }

    loadData = () => {
        let data = {
            columns: [],
            rows: []
        };

        const getEditor = (type, row, column, onRowChange, onClose, config = {}) => {
            const props = {
                elements: this.state.elements, elementsToModify: this.state.elementsToModify, ...config,
                row: row, column: column, onRowChange: onRowChange, onClose: onClose, updateSelectedRow: this.updateSelectedRow,
                pushToModificationsHistory: this.pushToModificationsHistory, changeElementsToModify: this.changeElementsToModify
            };
            switch (type) {
                case 'boolean': return <BooleanEditor {...props} />;
                case 'date': return <DateEditor {...props} />;
                case 'dropdown': return <DropDownEditor {...props} />;
                case 'number': return <NumberEditor {...props} />;
                case 'text': return <TextEditor {...props} />;
                default: return;
            }
        }

        // Définition des colonnes
        data.columns = [
            {
                name: i18n.t("Utilisateur"), key: 'username', width: 250,
                frozen: true, sortable: true, editable: false,
                formatter: (props) => <div className='disabled'>{props.row.username}</div>,
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Email"), key: 'email', width: 300,
                sortable: true, editable: false,
                formatter: (props) => <div className='disabled'>{props.row.email}</div>,
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Organisations"), key: 'organizations', width: 300,
                sortable: true, editable: false,
                formatter: (props) => <div className='disabled'>{props.row.organizations}</div>,
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Actif"), key: 'emailConfirmed', width: 110,
                sortable: true, editable: () => this.props.activeOrganization?.subscription.shortName !== 'Com',
                formatter: (props) => {
                    const subscription = this.props.activeOrganization?.subscription;
                    return (
                        <div className={subscription.shortName === 'Com' ? 'disabled' : ''} style={{ alignItems: subscription.shortName === 'Com' ? 'baseline' : 'center' }}>
                            {props.row.emailConfirmed === i18n.t("Oui")
                                ? <Icon name='check' color='green' />
                                : <Icon name='times' color='red' />}
                        </div>
                    );
                },
                filterRenderer: (props) => <BooleanFilter p={props} />,
                editor: ({ row, column, onRowChange, onClose }) => getEditor('boolean', row, column, onRowChange, onClose),
                editorOptions: { editOnClick: true }
            },
            {
                name: i18n.t("Date d'inscription"), key: 'registrationDate', width: 180, sortable: true,
                formatter: (props) => <div className='disabled'>{props.row.registrationDate}</div>,
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Dernière connexion"), key: 'lastLoginDate', width: 180, sortable: true,
                formatter: (props) => <div className='disabled'>{props.row.lastLoginDate}</div>,
                filterRenderer: (props) => <TextFilter p={props} />
            },
            {
                name: i18n.t("Projets"), key: 'projects', width: 200, sortable: true,
                formatter: (props) => (
                    <div className='disabled'>
                        <div>
                            <Popup
                                trigger={<span className='no-themed'><FontAwesomeIcon icon={faCrown} style={{ marginRight: '3px' }} />{props.row.ownedProjects.length}</span>}
                                content={i18n.t("En tant que propriétaire")}
                            />
                            <span style={{ margin: '0 5px' }}>|</span>
                            <Popup
                                trigger={<span className='no-themed'><FontAwesomeIcon icon={faUser} style={{ marginRight: '3px' }} />{props.row.joinedProjects.length}</span>}
                                content={i18n.t("En tant qu'invité")}
                            />
                        </div>
                        <Button
                            color='blue' title={i18n.t("Voir les projets")} style={{ padding: 0, height: '25px', width: '25px', position: 'absolute', top: '12.5%', right: 0 }}
                            disabled={(props.row.ownedProjects.length + props.row.joinedProjects.length) < 1}
                            onClick={() => this.setState({ projects: { joined: props.row.joinedProjects, owned: props.row.ownedProjects } })}
                        >
                            <FontAwesomeIcon icon={faEye} />
                        </Button>
                    </div>
                ),
                filterRenderer: (props) => <NumberFilter p={props} />
            }
        ];

        // Ajout des données
        UsersService.getUserRows().then(userRows => {
            const userId = jwtDecode(new Cookies().get('token')).id;
            data.rows = userRows.map(row => this.getRowValue(row)).filter(row => row.id !== userId).reverse();
            const elements = userRows.filter(user => user.id !== userId).reverse();
            const initialOrder = data.rows.map(row => row.id);
            this.setState({ data: data, elements: elements, initialOrder: initialOrder, isLoading: false });
        });
    }

    // Filtres
    areFiltersApplied = () => {
        if (!this.state.enableFilterRow) return false;
        let filtersApplied = false;
        for (const property in this.state.filters)
            if (this.state.filters[property]) filtersApplied = true;
        return filtersApplied;
    }

    toggleFilters = () => this.setState(prevState => ({ enableFilterRow: !prevState.enableFilterRow }));
    clearFilters = () => this.setState({ filters: initialFilters });

    getFilteredRows = () => {
        const filters = this.state.filters;
        let rows = [...this.state.data.rows];

        const $ = (str) => FormattersUtil.getNormalizedString(str);
        return rows.filter(r => {
            return !this.state.enableFilterRow || (
                (filters.username ? $(r.username).includes($(filters.username)) : true)
                && (filters.email ? $(r.email).includes($(filters.email)) : true)
                && (filters.organizations ? $(r.organizations).includes($(filters.organizations)) : true)
                && (filters.emailConfirmed ? r.emailConfirmed === filters.emailConfirmed : true)
                && (filters.registrationDate ? $(r.registrationDate).includes($(filters.registrationDate)) : true)
                && (filters.lastLoginDate ? $(r.lastLoginDate).includes($(filters.lastLoginDate)) : true)
                && (filters.projects ? (r.joinedProjects.length === Number(filters.projects) || r.ownedProjects.length === Number(filters.projects)) : true)
            );
        });
    }

    // Tri
    handleSort = (columnKey, direction) => this.setState({ sortColumn: columnKey, sortDirection: direction }, this.sortRows);
    sortRows = () => {
        const sortDirection = this.state.sortDirection;
        let rows = [...this.state.data.rows];
        if (sortDirection === 'NONE') {
            for (let i = 0; i < this.state.initialOrder.length; i++) {
                let temp = rows[i];
                const index = rows.findIndex(row => row.id === this.state.initialOrder[i]);
                rows[i] = rows[index];
                rows[index] = temp;
            }

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: rows
                }
            }));
        } else {
            const sortColumn = this.state.sortColumn;
            if (sortColumn === 'projects')
                rows = rows.sort((a, b) => (a.ownedProjects.length + a.joinedProjects.length) - (b.ownedProjects.length + b.joinedProjects.length));
            else if (['registrationDate', 'lastLoginDate'].includes(sortColumn))
                rows = rows.sort((a, b) => {
                    const aDate = DatesUtil.convertDateStringToDate(a[sortColumn]), bDate = DatesUtil.convertDateStringToDate(b[sortColumn]);
                    return !aDate ? -1 : !bDate ? 1 : aDate - bDate;
                });
            else rows = rows.sort((a, b) => (a[sortColumn] || '').localeCompare(b[sortColumn] || ''));

            this.setState(prevState => ({
                data: {
                    columns: prevState.data.columns,
                    rows: sortDirection === 'DESC' ? rows.reverse() : rows
                }
            }));
        }
    }

    // Gestion des modifications
    changeElementsToModify = (elementsToModify) => this.setState({ elementsToModify: elementsToModify });
    changeElementsToModifyLocally = (id, property, value, elementsToModify) => {
        const index = elementsToModify.findIndex(element => element.id === id);
        elementsToModify[index][property] = value;
        return elementsToModify;
    }

    /*     Historique     */
    pushToModificationsHistory = (modifications) => {
        let modificationsHistory = this.state.modificationsHistory;
        modificationsHistory = modificationsHistory.slice(0, this.state.modificationsHistoryIndex);
        modificationsHistory.push(modifications);
        this.setState(prevState => ({
            modificationsHistory: modificationsHistory,
            modificationsHistoryIndex: prevState.modificationsHistoryIndex + 1
        }));
    }

    handleKeyDown = (e) => {
        if (e.ctrlKey && e.key === 'z') this.restorePreviousModification();
        else if (e.ctrlKey && e.key === 'y') this.restoreNextModification();
        else if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
            const { selectedColumn, selectedRow } = this.state;
            if (selectedColumn && selectedRow) navigator.clipboard.writeText(selectedRow[selectedColumn.key] || '');
        }
    }

    restorePreviousModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const previousModification = this.state.modificationsHistory[index - 1];

        if (previousModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { elementsToModify, elementsToDelete } = this.state;

            let modificationsToCreate = [], previousElementsId = [], previousElementsProperties = [];
            previousModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                previousElementsId.push(elementId);
                previousElementsProperties.push(property);

                if (property !== 'delete') {
                    let row = data.rows.find(row => row.id === elementId);
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: row[property] });
                    row[property] = oldValue;
                    if (row.id === this.state.selectedRow?.id) this.updateSelectedRow(row);
                    const value = this.getPropertyValue(property, oldValue);
                    elementsToModify = this.changeElementsToModifyLocally(elementId, property, value, elementsToModify);
                } else {
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: oldValue });
                    elementsToDelete = elementsToDelete.filter(element => element.id !== oldValue.id);
                    data.rows.splice(elementId, 0, oldValue);
                }

            });

            let modificationsHistory;
            if (index === this.state.modificationsHistory.length) {
                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory.push(modificationsToCreate);
            } else {
                let actualElementsId = [], actualElementsProperties = [];

                this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
                this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));

                if (JSON.stringify(previousElementsId) !== JSON.stringify(actualElementsId)
                    || JSON.stringify(previousElementsProperties) !== JSON.stringify(actualElementsProperties)
                    || previousModification[0].property === 'delete') {
                    modificationsHistory = this.state.modificationsHistory;
                    modificationsHistory[index] = modificationsToCreate;
                }
            }

            this.setState(prevState => ({
                data: data,
                elementsToModify: elementsToModify,
                elementsToDelete: elementsToDelete,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index - 1,
            }));
        }
    }

    restoreNextModification = () => {
        const index = this.state.modificationsHistoryIndex;
        const nextModification = this.state.modificationsHistory[index + 1];

        if (nextModification) {
            let data = {
                columns: [...this.state.data.columns],
                rows: [...this.state.data.rows]
            };
            let { elementsToModify, elementsToDelete } = this.state;

            let modificationsToCreate = [], nextElementsId = [], nextElementsProperties = [];
            nextModification.forEach(modification => {
                const { elementId, property, oldValue } = modification;

                nextElementsId.push(elementId);
                nextElementsProperties.push(property);

                if (property !== 'delete') {
                    let row = data.rows.find(row => row.id === elementId);
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: row[property] });
                    row[property] = oldValue;
                    if (row.id === this.state.selectedRow?.id) this.updateSelectedRow(row);
                    const value = this.getPropertyValue(property, oldValue);
                    elementsToModify = this.changeElementsToModifyLocally(elementId, property, value, elementsToModify);
                } else {
                    modificationsToCreate.push({ property: property, elementId: elementId, oldValue: oldValue });
                    elementsToDelete.push(oldValue);
                    data.rows.splice(elementId, 1);
                }
            });

            let modificationsHistory, actualElementsId = [], actualElementsProperties = [];

            this.state.modificationsHistory[index].forEach(modification => actualElementsId.push(modification.elementId));
            this.state.modificationsHistory[index].forEach(modification => actualElementsProperties.push(modification.property));

            if (JSON.stringify(nextElementsId) !== JSON.stringify(actualElementsId)
                || nextElementsProperties !== actualElementsProperties
                || nextModification[0].property === 'delete') {
                modificationsHistory = this.state.modificationsHistory;
                modificationsHistory[index] = modificationsToCreate;
            }

            if (index === this.state.modificationsHistory.length - 2)
                modificationsHistory = this.state.modificationsHistory.slice(0, this.state.modificationsHistory.length - 1);

            this.setState(prevState => ({
                data: data,
                elementsToModify: elementsToModify,
                elementsToDelete: elementsToDelete,
                modificationsHistory: modificationsHistory || prevState.modificationsHistory,
                modificationsHistoryIndex: index + 1
            }));
        }
    }

    getPropertyValue = (property, value) => { // Map les valeurs affichées aux valeurs réelles
        switch (property) {
            case 'emailConfirmed': return value === i18n.t("Oui");
            default: return value;
        }
    }

    /*     Remplissage     */
    handleFill = ({ columnKey, sourceRow, targetRows }) => {
        let elementsToModify = this.state.elementsToModify;

        let rowsUpdated = false;
        targetRows.forEach(row => {
            rowsUpdated = true;
            const value = this.getPropertyValue(columnKey, sourceRow[columnKey]);;
            const index = elementsToModify.findIndex(element => element.id === row.id);
            if (index === -1) {
                let element = JSON.parse(JSON.stringify(this.state.elements.find(element => element.id === row.id)));
                element[columnKey] = value;
                elementsToModify.push(element);
            } else elementsToModify[index][columnKey] = value;
        });
        if (rowsUpdated) this.changeElementsToModify(elementsToModify);

        let modificationsToCreate = [];
        const newRows = targetRows.map(row => {
            modificationsToCreate.push({ property: columnKey, elementId: row.id, oldValue: row[columnKey] });
            return { ...row, [columnKey]: sourceRow[columnKey] };
        });
        this.pushToModificationsHistory(modificationsToCreate);

        return newRows;
    }

    /*     Réinitialisation     */
    resetRow = (_, { rowIdx }) => {
        let elementsToModify = this.state.elementsToModify;
        let data = {
            columns: [...this.state.data.columns],
            rows: [...this.state.data.rows]
        };

        // On reset les données de la ligne sélectionnée
        let filteredRows = this.getFilteredRows();
        const index = elementsToModify.findIndex(element => element.id === filteredRows[rowIdx].id);
        if (index !== -1) {
            const initialElement = this.state.elements.find(element => filteredRows[rowIdx].id === element.id);
            elementsToModify[index] = JSON.parse(JSON.stringify(initialElement));
            const newDisplayedData = { ...filteredRows[rowIdx], ...this.getRowValue(initialElement) };
            let modificationsToCreate = [];
            let rowIndex = data.rows.findIndex(row => row.id === filteredRows[rowIdx].id);
            for (const property in newDisplayedData)
                if (data.rows[rowIndex][property] !== newDisplayedData[property])
                    modificationsToCreate.push({ property: property, elementId: data.rows[rowIndex].id, oldValue: data.rows[rowIndex][property] });
            if (modificationsToCreate.length > 0)
                this.pushToModificationsHistory(modificationsToCreate);
            data.rows[rowIndex] = newDisplayedData;
            this.updateSelectedRow(newDisplayedData);
        }

        this.setState({
            data: data,
            elementsToModify: elementsToModify
        });
    }

    getRowValue = (element) => ({ ...element, emailConfirmed: element.emailConfirmed ? i18n.t("Oui") : i18n.t("Non") });

    deleteRow = (_, { rowIdx }) => {
        let elementsToDelete = this.state.elementsToDelete;
        let data = {
            columns: [...this.state.data.columns],
            rows: [...this.state.data.rows]
        };

        // On supprime la ligne sélectionnée et l'ajoute aux éléments à supprimer
        let filteredRows = this.getFilteredRows();
        const initialElement = this.state.elements.find(element => filteredRows[rowIdx].id === element.id);
        elementsToDelete.push(JSON.parse(JSON.stringify(initialElement)));
        let rowIndex = data.rows.findIndex(row => row.id === filteredRows[rowIdx].id);
        this.pushToModificationsHistory([{ property: 'delete', elementId: rowIndex, oldValue: data.rows[rowIndex] }]);
        data.rows.splice(rowIndex, 1);

        this.setState({ data, elementsToDelete, selectedRow: null, selectedColumn: null });
    }

    exportXLSX = () => {
        const { elements } = this.state;
        const elementsToExport = this.getFilteredRows().map(row => row.id);
        this.setState({ isExporting: true });
        UsersService.exportUsersAsExcel(elements.filter(element => elementsToExport.includes(element.id))).then(() => this.setState({ isExporting: false }));
    }

    handleSubmit = () => {
        let { elementsToModify, elementsToDelete, elements } = this.state;
        let elementsNotToModifyAnymore = [];

        elementsToModify.forEach(elementToModify => {
            if (JSON.stringify(elementToModify) === JSON.stringify(this.state.elements.find(element => element.id === elementToModify.id))
                || elementsToDelete.find(element => element.id === elementToModify.id))
                elementsNotToModifyAnymore.push(elementToModify);
        });
        elementsToModify = elementsToModify.filter(element => !elementsNotToModifyAnymore.includes(element));

        if (elementsToModify.length > 0 || elementsToDelete.length > 0) {
            this.setState({ isUpdating: true });
            let promises = [];
            if (elementsToModify.length > 0)
                promises.push(new Promise(resolve => {
                    UsersService.updateUsers(elementsToModify).then(response => {
                        if (response === 200) {
                            let rows = JSON.parse(JSON.stringify(this.state.data.rows));
                            elementsToModify.forEach(elementToModify => {
                                const index = elements.findIndex(element => element.id === elementToModify.id);
                                elements[index] = elementToModify;
                            });
                            this.setState(prevState => ({ data: { ...prevState.data, rows }, elementsToModify: [] }), () => resolve());
                        }
                    });
                }));

            if (elementsToDelete.length > 0)
                promises.push(new Promise(resolve => {
                    UsersService.deleteUsers(elementsToDelete.map(element => element.id)).then(response => {
                        if (response === 200) {
                            elements = elements.filter(element => !elementsToDelete.find(elementToDelete => elementToDelete.id === element.id));
                            this.setState({ elementsToDelete: [] }, () => resolve());
                        }
                    });
                }));

            Promise.all(promises).then(() => {
                this.setState({ elements: elements, modificationsHistory: [], modificationsHistoryIndex: 0, isUpdating: false });
            });
        } else {
            this.setState({ elementsToModify: [], modificationsHistory: [], modificationsHistoryIndex: 0 });
            showToast('users_updated');
        }
    }

    loginAs = (_, { rowIdx }) => {
        const subscription = this.props.activeOrganization?.subscription;
        const filteredRows = this.getFilteredRows();
        const initialElement = this.state.elements.find(element => filteredRows[rowIdx].id === element.id);
        const userId = initialElement.id;

        this.props.setHomeInfos(null);
        UsersService.loginAs(userId).then(response => {
            if (response.token) {
                const token = new Cookies().get('token');
                this.props.logUser();
                localStorage.setItem('loginAsData', JSON.stringify(
                    {
                        token, readOnly: false //subscription?.shortName !== 'Dev'
                    }));
                this.props.logUser(response.token);
            }
        });
    }

    transferData = () => {
        const { transferData } = this.state;

        let isValid = true, messages = [];
        const errors = { sourceUser: false, targetUser: false };

        const addError = (property, message) => {
            messages = [...(messages || []), message];
            errors[property] = true;
            isValid = false;
        };

        if (!transferData.sourceUser) addError('sourceUser', i18n.t("Veuillez sélectionner un utilisateur source"));
        if (!transferData.targetUser) addError('targetUser', i18n.t("Veuillez sélectionner un utilisateur cible"));

        if (!['baseProjects', 'customCharts', 'filterLists', 'wmsServices', 'thematicMaps'].some(property => transferData[property]))
            addError('properties', i18n.t("Veuillez cocher au minimum un type de données à transférer"));

        if (isValid) {
            this.setState({ isTransferringData: true });
            UsersService.transferUserData(transferData).then(() => {
                this.setState({ showTransferForm: false, isTransferringData: false, transferData: initialTransfer });
            });
        } else this.setState({ error: { messages, ...errors } });
    }
}

const mapStateToProps = (state) => {
    return {
        isDarkTheme: state.isDarkTheme,
        isOnline: state.isOnline,
        activeOrganization: state.activeOrganization
    };
};

const mapDispatchToProps = {
    setHomeInfos
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTable);